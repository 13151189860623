import styled from "styled-components";

import { Button as ButtonMD, Dialog, DialogActions, DialogTitle } from "@material-ui/core";

import Input from "components/Input";

export const MyInput = styled(Input)`
  width: 100%;
  // max-width: 450px;

  height: 70px !important;

  border: 2px solid #5f33ff !important;
  border-radius: 5px !important;
  opacity: 1 !important;

  color: #5f33ff !important;
  font-weight: bold;

  div {
    margin-top: 0 !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const TDialog = styled(Dialog)`
  border-radius: 20px !important;

  @media (max-width: 1200px) {
    .MuiDialog-paperWidthSm {
      max-width: inherit !important;
      width: 100vw !important;
      margin: 0px !important;
      max-height: inherit !important;
      height: 100vh !important;
    }
  }

  @media (min-width: 1200px) {
    .MuiDialog-paperWidthSm {
      max-width: inherit !important;
    }
  }
`;

export const TDialogTitle = styled(DialogTitle)`
  font-size: 32px !important;
  font-weight: bold !important;

  margin-top: 20px !important;
  letter-spacing: 0px !important;
  color: #5f33ff !important;

  text-align: center !important;
`;

export const TDialogContent = styled.div`
  width: 100vw !important;

  @media (min-width: 1200px) {
    width: 480px !important;
  }

  .option {
    display: flex;
    flex-direction: row;

    padding: 8px 0;
    display: flex;
    align-items: center;

    .option-label {
      display: flex;
      align-items: center;
      font-size: 14px;
    }
  }
`;

export const TDialogContentText = styled.div`
  padding: 20px 40px;
  text-align: center;
  // font: normal normal medium 16px/25px Poppins !important;
  letter-spacing: 0px !important;
  color: #210b3a !important;

  a {
    font: normal normal bold 16px/25px Poppins;
  }

  @media (min-width: 1200px) {
    // height: 700px;
    padding: 0 60px 60px 60px;
  }

  font: 16px Poppins;
  font-weight: medium;

  overflow: auto;
`;

export const TDialogActions = styled(DialogActions)`
  margin-bottom: 20px !important;
  justify-content: center !important;
  flex-direction: column !important;

  button {
    font: 14px Poppins !important;
  }

  button + button {
    margin-top: 10px !important;
  }

  @media (min-width: 700px) {
    flex-direction: row !important;

    button + button {
      margin-left: 20px !important;
      margin-top: 0px !important;
    }
  }
`;

interface ButtonProps {
  primary?: string;
}

export const TDialogButton = styled(ButtonMD)`
  background: ${(props: ButtonProps) =>
    props.primary === "true"
      ? "#5f33ff 0% 0% no-repeat padding-box !important"
      : "#ACACAC  0% 0% no-repeat padding-box !important"};

  border-radius: 5px !important;
  opacity: 1 !important;
  text-transform: none !important;

  width: 100% !important;
  height: 52px !important;
  margin: 0 !important;

  span {
    text-transform: none !important;
    color: #fff !important;
  }

  @media (min-width: 700px) {
    width: 144px !important;
  }
`;

interface SpacingProps {
  readonly height?: number;
}

export const Spacing = styled.div`
  height: ${(props: SpacingProps) => (props.height ? `${props.height}px` : "40px")};
`;

export const Button = styled(ButtonMD)`
  background: #5f33ff 0% 0% no-repeat padding-box !important;

  border-radius: 5px !important;
  opacity: 1 !important;
  text-transform: none !important;

  width: 180px !important;
  height: 52px !important;

  span {
    text-transform: none !important;
    color: #fff !important;
    font: 17px Poppins;
  }
`;

export const Title = styled.div`
  width: 100%;

  text-align: center;
  color: #210b3a;

  font-family: Coolvetica;

  span {
    color: #5f33ff;
  }

  font-size: 32px;
`;

export const Text = styled(Title)`
  font-family: Poppins;
  font-size: 16px;
`;

interface RadioProps {
  readonly selected: boolean;
}

export const Radio = styled.button<RadioProps>`
  border-radius: 50%;
  height: 18px;
  min-height: 18px;
  width: 18px;
  min-width: 18px;

  background: ${(props) => (props.selected ? "var(--color-roxo)" : "white")};
  color: ${(props) => (props.selected ? "white" : "var(--color-text-base)")};

  margin: 0 6px;
  outline: none;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 12px;
  font-weight: bold;
  border: 2px solid var(--color-roxo);
`;
