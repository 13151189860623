import axios, { AxiosResponse } from "axios";

export interface IBGEUFResponse {
  sigla: string;
}

export interface IBGECityResponse {
  nome: string;
}

const ibge = axios.create({
  baseURL: "https://servicodados.ibge.gov.br/api/v1/"
});

export function getUF(): Promise<AxiosResponse> {
  return ibge.get<IBGEUFResponse[]>("localidades/estados");
}

export function getUFCities(uf: string): Promise<AxiosResponse> {
  return ibge.get<IBGECityResponse[]>(`localidades/estados/${uf}/municipios`);
}

export default ibge;
