import styled from "styled-components";

export const Kit = styled.div`
  width: 90%;
  height: 100px;

  min-height: 100px;

  box-shadow: 0px 3px 6px #2c28281c;
  border-radius: 10px;

  background: #fff;

  & + & {
    margin-top: 25px;
  }
`;
export const KitHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding: 10px 25px;
`;
export const KitInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
export const IconContainer = styled.div`
  font-size: 32px;
  color: var(--color-roxo);

  display: flex;
  align-items: center;
`;
export const KitDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  padding: 0 5px;
`;
export const KitId = styled.div`
  font-weight: bold;
  font-size: 16px;
`;
export const KitDate = styled.div`
  font-size: 8px;
`;
export const KitStatus = styled.div`
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface KitStatusColorProps {
  readonly color: string;
}

export const KitStatusColor = styled.div<KitStatusColorProps>`
  width: 10px;
  height: 10px;
  background-color: ${(props) => `${props.color}`};
  border-radius: 50%;
  margin-right: 5px;
`;

export const KitFooter = styled.div`
  display: flex;
  width: 100%;

  padding: 0 10px;
`;

export const KitButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  button {
    height: 32px !important;
    font-size: 12px !important;
  }

  button + button {
    margin-left: 5px;
  }
`;
