import React from "react";
import { Dialog, DialogActions, DialogButton, DialogContent, DialogTitle, Spacing } from "./styles";

interface DialogProps {
  openDialog: boolean;
  handleDisagree(): void;
  handleAgree(): void;
}

export const NotAnswering: React.FC<DialogProps> = ({ openDialog, handleAgree, handleDisagree }) => {
  return (
    <Dialog
      open={openDialog}
      onClose={handleDisagree}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Spacing height={20} />
      <DialogTitle id="alert-dialog-title">Atenção!</DialogTitle>
      <DialogContent>
        A ausência de resposta ao questionário pode afetar a qualidade dos resultados. Tem certeza que não deseja
        responder?
      </DialogContent>
      <Spacing height={12} />

      <DialogActions>
        <DialogButton onClick={handleDisagree} primary="false">
          VOLTAR
        </DialogButton>
        <DialogButton onClick={handleAgree} primary="true">
          NÃO RESPONDER
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
};

export default NotAnswering;
