import React, { useCallback, useEffect, useState } from "react";
import api from "services/api";
import { IPack } from "types";
import loadash from "lodash";
import { toast } from "react-toastify";
import { IconButton } from "@material-ui/core";
import { Edit, Save } from "@material-ui/icons";
import { FiPackage, FiDownload } from "react-icons/fi";
import Table3 from "components/Table3";
import NewPackDialog from "components/Dialogs/NewPackDialog";
import Input from "components/Input";
import Loading from "components/Loading";
import { PackRow, Page } from "./styles";

export const Packs = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [offset, setOffset] = useState<number>(0);
  const [limit] = useState<number>(5);

  const [total, setTotal] = useState<number>(0);
  const [packs, setPacks] = useState<IPack[]>([]);

  const [selectedPack, selectPack] = useState<IPack>();

  const [newPack, setNewPack] = useState({
    kitType: 1,
    quantity: 1,
    name: ""
  });

  const [openDialog, setOpenDialog] = useState(false);

  // const history = useHistory();

  useEffect(() => {
    api.get(`packs?limit=${limit}&offset=${offset}`).then((res) => {
      const { total, packs } = res.data;

      setPacks(packs);
      setTotal(total);
    });
  }, [offset, limit]);

  const getFilteredPacks = (value: string) => {
    api.get(`packs?limit=${limit}&offset=${offset}&name=${value}`).then((res) => {
      const { total, packs } = res.data;
      setPacks(packs);
      setTotal(total);
    });
  };

  const debouncedSearch = useCallback(
    loadash.debounce((nextValue) => getFilteredPacks(nextValue), 1000),
    []
  );

  const handleChangeSearch = (v) => {
    debouncedSearch(v);
  };

  const handlePreviousPage = () => {
    if (offset - limit < 0) return;
    setOffset(offset - limit);
  };

  const handleNextPage = () => {
    if (offset + limit >= total) return;

    setOffset(offset + limit);
  };

  const getFormatedData = (data) => {
    return new Intl.DateTimeFormat("pt-BR", {
      year: "numeric",
      month: "numeric",
      day: "numeric"
    }).format(new Date(data));
  };

  const handleChangePack = (pack: { name: string; quantity: number; kitType: number }) => {
    setNewPack(pack);
  };

  const handleSavePack = async () => {
    if (newPack.quantity <= 0) {
      toast.error("Informe uma quantidade maior que 0");
      return;
    }
    setLoading(true);

    api.post("packs", newPack).then((r) => {
      setNewPack({
        name: "",
        quantity: 1,
        kitType: 1
      });
      setOpenDialog(false);
      setLoading(false);
      getFilteredPacks("");
    });
  };

  const handleEditPack = (pack: IPack) => {
    selectPack(pack);
  };

  const handleSaveSelectedPack = (pack: IPack) => {
    selectPack(pack);
    api.put(`packs/${pack.id}`, { name: selectedPack?.name || "Pack" }).then((res) => {
      toast.success("Pack atualizado com sucesso!");
      selectPack(undefined);
      getFilteredPacks("");
    });
  };

  const handleDownloadPack = async (pack: IPack) => {
    api
      .post(
        "download/pack",
        { pack: pack.id },
        {
          responseType: "blob"
        }
      )
      .then((r) => {
        const url = window.URL.createObjectURL(r.data);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${pack.id}.zip`;
        a.click();
        setLoading(false);
      });
    // .catch((err) => setLoading(false));
  };

  const handleDownloadForms = async (type: string) => {
    if (type !== "intestinal" && type !== "solo") {
      return toast.error("Erro ao baixar fomulário");
    }
    setLoading(true);

    return api
      .get(type === "intestinal" ? "questionarios/json/intestinal" : "questionarios/json/solo", {
        responseType: "blob"
      })
      .then((r) => {
        if (r.status === 200) {
          const url = window.URL.createObjectURL(r.data);
          const a = document.createElement("a");
          a.href = url;
          a.download = `${type}.json`;
          a.click();
        } else {
          toast.error("Erro ao baixar fomulário");
        }
        setLoading(false);
      });

    // .catch((err) => setLoading(false));
  };

  return (
    <Page>
      <Loading active={loading} />
      <Table3
        handleChangeSearch={handleChangeSearch}
        total={total}
        offset={offset}
        limit={limit}
        handleNextPage={handleNextPage}
        handlePreviousPage={handlePreviousPage}
        handleDownloadHuman={() => handleDownloadForms("intestinal")}
        handleDownloadSoil={() => handleDownloadForms("solo")}
        handleClickNew={() => setOpenDialog(true)}
      >
        <NewPackDialog
          openDialog={openDialog}
          handleCancel={() => setOpenDialog(false)}
          handleChangePack={handleChangePack}
          handleSave={handleSavePack}
          pack={newPack}
        />
        {packs.map((pack: IPack) => {
          return (
            <PackRow key={pack.id}>
              <div>
                <FiPackage />
              </div>
              <div className="kit">{pack.id}</div>
              {selectedPack && pack.id === selectedPack.id ? (
                <Input
                  name="selected-pack-name"
                  label=""
                  value={selectedPack.name}
                  onChange={(e) => selectPack({ ...selectedPack, name: e.target.value })}
                />
              ) : (
                <div>{pack.name}</div>
              )}

              <div>{getFormatedData(pack.created_at)}</div>
              <div>
                {selectedPack && pack.id === selectedPack.id ? (
                  <IconButton aria-label="settings" onClick={() => handleSaveSelectedPack(pack)}>
                    <Save />
                  </IconButton>
                ) : (
                  <IconButton aria-label="settings" onClick={() => handleEditPack(pack)}>
                    <Edit />
                  </IconButton>
                )}
              </div>
              <div>
                <IconButton aria-label="settings" onClick={() => handleDownloadPack(pack)}>
                  <FiDownload />
                </IconButton>
              </div>
            </PackRow>
          );
        })}
      </Table3>
    </Page>
  );
};

export default Packs;
