import Emoji from "components/Emoji";
import React from "react";
import {
  MyInput,
  Spacing,
  TDialog,
  TDialogActions,
  TDialogButton,
  TDialogContent,
  TDialogContentText,
  Title
} from "./styles";

interface DialogProps {
  openDialog: boolean;
  handleValidar(): void;
  handleSetCode(code: string): void;
  handleCancel(): void;
  validCode: boolean;
  code: string;
}

export const NewKitDialog: React.FC<DialogProps> = ({
  openDialog,
  handleValidar,
  handleCancel,
  handleSetCode,
  validCode,
  code
}) => {
  /* eslint-disable */
  return (
    <TDialog
      open={openDialog}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <TDialogContent>
        <TDialogContentText id="alert-dialog-description">
          <MyInput
            name="code"
            label="Código do novo kit:"
            disabled={validCode}
            value={code}
            onChange={(e) => {
              handleSetCode(e.target.value)
            }}
          />
          <Spacing />
          <Title>
            Atenção <Emoji symbol="🙂" label="sorrindo" />
          </Title>
          <Spacing height={12} />
          Ao ativar um Kit você está aceitando os{" "}
          <a href="https://www.agregabiotec.com/termos-de-uso" target="_blank">
            Termos de Uso
            </a>{" "}
            e a{" "}
          <a href="https://www.agregabiotec.com/politica-de-privacidade" target="_blank">
            Política de Privacidade
          </a>.

        </TDialogContentText>



      </TDialogContent>
      <TDialogActions>
        <TDialogButton primary={"false"} onClick={handleCancel}>
          Cancelar
        </TDialogButton>
        <TDialogButton primary={"true"} onClick={handleValidar} color="primary" autoFocus>
          Concordo
        </TDialogButton>
      </TDialogActions>
    </TDialog>
  )
  /* eslint-disable */
};

export default NewKitDialog;
