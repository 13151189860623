import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { FiFileText, FiPackage, FiSettings, FiHome, FiUser, FiMenu, FiLogOut } from "react-icons/fi";
import logo from "../../assets/images/agrega_logo.png";
import { useAuth } from "../../contexts/auth";

import { IKitType } from "../../types";
import api from "../../services/api";
import { Container, ContainerCollapsed, LI, UL } from "./styles";

export default function Sidebar() {
  const { logout, isAdmin, triggerNewKit } = useAuth();
  const [collapsed, setCollapsed] = useState(false);
  const [kitTypes, setKitTypes] = useState<IKitType[]>([]);
  const [menuId, setMenuId] = useState(0);

  useEffect(() => {
    if (isAdmin()) {
      api.get("kit-types").then((res) => {
        setKitTypes(res.data);
      });
      // .catch((err) => console.log("erro do sidebar"));
    } else {
      api.get("user/kit-types").then((res) => setKitTypes(res.data));
    }
  }, [isAdmin, triggerNewKit]);

  const handleClickMenu = (menu: number) => {
    setMenuId(menu);
  };

  return (
    <>
      {collapsed ? (
        <ContainerCollapsed>
          <header>
            <FiMenu onClick={() => setCollapsed(!collapsed)} />
          </header>

          <Link to="/" className="sair" onClick={logout}>
            <FiLogOut />
          </Link>
        </ContainerCollapsed>
      ) : (
        <Container>
          <header>
            <img src={logo} alt="Logo" />
            <FiMenu onClick={() => setCollapsed(!collapsed)} />
          </header>

          <UL>
            <LI selected={menuId === 1}>
              <Link to="/" onClick={() => handleClickMenu(1)}>
                <FiHome />
                Home
              </Link>
            </LI>
            <LI selected={menuId === 2}>
              <Link to="/profile" onClick={() => handleClickMenu(2)}>
                <FiUser />
                Meu perfil
              </Link>
            </LI>
            {kitTypes.map((kitType, i) => {
              return (
                <LI key={kitType.id} selected={menuId === 3 + i}>
                  <Link to={`/my-kits/${kitType.id}`} onClick={() => handleClickMenu(3 + i)}>
                    <FiPackage />
                    {kitType.type}
                  </Link>
                </LI>
              );
            })}
            {isAdmin() && (
              <>
                <LI selected={menuId === 7}>
                  <Link to="/users" onClick={() => handleClickMenu(7)}>
                    <FiSettings />
                    Controle de Usuários
                  </Link>
                </LI>
                <LI selected={menuId === 8}>
                  <Link to="/kits" onClick={() => handleClickMenu(8)}>
                    <FiFileText />
                    Kits
                  </Link>
                </LI>
                <LI selected={menuId === 9}>
                  <Link to="/packs" onClick={() => handleClickMenu(9)}>
                    <FiFileText />
                    Packs
                  </Link>
                </LI>
              </>
            )}
          </UL>

          <Link to="/" className="sair" onClick={logout}>
            Sair
          </Link>
        </Container>
      )}
    </>
  );
}
