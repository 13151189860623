import styled from "styled-components";

export const Page = styled.div`
  flex: 1 1 auto;

  background-color: var(--color-roxo);

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  overflow: hidden;

  @media (min-width: 1200px) {
    padding: 15px;
    height: 100vh;
  }
`;
export const Panel = styled.div`
  width: 100%;
  max-width: 1000px;

  display: flex;
  flex-direction: row;

  justify-content: space-between;

  margin: 15px 0;

  height: 200px;
  padding: 20px 0;
`;
export const Statistic = styled.div`
  width: 33%;
  height: auto;
  padding: 30px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: #fff;
  box-shadow: 0px 3px 6px #2c28281c;
  border-radius: 10px;
  opacity: 1;

  position: relative;
  cursor: pointer;

  & + & {
    margin-left: 20px;
  }
`;

export const Label = styled.div`
  font-size: 14px;
  color: #8898aa;
  letter-spacing: 0px;
  text-align: left;
  width: 100%;
`;
export const Amount = styled.div`
  font-size: 40px;
  color: var(--color-roxo);
  letter-spacing: 0px;
  text-align: left;
  width: 100%;
`;
export const LastMonth = styled.div`
  font-size: 17px;
  color: #4d4f5c;
  letter-spacing: 0px;
  text-align: left;
  width: 100%;

  .negative {
    color: #f53c56;
  }

  .positive {
    color: #2dce98;
  }
`;
export const Icon = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;

  svg {
    font-size: 28px;
    color: var(--color-roxo);
  }
`;

export const KitRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 5fr 3fr 2fr 2fr 1fr;
  text-align: center;

  flex-grow: 1;
  max-height: 80px;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .kit {
    text-align: start;
    font-size: 14px;
    justify-content: flex-start;
  }

  svg {
    font-size: 24px;
    color: var(--color-roxo);
    cursor: pointer;
  }

  &:nth-child(odd) {
    background-color: #f7fafc;
  }
`;

interface KitStatusColorProps {
  readonly color: string;
}

export const KitStatusColor = styled.div<KitStatusColorProps>`
  width: 10px;
  height: 10px;
  background-color: ${(props) => `${props.color}`};
  border-radius: 50%;
  margin-right: 5px;
`;
