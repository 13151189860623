import styled from "styled-components";

export const Main = styled.main`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: #5f33ff 0% 0% no-repeat padding-box;

  height: 100vh;
  @media (min-width: 1200px) {
    flex-direction: row;
  }
`;

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #5f33ff 0% 0% no-repeat padding-box;

  @media (min-width: 1200px) {
    width: 1020px;
    overflow: hidden;
  }
`;

export const Right = styled.div`
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  flex: 1 1 auto;
  align-items: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 20px;
  height: 100%;

  background: #fff;

  @media (min-width: 1200px) {
    min-width: 500px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
`;

export const LogoContainer = styled.div`
  padding: 36px;
  width: 100%;

  img {
    width: 178px;
    height: 48px;
  }

  @media (min-width: 1200px) {
    flex-grow: 1;

    display: flex;
    align-items: center;
    justify-content: conter;

    padding: 80px;

    img {
      width: 280px;
      height: 76px;
    }
  }
`;

export const ImageContainer = styled.div`
  display: none;

  @media (min-width: 1200px) {
    flex-grow: 2;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;

    height: 80%;

    img {
      max-width: 100%;
      max-height: 240%;
    }
  }
`;
