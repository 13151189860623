import React, { useState } from "react";
import {
  Radio,
  Spacing,
  TDialog,
  TDialogActions,
  TDialogButton,
  TDialogContent,
  TDialogContentText,
  Title,
  Text
} from "./styles";

interface DialogProps {
  openDialog: boolean;
  handleSelect(formId: string): void;
  handleNotSelect(): void;
  options: { kit: string; form: string }[];
}

export const OldFormsDialog: React.FC<DialogProps> = ({ openDialog, handleSelect, handleNotSelect, options }) => {
  const [formId, setFormId] = useState<string>("");

  const handleSelectFormId = (id: string) => {
    setFormId(id);
  };

  return (
    <TDialog
      open={openDialog}
      onClose={handleNotSelect}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <TDialogContent>
        <TDialogContentText id="alert-dialog-description">
          <Spacing height={20} />
          <Title>Atenção</Title>
          <Spacing height={20} />
          <Text>
            Para facilitar o preenchimento de uma nova amostras, você poderá carregar as respostas de questionários
            anteriores.
          </Text>
          <Spacing height={12} />
          {options.map((opt) => (
            <div key={opt.form} className="option">
              <Radio onClick={() => handleSelectFormId(opt.form)} selected={formId === opt.form} />
              <div className="option-label">{opt.kit}</div>
            </div>
          ))}
        </TDialogContentText>
      </TDialogContent>
      <TDialogActions>
        <TDialogButton primary="false" onClick={handleNotSelect}>
          Não carregar
        </TDialogButton>
        <TDialogButton primary="true" onClick={() => handleSelect(formId)} color="primary" autoFocus>
          Carregar
        </TDialogButton>
      </TDialogActions>
    </TDialog>
  );
};

export default OldFormsDialog;
