import React, { useState, FormEvent, ChangeEvent, useEffect } from "react";

import api from "services/api";
import { useAuth } from "contexts/auth";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import { IUser } from "types";
import { getCEP, validarCEP } from "services/cep";
import { useHistory } from "react-router-dom";
import { Page } from "components/Page";
import LandingLayout from "components/LandingLayout";
import TermsDialog from "components/Dialogs/TermsDialog";
import Loading from "components/Loading";
import { validarCPF } from "services/utils";
import {
  Container,
  Header,
  MyButton,
  MyInput,
  Subtitle,
  Title,
  RegisterDialogContentText,
  RegisterDialogContent,
  RegisterDialog,
  RegisterCard,
  RegisterCardHeader,
  RegisterCardTitle,
  RegisterForm,
  InputGroup,
  RegisterInput,
  RegisterInputContent,
  RegisterInputError,
  RegisterMaskedInput
} from "./styles";

interface Errors {
  [key: string]: string;
}

function Register() {
  const [code, setCode] = useState("");
  const [validCode, setValidCode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<IUser>({
    email: "",
    name: "",
    address: "",
    city: "",
    cep: "",
    complement: "",
    cpf: "",
    mobile_phone: "",
    neighborhood: "",
    phone: "",
    number: "",
    reference: "",
    uf: "",
    password: ""
  } as IUser);
  const [openDialog, setOpenDialog] = useState(false);
  const [openRegisterDialog, setOpenRegisterDialog] = useState(false);

  const params: { type: string; kit: string } = useParams();

  const [errors, setErros] = useState<Errors>({});

  const { register } = useAuth();

  const history = useHistory();

  function handleCheckCode() {
    api
      .get(`kits/check/${params.kit ? params.kit : code}`)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Código válido!");
          setValidCode(true);
          setOpenRegisterDialog(true);
        } else {
          toast.error("Código inválido!");
        }
      })
      .catch((err) => {
        toast.error("Erro! Contato o suporte.");
      });
  }

  useEffect(() => {
    if (params.kit) {
      setCode(params.kit);
      handleCheckCode();
    }
    // eslint-disable-next-line
  }, [params]);

  async function handleValidation() {
    let isValid = true;

    let newErrors = {};

    if (user.email === "" || !user.email) {
      newErrors = { ...newErrors, email: "E-mail é obrigatório." };
      isValid = false;
    }

    if (user.password === "") {
      newErrors = { ...newErrors, password: "Senha é obrigatória." };

      isValid = false;
    }

    if (user.name === "") {
      newErrors = { ...newErrors, name: "Nome é obrigatório." };
      isValid = false;
    }

    if (user.cpf === "" || !user.cpf) {
      newErrors = { ...newErrors, cpf: "CPF ou CNPJ é obrigatório." };
      isValid = false;
    }

    if (user.address === "" || !user.address) {
      newErrors = { ...newErrors, address: "Endereço é obrigatório." };
      isValid = false;
    }

    if (user.cep === "" || !user.cep) {
      newErrors = { ...newErrors, cep: "CEP é obrigatório." };
      isValid = false;
    }

    if (user.number === "" || !user.number) {
      newErrors = { ...newErrors, number: "Número é obrigatório." };
      isValid = false;
    }

    if (user.city === "" || !user.city) {
      newErrors = { ...newErrors, city: "Cidade é obrigatório." };
      isValid = false;
    }

    if (user.uf === "" || !user.uf) {
      newErrors = { ...newErrors, uf: "UF é obrigatório." };
      isValid = false;
    }

    if (user.cpf !== "" && validarCPF(user.cpf) === false) {
      newErrors = { ...newErrors, cpf: "CPF ou CNPJ inválido." };
      isValid = false;
    }

    if (user.cep !== "" && (await validarCEP(user.cep)) === false) {
      newErrors = { ...newErrors, cep: "CEP inválido." };
      isValid = false;
    }
    setErros(newErrors);
    return isValid;
  }

  async function handleRegister(e: FormEvent) {
    e.preventDefault();

    const isValid = await handleValidation();

    if (isValid) {
      setLoading(true);
      const res = await register({ ...user, email: user.email.toLowerCase() }, code);

      if (res) {
        if (res.data.type_id === 1) {
          api
            .post("questionario/intestinal", { kit_id: code, answered: false })
            .then((formResponse) => {
              if (formResponse.status === 204) {
                setLoading(false);
                history.push("/home");
              } else {
                setLoading(false);
                localStorage.setItem("@RAuth:AGREGA:formCode", `/form/human/${formResponse.data.form_id}`);
                history.push(`/form/human/${formResponse.data.form_id}`);
              }
            })
            .catch((err) => {
              // console.log(err);
            });
        } else if (res.data.type_id === 2) {
          api
            .post("questionario/solo", { kit_id: code, answered: false })
            .then((formResponse) => {
              if (formResponse.status === 204) {
                setLoading(false);
                history.push("/home");
              } else {
                setLoading(false);
                localStorage.setItem("@RAuth:AGREGA:formCode", `/form/soil/${formResponse.data.form_id}`);
                history.push(`/form/soil/${formResponse.data.form_id}`);
              }
            })
            .catch((err) => {
              // console.log(err);
            });
        }
      } else {
        setLoading(false);
      }
    }

    setLoading(false);
    toast.error("O formulário precisa ser preenchido corretamente.");
  }

  const handleAgree = () => {
    setOpenDialog(false);
    handleCheckCode();
  };

  const handleDisagree = () => {
    setOpenDialog(false);
  };

  function handleTerms() {
    setOpenDialog(true);
  }

  function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
    const { id, value } = event.target;
    setUser({ ...user, [id]: value });
  }

  function handleCepChange() {
    const { cep } = user;

    if (!cep) return;

    getCEP(cep).then((res) => {
      if (res && res.status === 200 && !(res.data.erro === true)) {
        const { complemento, localidade, logradouro, uf, bairro } = res.data;
        setUser({ ...user, complement: complemento, uf, city: localidade, address: logradouro, neighborhood: bairro });
      }
    });
  }

  return (
    <Page>
      <Loading active={loading} />
      <LandingLayout>
        <Container>
          <Header>
            <Title>dados</Title>
            <Title>
              do <span>kit</span>
            </Title>
            <Subtitle>Insira aqui o código do seu kit agrega.</Subtitle>
          </Header>
          <MyInput
            label=""
            name="code"
            disabled={validCode}
            value={code}
            onChange={(e) => {
              setCode(e.target.value);
            }}
          />
          <MyButton onClick={handleTerms}>
            Validar <span>código</span>
          </MyButton>
        </Container>
      </LandingLayout>
      <TermsDialog openDialog={openDialog} handleAgree={handleAgree} handleDisagree={handleDisagree} />
      {validCode && openRegisterDialog && (
        <RegisterDialog
          open={openRegisterDialog}
          onClose={() => setOpenRegisterDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <RegisterDialogContent>
            <RegisterDialogContentText id="alert-dialog-description">
              <RegisterCard onSubmit={handleRegister}>
                <RegisterCardHeader>
                  <RegisterCardTitle>Seus Dados</RegisterCardTitle>
                  <MyButton type="submit">Salvar</MyButton>
                </RegisterCardHeader>
                <RegisterForm>
                  <InputGroup inputs={2}>
                    <RegisterInputContent>
                      <RegisterInput
                        label="Email"
                        name="email"
                        type="email"
                        value={user.email}
                        onChange={handleInputChange}
                      />
                      {errors.email && <RegisterInputError>{errors.email}</RegisterInputError>}
                    </RegisterInputContent>
                    <RegisterInputContent>
                      <RegisterInput
                        name="password"
                        type="password"
                        label="Senha"
                        value={user.password}
                        onChange={handleInputChange}
                      />
                      {errors.password && <RegisterInputError>{errors.password}</RegisterInputError>}
                    </RegisterInputContent>
                  </InputGroup>
                  <InputGroup inputs={2}>
                    <RegisterInputContent>
                      <RegisterInput name="name" label="Nome completo" value={user.name} onChange={handleInputChange} />
                      {errors.name && <RegisterInputError>{errors.name}</RegisterInputError>}
                    </RegisterInputContent>
                    <RegisterInputContent>
                      <RegisterInput
                        name="cpf"
                        id="cpf"
                        value={user && user.cpf ? user.cpf : ""}
                        label="CPF ou CNPJ"
                        onChange={handleInputChange}
                      />
                      {errors.cpf && <RegisterInputError>{errors.cpf}</RegisterInputError>}
                    </RegisterInputContent>
                  </InputGroup>
                  <InputGroup inputs={2}>
                    <RegisterInputContent>
                      <RegisterMaskedInput
                        name="phone"
                        id="phone"
                        value={user && user.phone ? user.phone : ""}
                        label="Telefone"
                        mask={["(", /[1-9]/, /\d/, ")", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                        onChange={handleInputChange}
                      />
                      {errors.phone && <RegisterInputError>{errors.phone}</RegisterInputError>}
                    </RegisterInputContent>
                    <RegisterInputContent>
                      <RegisterMaskedInput
                        name="mobile_phone"
                        id="mobile_phone"
                        value={user && user.mobile_phone ? user.mobile_phone : ""}
                        label="Celular"
                        mask={["(", /[1-9]/, /\d/, ")", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                        onChange={handleInputChange}
                      />
                      {errors.mobile_phone && <RegisterInputError>{errors.mobile_phone}</RegisterInputError>}
                    </RegisterInputContent>
                  </InputGroup>
                  <InputGroup inputs={3}>
                    <RegisterInputContent>
                      <RegisterMaskedInput
                        name="cep"
                        label="CEP"
                        id="cep"
                        value={user && user.cep ? user.cep : ""}
                        mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                        onBlur={() => handleCepChange()}
                        onChange={handleInputChange}
                      />
                      {errors.cep && <RegisterInputError>{errors.cep}</RegisterInputError>}
                    </RegisterInputContent>
                    <RegisterInputContent>
                      <RegisterInput
                        type="text"
                        name="uf"
                        label="Estado"
                        value={user?.uf}
                        onChange={handleInputChange}
                      />
                      {errors.uf && <RegisterInputError>{errors.uf}</RegisterInputError>}
                    </RegisterInputContent>
                    <RegisterInputContent>
                      <RegisterInput
                        type="text"
                        name="city"
                        label="Cidade"
                        value={user?.city}
                        onChange={handleInputChange}
                      />
                      {errors.city && <RegisterInputError>{errors.city}</RegisterInputError>}
                    </RegisterInputContent>
                  </InputGroup>
                  <InputGroup inputs={2}>
                    <RegisterInputContent>
                      <RegisterInput
                        type="text"
                        name="address"
                        label="Endereço"
                        value={user?.address}
                        onChange={handleInputChange}
                      />
                      {errors.address && <RegisterInputError>{errors.address}</RegisterInputError>}
                    </RegisterInputContent>
                    <RegisterInputContent>
                      <RegisterInput
                        type="text"
                        name="number"
                        label="Número"
                        value={user?.number}
                        onChange={handleInputChange}
                      />
                      {errors.number && <RegisterInputError>{errors.number}</RegisterInputError>}
                    </RegisterInputContent>
                  </InputGroup>
                  <InputGroup inputs={3}>
                    <RegisterInputContent>
                      <RegisterInput name="complemento" label="Complemento" />
                      {errors.complement && <RegisterInputError>{errors.complement}</RegisterInputError>}
                    </RegisterInputContent>
                    <RegisterInputContent>
                      <RegisterInput name="bairro" label="Bairro" />
                      {errors.neighborhood && <RegisterInputError>{errors.neighborhood}</RegisterInputError>}
                    </RegisterInputContent>
                    <RegisterInputContent>
                      <RegisterInput
                        type="text"
                        name="reference"
                        label="Referência"
                        value={user?.reference}
                        onChange={handleInputChange}
                      />
                      {errors.reference && <RegisterInputError>{errors.reference}</RegisterInputError>}
                    </RegisterInputContent>
                  </InputGroup>
                </RegisterForm>
              </RegisterCard>
            </RegisterDialogContentText>
          </RegisterDialogContent>
        </RegisterDialog>
      )}
    </Page>
  );
}

export default Register;
