import React, { useEffect, useState } from "react";

import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Dialog, IconButton } from "@material-ui/core";
import { FiX } from "react-icons/fi";
import OldFormsDialog from "components/Dialogs/OldFormsDialog";
import {
  Container,
  LandingCard,
  Card,
  Button,
  InputGroup,
  InputGroupChild,
  TableIntro,
  Table,
  DialogTitle,
  DialogActions,
  DialogButton,
  Spacing,
  DialogContent,
  FormInputError,
  ButtonSave
} from "./styles";
import Input from "../../components/FormInput";
import Select from "../../components/FormSelect";
import SelectWithInput from "../../components/SelectWithInput";

import CheckboxListWithSelect from "../../components/CheckboxListWithSelect";
import Emoji from "../../components/Emoji";
import TableRow from "../../components/TableRow";
import CheckboxList from "../../components/CheckboxList";
import Autocomplete from "../../components/Autocomplete";

import { getUF, getUFCities, IBGEUFResponse, IBGECityResponse } from "../../services/ibge";

import * as Questionario from "./questions";
import api from "../../services/api";
import Textarea from "../../components/Textarea";

import fezes from "../../assets/images/fezes.png";

interface Errors {
  [key: string]: string;
}

interface ITemplate {
  S01Q01: Questionario.AutoComplete;
  S01Q02: Questionario.AutoComplete;
  S01Q03: Questionario.CheckboxList;

  S02Q01: Questionario.InputNormal;
  S02Q02: Questionario.SelectNormal;
  S02Q03: Questionario.InputNormal;
  S02Q04: Questionario.SelectNormal;
  S02Q05: Questionario.InputNormal;
  S02Q06: Questionario.SelectNormal;
  S02Q07: Questionario.CheckBoxListWithSelect;
  S02Q08: Questionario.SelectNormal;
  S02Q09: Questionario.SelectNormal;
  S02Q10: Questionario.CheckBoxListWithSelect;
  S02Q11: Questionario.SelectNormal;
  S02Q12: Questionario.SelectWithInput;
  S02Q13: Questionario.SelectNormal;

  S03Q01: Questionario.SelectNormal;
  S03Q02: Questionario.SelectNormal;
  S03Q03: Questionario.SelectNormal;
  S03Q04: Questionario.SelectNormal;
  S03Q05: Questionario.SelectNormal;
  S03Q06: Questionario.SelectNormal;
  S03Q07: Questionario.SelectNormal;

  S04Q01: Questionario.SelectNormal;
  S04Q02: Questionario.InputNormal;
  S04Q03: Questionario.SelectNormal;
  S04Q04: Questionario.SelectNormal;
  S04Q05: Questionario.InputNormal;
  S04Q06: Questionario.SelectNormal;
  S04Q07a: Questionario.SelectNormal;
  S04Q07: Questionario.SelectWithInput;
  S04Q08: Questionario.SelectNormal;
  S04Q09: Questionario.SelectNormal;
  S04Q10: Questionario.SelectNormal;
  S04Q11: Questionario.SelectNormal;

  S05Q01: Questionario.SelectNormal;
  S05Q02a: Questionario.InputNormal;
  S05Q02b: Questionario.InputNormal;
  S05Q03: Questionario.SelectNormal;
  S05Q04a: Questionario.InputNormal;
  S05Q04b: Questionario.InputNormal;
  S05Q05: Questionario.SelectNormal;
  S05Q06a: Questionario.InputNormal;
  S05Q06b: Questionario.InputNormal;
  S05Q07a: Questionario.InputNormal;
  S05Q07b: Questionario.InputNormal;
  S05Q08a: Questionario.InputNormal;
  S05Q08b: Questionario.InputNormal;

  S06Q01: Questionario.Table;
  S06Q02: Questionario.SelectNormal;
  S06Q03: Questionario.SelectNormal;
  S06Q04: Questionario.SelectNormal;
  S06Q05: Questionario.SelectNormal;
  S06Q06: Questionario.SelectNormal;
  S06Q07: Questionario.SelectNormal;
  S06Q08: Questionario.SelectWithInput;
  S06Q09: Questionario.SelectNormal;
  S06Q10: Questionario.TextAreaNormal;

  S07Q01: Questionario.SelectNormal;
  S07Q02: Questionario.SelectNormal;
}

function Form() {
  const [show, setShow] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openOldFormsDialog, setOpenOldFormsDialog] = React.useState(false);
  const [answered, setAnswered] = React.useState(false);
  const [section, setSection] = useState<number>(1);
  const params: { id: string } = useParams();

  const [errors, setErros] = useState<Errors>({});
  const [oldForms, setOldForms] = useState<{ kit: string; form: string }[]>([]);

  const [ufs, setUfs] = useState<IBGEUFResponse[]>([]);
  const [cities, setCities] = useState<IBGECityResponse[]>([]);

  const history = useHistory();
  const [questionario, setQuestionario] = useState<ITemplate>({
    S01Q01: Questionario.S01Q01,
    S01Q02: Questionario.S01Q02,
    S01Q03: Questionario.S01Q03,

    S02Q01: Questionario.S02Q01,
    S02Q02: Questionario.S02Q02,
    S02Q03: Questionario.S02Q03,
    S02Q04: Questionario.S02Q04,
    S02Q05: Questionario.S02Q05,
    S02Q06: Questionario.S02Q06,
    S02Q07: Questionario.S02Q07,
    S02Q08: Questionario.S02Q08,
    S02Q09: Questionario.S02Q09,
    S02Q10: Questionario.S02Q10,
    S02Q11: Questionario.S02Q11,
    S02Q12: Questionario.S02Q12,
    S02Q13: Questionario.S02Q13,

    S03Q01: Questionario.S03Q01,
    S03Q02: Questionario.S03Q02,
    S03Q03: Questionario.S03Q03,
    S03Q04: Questionario.S03Q04,
    S03Q05: Questionario.S03Q05,
    S03Q06: Questionario.S03Q06,
    S03Q07: Questionario.S03Q07,

    S04Q01: Questionario.S04Q01,
    S04Q02: Questionario.S04Q02,
    S04Q03: Questionario.S04Q03,
    S04Q04: Questionario.S04Q04,
    S04Q05: Questionario.S04Q05,
    S04Q06: Questionario.S04Q06,
    S04Q07a: Questionario.S04Q07a,
    S04Q07: Questionario.S04Q07,
    S04Q08: Questionario.S04Q08,
    S04Q09: Questionario.S04Q09,
    S04Q10: Questionario.S04Q10,
    S04Q11: Questionario.S04Q11,

    S05Q01: Questionario.S05Q01,
    S05Q02a: Questionario.S05Q02a,
    S05Q02b: Questionario.S05Q02b,
    S05Q03: Questionario.S05Q03,
    S05Q04a: Questionario.S05Q04a,
    S05Q04b: Questionario.S05Q04b,
    S05Q05: Questionario.S05Q05,
    S05Q06a: Questionario.S05Q06a,
    S05Q06b: Questionario.S05Q06b,
    S05Q07a: Questionario.S05Q07a,
    S05Q07b: Questionario.S05Q07b,
    S05Q08a: Questionario.S05Q08a,
    S05Q08b: Questionario.S05Q08b,

    S06Q01: Questionario.S06Q01,
    // S06Q08b: Questionario.InputNormal;
    S06Q02: Questionario.S06Q02,
    S06Q03: Questionario.S06Q03,
    S06Q04: Questionario.S06Q04,
    S06Q05: Questionario.S06Q05,
    S06Q06: Questionario.S06Q06,
    S06Q07: Questionario.S06Q07,
    S06Q08: Questionario.S06Q08,
    S06Q09: Questionario.S06Q09,
    S06Q10: Questionario.S06Q10,

    S07Q01: Questionario.S07Q01,
    S07Q02: Questionario.S07Q02
  });

  const handleValidation = () => {
    let isValid = true;
    let newErrors = {};

    const sectionsToValidate = [2, 4, 5];

    if (sectionsToValidate.includes(section)) {
      if (section === 2) {
        if (questionario.S02Q01.value) {
          if (!(Number(questionario.S02Q01.value) >= 18 && Number(questionario.S02Q01.value) <= 120)) {
            newErrors = { ...newErrors, S02Q01: "A idade deve ser entre 18 e 120 anos." };
            isValid = false;
          }
        }

        if (questionario.S02Q03.value) {
          if (!(Number(questionario.S02Q03.value) >= 30 && Number(questionario.S02Q03.value) <= 500)) {
            newErrors = { ...newErrors, S02Q03: "O peso deve ser entre 30 e 500 Kg." };
            isValid = false;
          }
        }

        if (questionario.S02Q05.value) {
          if (!(Number(questionario.S02Q05.value) >= 100 && Number(questionario.S02Q05.value) <= 300)) {
            newErrors = { ...newErrors, S02Q05: "A altura deve ser entre 100 e 300 cm." };
            isValid = false;
          }
        }
      }

      if (section === 4) {
        if (questionario.S04Q07.value && questionario.S04Q07.inputTriggered) {
          if (!(Number(questionario.S04Q07.inputValue) >= 1 && Number(questionario.S04Q07.inputValue) <= 40)) {
            newErrors = { ...newErrors, S04Q07: "Informe um valor entre 1 e 40 semanas." };
            isValid = false;
          }
        }
      }

      if (section === 5) {
        if (questionario.S05Q02a.value) {
          if (!(Number(questionario.S05Q02a.value) >= 0 && Number(questionario.S05Q02a.value) <= 24)) {
            newErrors = { ...newErrors, S05Q02a: "Informe um valor entre 0 e 24." };
            isValid = false;
          }

          if (!(Number(questionario.S05Q02b.value) >= 0 && Number(questionario.S05Q02b.value) <= 60)) {
            newErrors = { ...newErrors, S05Q02b: "Informe um valor entre 0 e 59." };
            isValid = false;
          }

          if (!(Number(questionario.S05Q04a.value) >= 0 && Number(questionario.S05Q04a.value) <= 24)) {
            newErrors = { ...newErrors, S05Q04a: "Informe um valor entre 0 e 24." };
            isValid = false;
          }

          if (!(Number(questionario.S05Q04b.value) >= 0 && Number(questionario.S05Q04b.value) <= 60)) {
            newErrors = { ...newErrors, S05Q04b: "Informe um valor entre 0 e 59." };
            isValid = false;
          }

          if (!(Number(questionario.S05Q06a.value) >= 0 && Number(questionario.S05Q06a.value) <= 24)) {
            newErrors = { ...newErrors, S05Q06a: "Informe um valor entre 0 e 24." };
            isValid = false;
          }

          if (!(Number(questionario.S05Q06b.value) >= 0 && Number(questionario.S05Q06b.value) <= 60)) {
            newErrors = { ...newErrors, S05Q06b: "Informe um valor entre 0 e 59." };
            isValid = false;
          }

          if (!(Number(questionario.S05Q07a.value) >= 0 && Number(questionario.S05Q07a.value) <= 24)) {
            newErrors = { ...newErrors, S05Q07a: "Informe um valor entre 0 e 24." };
            isValid = false;
          }

          if (!(Number(questionario.S05Q07b.value) >= 0 && Number(questionario.S05Q07b.value) <= 60)) {
            newErrors = { ...newErrors, S05Q07b: "Informe um valor entre 0 e 59." };
            isValid = false;
          }

          if (!(Number(questionario.S05Q08a.value) >= 0 && Number(questionario.S05Q08a.value) <= 24)) {
            newErrors = { ...newErrors, S05Q08a: "Informe um valor entre 0 e 24." };
            isValid = false;
          }

          if (!(Number(questionario.S05Q08b.value) >= 0 && Number(questionario.S05Q08b.value) <= 60)) {
            newErrors = { ...newErrors, S05Q08b: "Informe um valor entre 0 e 59." };
            isValid = false;
          }
        }
      }
    }

    setErros(newErrors);
    if (isValid) setSection(section + 1);
    else toast.error("Por gentileza, corrija os erros no formulário");
  };

  useEffect(() => {
    localStorage.removeItem("@RAuth:AGREGA:formCode");
  }, []);

  const getOldForm = (formId) => {
    api.get(`questionario/intestinal/${formId}`).then((res) => {
      const { data } = res;

      if (!data || !data.responses) return;

      setAnswered(data.answered);

      const oldForm = { ...questionario };

      Object.keys(data.responses).forEach((key) => {
        const savedResponse = data.responses[key];
        oldForm[key] = { ...oldForm[key], ...savedResponse, label: oldForm[key].label };
      });
      setQuestionario(oldForm);
    });
  };

  useEffect(() => {
    getOldForm(params.id);
  }, [params]);

  const handleAgree = () => {
    setOpenDialog(false);
    history.push("/home");
  };

  const handleDisagree = () => {
    setOpenDialog(false);
  };

  const setQuestion = (question) => {
    setQuestionario({ ...questionario, [question.name]: { ...question } });
  };

  const changeRow = (row) => {
    const S06Q01 = { ...questionario.S06Q01 };
    S06Q01.rows = questionario.S06Q01.rows.map((oldRow) => (row.meal === oldRow.meal ? { ...row } : { ...oldRow }));
    setQuestion(S06Q01);
  };

  useEffect(() => {
    if (section > 0) document.querySelector("#page-form > .card-content")?.scrollTo(0, 0);
  }, [section]);

  useEffect(() => {
    getUF().then((response) => setUfs(response.data));
  }, []);

  const handleSaveForm = () => {
    const formId = params.id;

    if (formId) {
      api.put(`questionario/intestinal/${formId}`, { form: questionario }).then((res) => {
        if (res.status === 200) {
          toast.success("Formulário respondido com sucesso!");
          history.push("/home");
        }
      });
    }
  };

  useEffect(() => {
    getUF().then((response) => setUfs(response.data));
  }, []);

  useEffect(() => {
    getUFCities(questionario.S01Q01.value).then((response) => setCities(response.data));
  }, [questionario.S01Q01.value]);

  const handleAnswer = () => {
    if (answered) {
      setShow(true);
    } else {
      api.get(`kit/questionario/respondidos/intestinal/${params.id}`).then((res) => {
        if (!res.data || res.data.length === 0) {
          setShow(true);
        } else {
          const forms = res.data;
          setOldForms(forms);
          setOpenOldFormsDialog(true);
        }
      });
    }
  };

  const handleNotSelect = () => {
    setOldForms([]);
    setOpenOldFormsDialog(false);
    setShow(true);
  };

  const handleSelect = (formId: string) => {
    getOldForm(formId);
    setOpenOldFormsDialog(false);
    setShow(true);
    setOldForms([]);
  };

  return (
    <Container>
      {show ? (
        <Card id="page-form">
          <div className="closeIcon">
            <IconButton onClick={handleSaveForm}>
              <FiX />
            </IconButton>
          </div>
          <div className="card-content">
            {section === 1 && (
              <fieldset>
                <header>
                  <legend>
                    <div>
                      Primeiro, um pouco sobre a
                      <span className="roxo">
                        sua <br /> origem
                      </span>{" "}
                      <Emoji symbol="🌎" label="mundo" />
                    </div>
                  </legend>
                </header>
                <section>
                  <Spacing height={66} />
                  <p>Onde você mora?</p>

                  <InputGroup>
                    <InputGroupChild grow={5} maxWidth={90}>
                      <Autocomplete
                        options={ufs.map((uf) => uf.sigla)}
                        setQuestion={setQuestion}
                        question={questionario.S01Q01 as Questionario.AutoComplete}
                      />
                    </InputGroupChild>

                    <InputGroupChild grow={2}>
                      <Autocomplete
                        options={cities.map((city) => city.nome)}
                        setQuestion={setQuestion}
                        question={questionario.S01Q02 as Questionario.AutoComplete}
                      />
                    </InputGroupChild>
                  </InputGroup>
                  <CheckboxList question={questionario.S01Q03} setFunction={setQuestion} />
                </section>
              </fieldset>
            )}
            {section === 2 && (
              <fieldset>
                <header>
                  <legend>
                    <div>
                      Agora, alguns dados gerais <br /> sobre a <span className="roxo"> sua saúde</span>{" "}
                      <Emoji symbol="📝" label="papel" />
                      <Emoji symbol="👩‍⚕️" label="medica" />
                    </div>
                  </legend>
                </header>
                <section>
                  <Spacing height={66} />
                  <Input type="number" min="18" max="120" question={questionario.S02Q01} setFunction={setQuestion} />
                  {errors.S02Q01 && <FormInputError>{errors.S02Q01}</FormInputError>}
                  <Select question={questionario.S02Q02} setFunction={setQuestion} />
                  <Input type="number" min="30" max="500" question={questionario.S02Q03} setFunction={setQuestion} />
                  {errors.S02Q03 && <FormInputError>{errors.S02Q03}</FormInputError>}
                  <Select question={questionario.S02Q04} setFunction={setQuestion} />
                  <Input type="number" min="100" max="300" question={questionario.S02Q05} setFunction={setQuestion} />
                  {errors.S02Q05 && <FormInputError>{errors.S02Q05}</FormInputError>}
                  <Select question={questionario.S02Q06} setFunction={setQuestion} />
                  <CheckboxListWithSelect question={questionario.S02Q07} setFunction={setQuestion} />
                  <Select question={questionario.S02Q08} setFunction={setQuestion} />
                  <Select question={questionario.S02Q09} setFunction={setQuestion} />
                  <CheckboxListWithSelect question={questionario.S02Q10} setFunction={setQuestion} />
                  {questionario.S02Q10.options.filter((opt) => opt.checked).length > 0 && (
                    <Select question={questionario.S02Q11} setFunction={setQuestion} />
                  )}
                  <SelectWithInput question={questionario.S02Q12} setFunction={setQuestion} />
                  <Select question={questionario.S02Q13} setFunction={setQuestion} />
                </section>
              </fieldset>
            )}
            {section === 3 && (
              <fieldset>
                <header>
                  <legend>
                    <div>
                      Agora, um pouco de <br />
                      <span className="roxo">como você se sente</span>
                    </div>
                  </legend>
                </header>
                <section>
                  <Spacing height={66} />
                  <div className="text">
                    Aqui você precisa responder um pouco a respeito de alguns sintomas que você pode estar sentindo.
                  </div>
                  <Select question={questionario.S03Q01} setFunction={setQuestion} />
                  <Select question={questionario.S03Q02} setFunction={setQuestion} />
                  <Select question={questionario.S03Q03} setFunction={setQuestion} />
                  <Select question={questionario.S03Q04} setFunction={setQuestion} />
                  <Select question={questionario.S03Q05} setFunction={setQuestion} />
                  <Select question={questionario.S03Q06} setFunction={setQuestion} />
                  <Select question={questionario.S03Q07} setFunction={setQuestion} />
                </section>
              </fieldset>
            )}
            {section === 4 && (
              <fieldset>
                <header>
                  <legend>
                    <div>
                      Uso de medicamentos <Emoji symbol="💊" label="medicamento" />
                    </div>
                  </legend>
                </header>
                <section>
                  <Spacing height={66} />
                  <div className="text">
                    Você precisa informar quais medicamentos você fez uso <span className="roxo">no último mês</span>{" "}
                    📅.
                  </div>
                  <Spacing height={30} />
                  <div className="text">
                    Ao responder sobre o seu uso de medicamentos, você deve pensar em todos, tanto os que foram
                    receitados quanto os que não foram. Também inclua remédios homeopáticos, vitaminas, minerais, ervas,
                    suplementos naturais e outros produtos alternativos de saúde.
                  </div>
                  <Select question={questionario.S04Q01} setFunction={setQuestion} />
                  {questionario.S04Q01.value === "Sim" && (
                    <>
                      <Input type="text" question={questionario.S04Q02} setFunction={setQuestion} />
                      <Select question={questionario.S04Q03} setFunction={setQuestion} />
                      <Select question={questionario.S04Q04} setFunction={setQuestion} />

                      {questionario.S04Q04.value === "Sim" && (
                        <>
                          <Input type="text" question={questionario.S04Q05} setFunction={setQuestion} />
                          <Select question={questionario.S04Q06} setFunction={setQuestion} />
                        </>
                      )}
                    </>
                  )}

                  <Select question={questionario.S04Q07a} setFunction={setQuestion} />
                  {questionario.S04Q07a.value === "Sim" && (
                    <>
                      <SelectWithInput question={questionario.S04Q07} setFunction={setQuestion} />
                      {errors.S04Q07 && <FormInputError>{errors.S04Q07}</FormInputError>}
                      <Select question={questionario.S04Q08} setFunction={setQuestion} />
                    </>
                  )}
                  <Select question={questionario.S04Q09} setFunction={setQuestion} />
                  <Select question={questionario.S04Q10} setFunction={setQuestion} />
                  <Select question={questionario.S04Q11} setFunction={setQuestion} />
                </section>
              </fieldset>
            )}
            {section === 5 && (
              <fieldset>
                <header>
                  <legend>
                    <div>
                      Chegou a hora da atividade <br /> física <Emoji symbol="🏋️‍♀️" label="fisica" />
                    </div>
                  </legend>
                </header>
                <section>
                  <Spacing height={66} />
                  <div className="text">
                    As perguntas estão relacionadas ao tempo que você gastou fazendo qualquer tipo de atividade física
                    na <span className="roxo">última semana</span> <Emoji symbol="📅" label="calendario" />.
                  </div>
                  <Spacing height={20} />
                  <div className="text">Para responder, lembre-se que:</div>
                  <Spacing height={20} />
                  <div className="text">
                    <Emoji symbol="👉" label="apontando" /> <span className="roxo">Atividades moderadas</span> são
                    aquelas que precisam de algum esforço e que fazem você respirar{" "}
                    <span className="roxo">um pouco mais</span> <Emoji symbol="😮‍💨" label="bufando" />
                  </div>
                  <Spacing height={20} />
                  <div className="text">
                    Aqui entra pedalar de forma leve na bicicleta, nadar, dançar, fazer ginástica aeróbica de forma
                    leve, jogar vôlei recreativo, carregar pesos leves, fazer serviços domésticos na casa, no quintal ou
                    no jardim como varrer, aspirar, cuidar do jardim, ou qualquer atividade que fez aumentar
                    moderadamente sua respiração ou batimentos do coração.
                  </div>
                  <Spacing height={20} />
                  <div className="text">
                    <Emoji symbol="⚠️" label="atencao" /> Não inclua caminhada aqui, ok?
                  </div>
                  <Spacing height={20} />
                  <div className="text">
                    <Emoji symbol="👉" label="apontando" /> <span className="roxo">Atividades vigorosas</span> são
                    aquelas que precisam de um grande esforço e que fazem você respirar{" "}
                    <span className="roxo">muito</span> mais <Emoji symbol="🥵" label="cansado" />
                  </div>
                  <Spacing height={20} />

                  <div className="text">
                    Aqui entra correr, fazer ginástica aeróbica, jogar futebol, pedalar rápido na bicicleta, jogar
                    basquete, fazer serviços domésticos pesados em casa, no quintal ou cavoucar no jardim, carregar
                    pesos elevados ou qualquer atividade que fez aumentar muito sua respiração ou batimentos do coração.
                  </div>

                  <Select question={questionario.S05Q01} setFunction={setQuestion} />
                  <Spacing height={20} />

                  <div className="text bold">
                    Nos dias em que você caminhou por pelo menos 10 minutos contínuos, quanto tempo no total você gastou
                    caminhando por dia?
                  </div>

                  <InputGroup>
                    <InputGroupChild grow={1} maxWidth={240}>
                      <Input type="text" question={questionario.S05Q02a} setFunction={setQuestion} />
                      {errors.S05Q02a && <FormInputError>{errors.S05Q02a}</FormInputError>}
                    </InputGroupChild>

                    <InputGroupChild grow={1} maxWidth={240}>
                      <Input type="text" question={questionario.S05Q02b} setFunction={setQuestion} />
                      {errors.S05Q02b && <FormInputError>{errors.S05Q02b}</FormInputError>}
                    </InputGroupChild>
                  </InputGroup>

                  <Select question={questionario.S05Q03} setFunction={setQuestion} />
                  <Spacing height={20} />

                  <div className="text bold">
                    Nos dias em que você fez essas atividades moderadas por pelo menos 10 minutos contínuos, quanto
                    tempo no total você gastou fazendo essas atividades por dia?
                  </div>

                  <InputGroup>
                    <InputGroupChild grow={1} maxWidth={240}>
                      <Input type="text" question={questionario.S05Q04a} setFunction={setQuestion} />
                      {errors.S05Q04a && <FormInputError>{errors.S05Q04a}</FormInputError>}
                    </InputGroupChild>

                    <InputGroupChild grow={1} maxWidth={240}>
                      <Input type="text" question={questionario.S05Q04b} setFunction={setQuestion} />
                      {errors.S05Q04b && <FormInputError>{errors.S05Q04b}</FormInputError>}
                    </InputGroupChild>
                  </InputGroup>

                  <Select question={questionario.S05Q05} setFunction={setQuestion} />
                  <Spacing height={20} />

                  <div className="text bold">
                    Nos dias em que você fez essas atividades vigorosas por pelo menos 10 minutos contínuos, quanto
                    tempo no total você gastou fazendo essas atividades por dia?
                  </div>

                  <InputGroup>
                    <InputGroupChild grow={1} maxWidth={240}>
                      <Input type="text" question={questionario.S05Q06a} setFunction={setQuestion} />
                      {errors.S05Q06a && <FormInputError>{errors.S05Q06a}</FormInputError>}
                    </InputGroupChild>

                    <InputGroupChild grow={1} maxWidth={240}>
                      <Input type="text" question={questionario.S05Q06b} setFunction={setQuestion} />
                      {errors.S05Q06b && <FormInputError>{errors.S05Q06b}</FormInputError>}
                    </InputGroupChild>
                  </InputGroup>

                  <Spacing height={66} />

                  <div className="text">
                    As próximas perguntas são sobre o tempo que você permanece sentado por dia, seja no trabalho, na
                    escola, na faculdade, em casa ou durante seu tempo livre. Isto inclui o tempo sentado enquanto você
                    estuda, descansa, lê, senta ou deita assistindo TV. 🧘‍♀️
                  </div>
                  <Spacing height={20} />

                  <div className="text">
                    ⚠️ Não inclua o tempo gasto sentando durante o transporte em ônibus, trem, metrô ou carro, certo?
                  </div>

                  <Spacing height={66} />

                  <div className="text bold">Quanto tempo no total você gasta sentado durante um dia de semana?</div>

                  <InputGroup>
                    <InputGroupChild grow={1} maxWidth={240}>
                      <Input type="text" question={questionario.S05Q07a} setFunction={setQuestion} />
                      {errors.S05Q07a && <FormInputError>{errors.S05Q07a}</FormInputError>}
                    </InputGroupChild>

                    <InputGroupChild grow={1} maxWidth={240}>
                      <Input type="text" question={questionario.S05Q07b} setFunction={setQuestion} />
                      {errors.S05Q07b && <FormInputError>{errors.S05Q07b}</FormInputError>}
                    </InputGroupChild>
                  </InputGroup>

                  <Spacing height={20} />

                  <div className="text bold">
                    Quanto tempo no total você gasta sentado durante um dia do final de semana?
                  </div>

                  <InputGroup>
                    <InputGroupChild grow={1} maxWidth={240}>
                      <Input type="text" question={questionario.S05Q08a} setFunction={setQuestion} />
                      {errors.S05Q08a && <FormInputError>{errors.S05Q08a}</FormInputError>}
                    </InputGroupChild>

                    <InputGroupChild grow={1} maxWidth={240}>
                      <Input type="text" question={questionario.S05Q08b} setFunction={setQuestion} />
                      {errors.S05Q08b && <FormInputError>{errors.S05Q08b}</FormInputError>}
                    </InputGroupChild>
                  </InputGroup>
                </section>
              </fieldset>
            )}
            {section === 6 && (
              <fieldset>
                <header>
                  <legend style={{ maxWidth: "100%" }}>
                    <div style={{ width: "100%" }}>
                      Agora um pouco dos seus <span className="roxo"> hábitos alimentares </span>
                      <Emoji symbol="🍽️" label="comida" />
                    </div>
                    <TableIntro>
                      <div className="left">
                        <div className="title">Respostas por tempo:</div>
                        <div className="values">
                          <div>
                            <span>D</span>Por dia
                          </div>
                          <div>
                            <span>S</span>Por semana
                          </div>
                          <div>
                            <span>M</span>Por mês
                          </div>
                          <div>
                            <span>A</span>Por ano
                          </div>
                        </div>
                      </div>
                      <div className="right">
                        <div className="title">Sua porção:</div>
                        <div className="values">
                          <div>
                            <span>P</span>É menor que a porção
                          </div>
                          <div>
                            <span>M</span>É igual a porção média
                          </div>
                          <div>
                            <span>G</span>É maior que a porção
                          </div>
                          <div>
                            <span>E</span>É bem maior
                          </div>
                        </div>
                      </div>
                    </TableIntro>
                  </legend>
                </header>
                <section>
                  <Table>
                    {questionario.S06Q01.rows.map((row) => {
                      return <TableRow key={row.meal} row={row} setRow={changeRow} />;
                    })}
                  </Table>
                </section>
              </fieldset>
            )}
            {section === 7 && (
              <fieldset>
                <header>
                  <legend>
                    <p>
                      Agora, mais algumas últimas <br />
                      perguntas
                    </p>

                    <p style={{ fontSize: 16 }}>
                      (tá acabando essa parte, nós juramos <Emoji symbol="😅" label="ops" />
                      !)
                    </p>
                  </legend>
                </header>
                <section>
                  <Spacing height={66} />
                  <Select question={questionario.S06Q02} setFunction={setQuestion} />
                  <Select question={questionario.S06Q03} setFunction={setQuestion} />
                  <Select question={questionario.S06Q04} setFunction={setQuestion} />
                  <Select question={questionario.S06Q05} setFunction={setQuestion} />
                  <Select question={questionario.S06Q06} setFunction={setQuestion} />
                  <Select question={questionario.S06Q07} setFunction={setQuestion} />
                  <SelectWithInput question={questionario.S06Q08} setFunction={setQuestion} />
                  <Select question={questionario.S06Q09} setFunction={setQuestion} />
                  <Textarea question={questionario.S06Q10} setFunction={setQuestion} />
                </section>
              </fieldset>
            )}
            {section === 8 && (
              <fieldset>
                <legend>
                  <p>
                    Deixamos o melhor pro final <Emoji symbol="💩" label="coco" />
                  </p>
                </legend>
                <section>
                  <Spacing height={66} />
                  <p>Observando a figura abaixo:</p>

                  <div className="image-container">
                    <img src={fezes} alt="fezes" />
                  </div>

                  <Select question={questionario.S07Q01} setFunction={setQuestion} />
                  <Select question={questionario.S07Q02} setFunction={setQuestion} />
                </section>
              </fieldset>
            )}
            {section === 9 && (
              <fieldset>
                <legend>
                  <div>
                    Parabéns! Você{" "}
                    <span className="roxo">
                      terminou de <br /> responder o questionário!
                    </span>
                    <Emoji symbol="🥳" label="parabens" />
                    <Emoji symbol="🎉" label="confetes" />
                  </div>
                </legend>
                <section>
                  <Spacing height={66} />
                  <p>
                    Lembre-se: ao responder o questionário você não obtém somente um relatório mais personalizado, mas
                    também contribui para que melhoremos nossos serviços e conheçamos mais sobre o microbioma intestinal
                    humano.
                  </p>
                  <p>
                    A ciência também agradece! <Emoji symbol="👨‍🔬" label="medico" />
                  </p>
                  <p>
                    A Johanna <Emoji symbol="🤖" label="robo" /> agora vai poder usar as suas respostas para melhorar os
                    seus resultados. Você receberá um e-mail e poderá acessar o seu relatório de microbioma na sua
                    página pessoal em meu.agregabiotec.com
                  </p>
                </section>
              </fieldset>
            )}
          </div>

          {section === 9 ? (
            <footer>
              <Button onClick={handleSaveForm} primary width="100%">
                Salvar e sair
              </Button>
            </footer>
          ) : (
            <footer>
              <ButtonSave width="160px" onClick={handleSaveForm}>
                Salvar e sair
              </ButtonSave>
              <div>
                <Button onClick={() => (section > 1 ? setSection(section - 1) : null)}>Voltar</Button>
                <Button primary onClick={() => handleValidation()}>
                  Próximo
                </Button>
              </div>
            </footer>
          )}
        </Card>
      ) : (
        <LandingCard>
          <div className="card-content">
            <header>
              <div>
                Temos algumas perguntas para te fornecer um <span className="roxo">resultado ainda melhor</span>!
                <Emoji symbol="🙂" label="sorrindo" />
              </div>
            </header>
            <section className="web">
              <div>
                Com essas informações nós treinamos o nosso algoritmo para criar perfis de saúde. O algoritmo é a nossa
                robozinha que analisa seus dados e escreve o seu relatório.{" "}
                <span className="bold">
                  Ah, sim, o nome dela é Johanna! <Emoji symbol="🤖" label="robo" />
                </span>
              </div>
              <Spacing height={30} />
              <div>
                Quando você responde o questionário, a Johanna pode usar suas respostas para calibrar a análise. Não
                precisa se sentir desconfortável durante o processo, pois ninguém olha as suas respostas. Bem, só a
                Johanna, <span className="bold">mas ela não costuma julgar ninguém.</span>{" "}
                <Emoji symbol="😉" label="piscando" />
              </div>
              <Spacing height={30} />
              <div>
                Algumas perguntas podem até parecer estranhas, mas elas estão aqui porque têm relação com mudanças e
                características do seu microbioma.
              </div>
              <Spacing height={30} />
              <div>
                Apesar de não ser obrigatório, se você realmente deseja uma avaliação mais precisa, convém responder o
                questionário de saúde.
              </div>
              <Spacing height={30} />
              <div>
                Sente-se confortavelmente antes de começar a responder o questionário, pois ele pode demorar um pouco{" "}
                <Emoji symbol="🕗" label="tempo" />
              </div>
              <Spacing height={30} />
              <div>
                <span className="bold">Que tal preparar um café para beber enquanto responde?</span>
                <Emoji symbol="☕" label="cafe" />
              </div>
            </section>
            <section className="mobile">
              <p>
                Com essas informações nós treinamos o nosso algoritmo para criar perfis de saúde. O algoritmo é a nossa
                robozinha que analisa seus dados e escreve o seu relatório.{" "}
                <span className="bold">
                  Ah, sim, o nome dela é Johanna! <Emoji symbol="🤖" label="robo" />
                </span>
              </p>
              <p>
                Quando você responde o questionário, a Johanna pode usar suas respostas para calibrar a análise. Não
                precisa se sentir desconfortável durante o processo, pois ninguém olha as suas respostas. Bem, só a
                Johanna,
                <span className="bold">
                  mas ela não costuma julgar ninguém. <Emoji symbol="😉" label="piscando" />
                </span>
              </p>
            </section>
          </div>
          <footer>
            <Button onClick={() => setOpenDialog(true)}>Não quero responder</Button>
            <Button primary onClick={() => handleAnswer()}>
              {/* {answered ? "Modificar" : "Responder"} questionário */}
              Responder questionário
            </Button>
          </footer>
          <Dialog
            open={openDialog}
            onClose={handleDisagree}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <Spacing height={20} />
            <DialogTitle id="alert-dialog-title">Atenção!</DialogTitle>
            <DialogContent>
              A ausência de resposta ao questionário pode afetar a qualidade dos resultados. Tem certeza que não deseja
              responder?
            </DialogContent>
            <Spacing height={12} />

            <DialogActions>
              <DialogButton onClick={handleDisagree} primary="false">
                VOLTAR
              </DialogButton>
              <DialogButton onClick={handleAgree} primary="true">
                NÃO RESPONDER
              </DialogButton>
            </DialogActions>
          </Dialog>
          <OldFormsDialog
            openDialog={openOldFormsDialog}
            handleNotSelect={handleNotSelect}
            handleSelect={handleSelect}
            options={oldForms}
          />
        </LandingCard>
      )}
    </Container>
  );
}

export default Form;
