import styled from "styled-components";

import Input from "components/Input";
import { Button, Dialog, DialogTitle } from "@material-ui/core";
import MyMaskedInput from "components/MaskedInput";

export const Header = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.div`
  width: 100%;

  text-align: left;
  color: #210b3a;

  font-family: Coolvetica;
  font-size: 95px;

  span {
    color: #5f33ff;
  }

  & + & {
    position: relative;
    top: -16px;
  }

  font-size: 42px;

  @media (min-width: 1200px) {
    // letter-spacing: -8px;
    font-size: 80px;

    & + & {
      position: relative;
    }
  }
`;

export const Subtitle = styled.div`
  width: 100%;

  text-align: left;
  font: normal 16px Poppins;
  letter-spacing: 0px;
  color: #210b3a;
  opacity: 1;

  position: relative;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 450px;
`;

export const MyInput = styled(Input)`
  width: 100%;
  max-width: 450px;

  height: 70px !important;

  border: 2px solid #5f33ff !important;
  border-radius: 5px !important;
  opacity: 1 !important;

  color: #5f33ff !important;
  font-weight: bold;
`;

export const MyButton = styled(Button)`
  width: 100%;
  max-width: 450px;
  height: 70px !important;

  margin-top: 20px !important;

  background: #5f33ff 0% 0% no-repeat padding-box !important;
  border-radius: 5px !important;
  opacity: 1 !important;

  text-align: left !important;
  font: 1rem Poppins !important;
  letter-spacing: 0px !important;
  opacity: 1 !important;

  color: #fff !important;

  text-transform: none !important;

  span {
    span {
      color: #2ef5ff !important;
      margin-left: 5px;
    }
  }
`;

export const RegisterDialog = styled(Dialog)`
  border-radius: 20px !important;

  @media (max-width: 1200px) {
    .MuiDialog-paperWidthSm {
      max-width: inherit !important;
      width: 100vw !important;
      margin: 0px !important;
      max-height: inherit !important;
      height: 100vh !important;
    }
  }

  @media (min-width: 1200px) {
    .MuiDialog-paperWidthSm {
      max-width: inherit !important;
    }
  }
`;

export const RegisterDialogTitle = styled(DialogTitle)`
  font-size: 32px !important;
  font-weight: bold !important;

  margin-top: 20px !important;
  letter-spacing: 0px !important;
  color: #5f33ff !important;

  text-align: center !important;
`;

export const RegisterDialogContent = styled.div`
  width: 100vw !important;

  @media (min-width: 1200px) {
    width: 1020px !important;
  }
`;

export const RegisterDialogContentText = styled.div`
  padding: 20px 40px;
  text-align: center;
  font: normal normal medium 16px/25px Poppins !important;
  letter-spacing: 0px !important;
  color: #210b3a !important;

  a {
    font: normal normal bold 16px/25px Poppins;
  }

  @media (min-width: 1200px) {
    height: 700px;
    padding: 0 60px 60px 60px;
  }

  overflow: auto;
`;

export const RegisterCard = styled.form``;

export const RegisterCardHeader = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding-bottom: 5px;
  // border-bottom: 1px solid black;
`;

export const RegisterCardTitle = styled.div`
  text-align: left;
  font: 23px Poppins;
  letter-spacing: 0px;
  color: #172b4d;
  opacity: 1;
`;

export const RegisterCardButton = styled.div``;

export const RegisterForm = styled.div``;

interface InputGroupProps {
  readonly inputs: number;
}

export const InputGroup = styled.div<InputGroupProps>`
  display: grid;
  width: 100%;

  grid-template-columns: 1fr;

  @media (min-width: 1200px) {
    grid-template-columns: ${(props) => `repeat(${props.inputs}, 1fr)`};

    div + div {
      margin-left: 32px;
    }
  }

  div {
    display: flex;
    flex-direction: column;

    label {
      width: 100%;
      display: flex;

      text-align: left;
      font: normal normal normal 20px/33px Poppins;
      letter-spacing: 0px;
      color: #172b4d;
      opacity: 1;
    }
  }
`;

export const RegisterInput = styled(Input)`
  & > label {
    display: flex !important;
  }

  box-shadow: 0px 2px 4px #1717172e !important;
  border-radius: 5px !important;
  opacity: 0.8 !important;

  margin-top: 0 !important;
`;

export const RegisterMaskedInput = styled(MyMaskedInput)`
  & > label {
    display: flex !important;
  }

  box-shadow: 0px 2px 4px #1717172e !important;
  border-radius: 5px !important;
  opacity: 0.8 !important;

  margin-top: 0 !important;
`;

export const Spacing = styled.div`
  height: 40px;
`;

export const RegisterInputContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RegisterInputError = styled.div`
  color: red;
  font-size: 0.8rem;
  margin: 0.8rem 0.4rem;
  font-weight: 700;
  text-align: initial;
`;
