/* eslint-disable  */
import React, { useState, useEffect, useCallback } from "react";

import { Amount, Icon, KitRow, Label, LastMonth, Page, Panel, Statistic } from "./styles";
import { FiDollarSign, FiPackage, FiShoppingBag } from "react-icons/fi";
import Table1 from "components/Table1";
import { IKit } from "types";
import api from "services/api";
import { Edit } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import { KitStatusColor } from "components/KitMobile/styles";
import { useHistory } from "react-router-dom";
import loadash from "lodash";
import { toast } from "react-toastify";
import Loading from "components/Loading";

function Kits() {
  const [loading, setLoading] = useState<boolean>(false)
  const [offset, setOffset] = useState<number>(0)
  const [limit, setLimit] = useState<number>(5)
  const [sold, setSold] = useState<string>('')

  const [total, setTotal] = useState<number>(0)
  const [kits, setKits] = useState<IKit[]>([])

  const [csvData, setCSVdata] = useState<any[]>([]);

  const [percentages, setPercentages] = useState({
    total: "0",
    available: "0",
    sold: "0",
  });

  const [numbers, setNumbers] = useState({
    total: "0",
    available: "0",
    sold: "0",
  });

  const history = useHistory();

  useEffect(() => {
    api.get(`kits?limit=${limit}&offset=${offset}&sold=${sold}`)
      .then(res => {
        const { total, kits } = res.data;

        getCsvData()
        setKits(kits)
        setTotal(total)
      })
  }, [offset, limit, sold])

  const getCsvData = () => {
    api.get(`kits?toCSV=${true}`)
      .then(res => {
        const { kits } = res.data;

        const csvData = [["id", "sold", "user_id", "user_name", "user_email", "hasForm", "hasHtml", "hasPdf", "hasZip", "pack", "status"]];

        kits.forEach((kit: any) => {
          csvData.push([
            kit.id,
            kit.sold ? "Sim" : "Não",
            kit.user_id ? kit.user_id : "-",
            kit.user_name ? kit.user_name : "-",
            kit.user_email ? kit.user_email : "-",
            kit.hasForm ? "Sim" : "Não",
            kit.hasHtml ? "Sim" : "Não",
            kit.hasPdf ? "Sim" : "Não",
            kit.hasZip ? "Sim" : "Não",
            kit.pack_name,
            kit.status
          ]);
        });

        setCSVdata(csvData);


      })



  }

  const getFormatedData = (data) => {
    return new Intl.DateTimeFormat("pt-BR", {
      year: "numeric",
      month: "numeric",
      day: "numeric"
    }).format(new Date(data))
  }

  const getFilteredKits = (value: string) => {
    api.get(`kits?limit=${limit}&offset=${offset}&id=${value}`)
      .then(res => {
        const { total, kits } = res.data;
        getCsvData()
        setKits(kits)
        setTotal(total)
      })
  }

  async function getStatistics() {
    api
      .get("kits-statistics")
      .then((res) => {
        const { totalP, availableP, soldP, total, available, sold } = res.data;
        setPercentages({ total: totalP.toString(), available: availableP.toString(), sold: soldP.toString() })
        setNumbers({ total: total.toString(), available: available.toString(), sold: sold.toString() })
      })
      .catch((err) => console.log(err));
  }

  const debouncedSearch = useCallback(
    loadash.debounce(nextValue => getFilteredKits(nextValue), 1000),
    [],
  );

  useEffect(() => {
    getStatistics()
  }, [])


  const handleChangeSearch = (v) => {
    debouncedSearch(v)
  }

  const handlePreviousPage = () => {
    if (offset - limit < 0)
      return;
    setOffset(offset - limit)
  }

  const handleNextPage = () => {
    if (offset + limit >= total)
      return;

    setOffset(offset + limit)
  }

  const handleImportCsv = (file: File) => {
    const data = new FormData();
    data.append("kitCsv", file);

    api
      .post(`csv/kits`, data, { headers: { "Content-Type": "multipart/form-data" } })
      .then((res) => {
        const { total, updated, emailSent, emailFailed } = res.data;
        toast.success("Kits atualizados com sucesso respondido com sucesso!");
        toast.success(`Total: ${total}. Atualizados: ${updated}.`);
        toast.success(`Emails enviados com sucesso: ${emailSent}. `);
        toast.error(`Emails enviados sem sucesso: ${emailFailed}.`);
      })
      .catch((err) => console.log(err));
  }

  const handleImportBatch = (file: File) => {
    const data = new FormData();
    data.append("kitBatch", file);
    setLoading(true)

    api
      .post(`upload/kit-batch`, data, { headers: { "Content-Type": "multipart/form-data" } })
      .then((res) => {
        setLoading(false)
        toast.success("Lote atualizado com sucesso!")
      })
      .catch((err) => console.log(err));
  }

  return (
    <Page>
      <Loading active={loading} />
      <Panel>
        <Statistic onClick={() => setSold('')}>
          <Icon>
            <FiShoppingBag />
          </Icon>
          <Label>Total</Label>
          <Amount>{numbers.total}</Amount>
          <LastMonth>
            <span className={parseFloat(percentages.total) >= 0 ? "positive" : "negative"}>
              {parseFloat(percentages.total) >= 0 ? "+" : "-"}
              {percentages.total}%
            </span>
            Desde o mês passado
          </LastMonth>
        </Statistic>


        <Statistic onClick={() => setSold('true')}>
          <Icon>
            <FiDollarSign />
          </Icon>
          <Label>Vendidos</Label>
          <Amount>{numbers.sold}</Amount>
          <LastMonth>
            <span className={parseFloat(percentages.sold) >= 0 ? "positive" : "negative"}>
              {parseFloat(percentages.sold) >= 0 ? "+" : "-"}
              {percentages.sold}%
            </span>
            Desde o mês passado
          </LastMonth>
        </Statistic>


        <Statistic onClick={() => setSold('false')}>
          <Icon>
            <FiPackage />
          </Icon>
          <Label>Disponiveis</Label>
          <Amount>{numbers.available}</Amount>
          <LastMonth>
            <span className={parseFloat(percentages.available) >= 0 ? "positive" : "negative"}>
              {parseFloat(percentages.available) >= 0 ? "+" : "-"}
              {percentages.available}%
            </span>
            Desde o mês passado
          </LastMonth>
        </Statistic>
      </Panel>

      <Table1
        handleChangeSearch={handleChangeSearch}
        total={total}
        offset={offset}
        limit={limit} handleNextPage={handleNextPage}
        handlePreviousPage={handlePreviousPage}
        csvData={csvData}
        handleImportCsv={handleImportCsv}
        handleImportBatch={handleImportBatch}
      >
        {
          kits.map((kit: IKit) => {
            const hasResult = kit.hasHtml || kit.hasPdf;
            return (
              <KitRow key={kit.id}>
                <div>
                  <FiPackage />
                </div>
                <div className="kit">{kit.id}</div>
                <div>{kit.type}</div>
                <div>
                  {getFormatedData(kit.created_at)}
                </div>
                <div className="status" >
                  <KitStatusColor color={hasResult ? "#2EF5FF" : "#5F33FF"} />
                  {kit.status}
                </div>
                <div>
                  <IconButton aria-label="settings" onClick={() => history.push(`/kit/${kit.id}`)}>
                    <Edit />
                  </IconButton>
                </div>
              </KitRow>
            )
          })
        }
      </Table1>
    </Page>
  );
}

export default Kits;
