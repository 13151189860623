import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { FiFileText, FiPackage, FiSettings, FiHome, FiUser, FiMenu } from "react-icons/fi";
import api from "services/api";
import { IKitType } from "types";
import logo from "../../assets/images/agrega_logo_branco.png";
import { useAuth } from "../../contexts/auth";

// import ilu_8 from "../../assets/images/ilustração_8.png";
// import ilu_9 from "../../assets/images/ilustração_9.png";
// import ilu_10 from "../../assets/images/ilustração_10.png";

import "./styles.css";

export default function MobileMenu() {
  const [open, setOpen] = useState(false);
  const { logout, isAdmin } = useAuth();

  const [kitTypes, setKitTypes] = useState<IKitType[]>([]);

  useEffect(() => {
    if (isAdmin()) {
      api.get("kit-types").then((res) => {
        setKitTypes(res.data);
      });
      // .catch((err) => console.log("erro do sidebar"));
    } else {
      api.get("user/kit-types").then((res) => setKitTypes(res.data));
    }
  }, [isAdmin]);

  return (
    <main id={open ? "mobile-menu-container-open" : "mobile-menu-container"} className="mobile-menu-class">
      <header>
        <img src={logo} alt="Logo" />
        <FiMenu onClick={() => setOpen(!open)} />
      </header>

      {open && (
        <div className="menu-body">
          <ul>
            <li>
              <Link to="/" onClick={() => setOpen(!open)}>
                <FiHome />
                Home
              </Link>
            </li>
            <li>
              <Link to="/profile" onClick={() => setOpen(!open)}>
                <FiUser />
                Meu perfil
              </Link>
            </li>

            {kitTypes.map((kitType) => {
              return (
                <li key={kitType.id}>
                  <Link to={`/my-kits/${kitType.id}`} onClick={() => setOpen(!open)}>
                    <FiPackage />
                    {kitType.type}
                  </Link>
                </li>
              );
            })}
            {isAdmin() && (
              <>
                <li>
                  <Link to="/users" onClick={() => setOpen(!open)}>
                    <FiSettings />
                    Controle de Usuários
                  </Link>
                </li>
                <li>
                  <Link to="/kits">
                    <FiFileText />
                    Kits
                  </Link>
                </li>
              </>
            )}
          </ul>

          <Link to="/" className="sair" onClick={logout}>
            Sair
          </Link>

          {/* <div className="ilustracoes">
            <img src={ilu_8} alt="ilustracao" />
            <img src={ilu_9} alt="ilustracao" />
            <img src={ilu_10} alt="ilustracao" />
          </div> */}
        </div>
      )}
    </main>
  );
}
