import styled from "styled-components";

import { Checkbox } from "@material-ui/core";

export const Container = styled.div`
  margin: 2rem 0;
`;

export const Label = styled.label`
  text-align: left;
  font: normal normal bold 15px/23px Poppins;
  letter-spacing: 0px;
  color: #210b3a;
  opacity: 1;
`;

export const Options = styled.div`
  margin-top: 2rem;
`;

export const Option = styled.div`
  display: flex;
  flex-direction: column;

  color: #210b3a;
  font: normal normal bold 15px/23px Poppins;

  & + & {
    margin-top: 10px;
  }

  div {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    word-break: break-all;

    span {
      height: 20px;
      width: 20px;
      padding: 5px;
      margin: 0 5px;
      color: red;
    }
  }
`;

export const MyCheckbox = styled(Checkbox)``;
