import Dropzone from "components/Dropzone";
import React from "react";
import { TDialog, TDialogContent, TDialogContentText } from "./styles";

interface DialogProps {
  openDialog: boolean;
  handleImportBatch(file: File): void;
  handleCancel(): void;
}

export const ImportCsvDialog: React.FC<DialogProps> = ({ openDialog, handleCancel, handleImportBatch }) => {
  /* eslint-disable */
  return (
    <TDialog
      open={openDialog}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <TDialogContent>
        <TDialogContentText id="alert-dialog-description">
          <Dropzone onFileUploaded={handleImportBatch} label="Importar" />
        </TDialogContentText>
      </TDialogContent>
    </TDialog>
  )
  /* eslint-disable */
};

export default ImportCsvDialog;
