import React, { TextareaHTMLAttributes } from "react";

import "./styles.css";

export interface TextAreaNormal {
  value: string;
  label: string;
  name: string;
}

interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  question: TextAreaNormal;
  setFunction(question): void;
}

const Textarea: React.FC<TextareaProps> = ({ question, setFunction, ...rest }) => {
  const { name, label } = question;
  return (
    <div className="textarea-block">
      <label htmlFor={name}>{label}</label>
      <textarea id={name} onChange={(e) => setFunction({ ...question, value: e.target.value })} {...rest} />
    </div>
  );
};

export default Textarea;
