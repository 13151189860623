import Loading from "components/Loading";
import Page from "components/Page";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

function EmptyPage() {
  const [loading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    const getItem = async () => {
      const storagedForm = await localStorage.getItem("@RAuth:AGREGA:formCode");
      return storagedForm;
    };

    setTimeout(async () => {
      const storagedForm = await getItem();
      if (storagedForm) history.push(storagedForm);
      else history.push(`/home`);
    }, 1500);
  }, [history]);

  return (
    <Page>
      <Loading active={loading} />
    </Page>
  );
}

export default EmptyPage;
