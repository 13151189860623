import styled from "styled-components";

interface RadioProps {
  readonly selected: boolean;
}

export const Radio = styled.button<RadioProps>`
  border-radius: 50%;
  height: 24px;
  width: 24px;

  background: ${(props) => (props.selected ? "var(--color-roxo)" : "white")};
  color: ${(props) => (props.selected ? "white" : "var(--color-text-base)")};

  margin: 3px;
  outline: none;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 12px;
  font-weight: bold;
  border: 2px solid var(--color-roxo);
`;

export const Circle = styled.div`
  border-radius: 50%;
  height: 14px;
  width: 14px;

  background-color: var(--color-roxo);
`;

export const Row = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;

  border-bottom: 1px solid var(--color-line-in-white);
  padding: 5px 0;

  div {
    display: flex;
    flex-direction: column;

    .label {
      font-size: 10px;
      font-weight: bold;
      color: #210b3a;
      padding: 4px 0;
    }

    .options {
      display: flex;
      flex-direction: row;
    }
  }

  .meal {
    padding: 8px 0;
    p {
      margin: 0;
    }
    .meal-title {
      font-size: 20px;
      margin: 8px 0;
      text-align: left;

      // white-space: nowrap;
      // text-overflow: ellipsis;
      max-width: 80vw;
      overflow: hidden;
    }
    .meal-portion-title {
      color: var(--color-roxo);
      font-size: 12px;
      text-align: left;
    }
    .meal-portion {
      font-size: 10px;
      text-align: left;
    }
  }

  @media (min-width: 700px) {
    grid-template-columns: 1fr 2fr 1fr 1fr;

    .meal {
      .meal-title {
        font-size: 13px;
        margin: 4px 0;
        text-align: left;

        max-width: 150px;
      }
    }
  }
`;
