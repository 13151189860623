import styled from "styled-components";

import { Button, Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import Input from "components/Input";

export const MyInput = styled(Input)`
  width: 100%;
  // max-width: 450px;

  height: 70px !important;

  border: 2px solid #5f33ff !important;
  border-radius: 5px !important;
  opacity: 1 !important;

  color: #5f33ff !important;
  font-weight: bold;

  div {
    margin-top: 0 !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const TDialog = styled(Dialog)`
  border-radius: 20px !important;

  @media (max-width: 1200px) {
    .MuiDialog-paperWidthSm {
      max-width: inherit !important;
      width: 100vw !important;
      margin: 0px !important;
      max-height: inherit !important;
      height: 100vh !important;
    }
  }

  @media (min-width: 1200px) {
    .MuiDialog-paperWidthSm {
      max-width: inherit !important;
    }
  }
`;

export const TDialogTitle = styled(DialogTitle)`
  font-size: 32px !important;
  font-weight: bold !important;

  margin-top: 20px !important;
  letter-spacing: 0px !important;
  color: #5f33ff !important;

  text-align: center !important;
`;

export const TDialogContent = styled.div`
  width: 100vw !important;

  @media (min-width: 1200px) {
    width: 460px !important;
  }
`;

export const TDialogContentText = styled.div`
  padding: 20px 40px;
  text-align: center;
  // font: normal normal medium 16px/25px Poppins !important;
  letter-spacing: 0px !important;
  color: #210b3a !important;

  a {
    font: normal normal bold 16px/25px Poppins;
  }

  @media (min-width: 1200px) {
    // height: 700px;
    padding: 0 60px 60px 60px;
  }

  select,
  input {
    border: 2px solid var(--color-roxo);
    opacity: 1;
    border-radius: 5px;
  }

  overflow: auto;
`;

export const TDialogActions = styled(DialogActions)`
  margin-bottom: 20px !important;
  justify-content: center !important;
  flex-direction: column !important;

  @media (min-width: 700px) {
    flex-direction: row !important;
  }
`;

interface ButtonProps {
  readonly primary: string;
}

export const TDialogButton = styled(Button)`
  background: ${(props: ButtonProps) =>
    props.primary === "true"
      ? "#5f33ff 0% 0% no-repeat padding-box !important"
      : "#ACACAC  0% 0% no-repeat padding-box !important"};

  border-radius: 5px !important;
  opacity: 1 !important;
  text-transform: none !important;

  width: 100% !important;
  height: 52px !important;
  margin: 0 !important;

  span {
    text-transform: none !important;
    color: #fff !important;
  }

  @media (min-width: 700px) {
    width: 144px !important;
  }
`;

export const Spacing = styled.div`
  height: 40px;
`;
