import React from "react";
import { ToastContainer } from "react-toastify";
import Routes from "./routes/index";

import "./assets/styles/global.css";

import { AuthProvider } from "./contexts/auth";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <AuthProvider>
      <ToastContainer />
      <Routes />
    </AuthProvider>
  );
}

export default App;
