import styled from "styled-components";

import { Button as ButtonMD, Dialog as DialogMD, DialogActions as DialogActionsMD } from "@material-ui/core";

export const DialogTitle = styled.div`
  width: 100%;

  text-align: center;
  color: #210b3a;

  font-family: Coolvetica !important;

  span {
    color: #5f33ff;
  }

  font-size: 32px;
`;

export const DialogContent = styled.div`
  @media (min-width: 1200px) {
    width: 480px !important;
  }

  padding: 10px 40px;
`;

export const DialogActions = styled(DialogActionsMD)`
  margin-bottom: 20px !important;
  justify-content: center !important;
  flex-direction: column !important;

  button {
    font: 14px Poppins !important;
  }

  button + button {
    margin-top: 10px !important;
  }

  @media (min-width: 700px) {
    flex-direction: row !important;

    button + button {
      margin-left: 20px !important;
      margin-top: 0px !important;
    }
  }
`;

interface DialogButtonProps {
  primary?: string;
}

export const DialogButton = styled(ButtonMD)`
  background: ${(props: DialogButtonProps) =>
    props.primary === "true"
      ? "#5f33ff 0% 0% no-repeat padding-box !important"
      : "#ACACAC  0% 0% no-repeat padding-box !important"};

  border-radius: 5px !important;
  opacity: 1 !important;
  text-transform: none !important;

  width: 100% !important;
  height: 52px !important;
  margin: 0 !important;

  span {
    text-transform: none !important;
    color: #fff !important;
  }

  @media (min-width: 700px) {
    width: 144px !important;
  }
`;

export const Dialog = styled(DialogMD)`
  @media (min-width: 1200px) {
    .MuiDialog-paperWidthSm {
      max-width: inherit !important;
    }
  }
`;

interface SpacingProps {
  readonly height?: number;
}

export const Spacing = styled.div`
  height: ${(props: SpacingProps) => (props.height ? `${props.height}px` : "40px")};
`;
