import styled from "styled-components";

interface ButtonProps {
  readonly primary: boolean;
  readonly width?: string;
}

export const Container = styled.div`
  flex: 1 1 auto;
  height: 100vh;

  padding: 15px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Button = styled.button<ButtonProps>`
  padding: 20px;
  height: 4rem;

  width: ${(props) => (props.width ? props.width : "12rem")};

  border-radius: 5px;
  font: 700 0.8rem Poppins;

  display: flex;
  align-items: center;
  justify-content: center;

  text-decoration: none;

  border: ${(props) => (props.primary ? "0" : "1px solid var(--color-roxo);")};

  color: ${(props) => (props.primary ? "var(--color-button-text)" : "var(--color-roxo)")};
  background: ${(props) => (props.primary ? "var(--color-roxo)" : "#ffffff")};

  transition: background-color 0.2s;
  cursor: pointer;

  outline: 0;

  &:hover {
    opacity: 0.8;
  }
`;
