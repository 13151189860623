import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Landing from "../pages/Landing";
import Login from "../pages/Login";
import Register from "../pages/Register";
import NotFound from "../pages/NotFound";
import ResetPassword from "../pages/ResetPassword";

function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Landing} />
        <Route path="/scan/:type/:kit" component={Landing} />
        <Route path="/register/" exact component={Register} />
        <Route path="/register/:type/:kit" exact component={Register} />

        <Route path="/login" exact component={Login} />
        <Route path="/login/:type/:kit" exact component={Login} />
        <Route path="/reset_password/:token" component={ResetPassword} />
        <Route path="/not-found" component={NotFound} />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
}

export default Routes;
