import React from "react";

import "./styles.css";
import { Checkbox } from "@material-ui/core";
import Select from "../Select";
import { Container, Label, Options, Option } from "./styles";

interface CheckboxListWithSelectProps {
  question: Question;
  setFunction(question): void;
}

interface Question {
  label: string;
  options: CheckBoxWithSelect[];
  selectOptions: SelectNormalOption[];
}

interface SelectNormalOption {
  value: string;
  label: string;
}

interface CheckBoxWithSelect {
  label: string;
  checked: boolean;
  selectValue: string;
  selectName: string;
}

const CheckboxListWithSelect: React.FC<CheckboxListWithSelectProps> = ({ question, setFunction }) => {
  const { label, options, selectOptions } = question;
  return (
    <Container>
      <Label>{label}</Label>
      <Options>
        {options.map((opt) => {
          return (
            <Option key={opt.label}>
              <div>
                <Checkbox
                  checked={opt.checked}
                  onChange={(e, checked) =>
                    setFunction({
                      ...question,
                      options: options.map((option) => (option.label === opt.label ? { ...option, checked } : option))
                    })
                  }
                  name={opt.label}
                />
                <div>{opt.label}</div>
              </div>
              {opt.checked && (
                <div className="checkBox-select">
                  <Select
                    name={opt.selectName}
                    value={opt.selectValue}
                    placeholder="Quando foi diagnosticada?"
                    onChange={(e) => {
                      setFunction({
                        ...question,
                        options: options.map((option) =>
                          option.label === opt.label ? { ...option, selectValue: e.target.value } : option
                        )
                      });
                    }}
                    options={selectOptions}
                  />
                </div>
              )}
            </Option>
          );
        })}
      </Options>
    </Container>
  );
};

export default CheckboxListWithSelect;
