import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import KitDetails from "pages/KitDetails";
import Packs from "pages/Packs";
import FormSoil from "pages/FormSoil";
import NewUser from "pages/NewUser";
import Home from "../pages/Home";
import Profile from "../pages/Profile";
import Kits from "../pages/Kits";
import Users from "../pages/Users";
import NotFound from "../pages/NotFound";
import MyKits from "../pages/MyKits";
import Form from "../pages/Form";

import Sidebar from "../components/Sidebar";
import MobileMenu from "../components/MobileMenu";
import Empty from "../pages/Empty";

function Routes() {
  return (
    <BrowserRouter>
      <div className="main-container">
        <Sidebar />
        <MobileMenu />
        <div className="content">
          <Switch>
            <Redirect exact from="/" to="/home" />
            <Redirect exact from="/scan/:type/:kit" to="/my-kits/:type/:kit" />
            <Redirect exact from="/login" to="/home" />
            <Redirect exact from="/login/:type/:kit" to="/my-kits/:type/:kit" />
            {/* <Redirect exact from="/register/:type/:kit" to="/home" />
            <Redirect exact from="/register" to="/home" /> */}
            <Redirect from="/reset_password" to="/home" />
            <Route path="/register" exact component={Empty} />
            <Route path="/home" exact component={Home} />
            <Route path="/new-user" exact component={NewUser} />
            <Route path="/profile" component={Profile} />
            <Route path="/my-kits/:type/:kit" component={MyKits} />
            <Route path="/my-kits/:type" component={MyKits} />
            <Route path="/kits" component={Kits} />
            <Route path="/packs" component={Packs} />
            <Route path="/users" component={Users} />
            <Route path="/form/human/:id" exact component={Form} />
            <Route path="/form/soil/:id" exact component={FormSoil} />
            <Route path="/kit/:id" exact component={KitDetails} />
            <Route component={NotFound} />
          </Switch>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default Routes;

