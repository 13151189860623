import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import NewKitDialog from "components/Dialogs/NewKitDialog";
import KitMobile from "components/KitMobile";
import { IKit } from "types";
import { useAuth } from "../../contexts/auth";
import api from "../../services/api";
import KitsTable from "../../components/KitsTable";

import ilu_7 from "../../assets/images/ilustração_7.png";
import ilu_8 from "../../assets/images/ilustração_8.png";
import ilu_9 from "../../assets/images/ilustração_9.png";
import ilu_10 from "../../assets/images/ilustração_10.png";

import Loading from "../../components/Loading";
import { Container, Ilustracoes, KitList, Mobile, Page, Title } from "./styles";

function Home() {
  const { getUserId, triggerNewKitFunction } = useAuth();

  const [kits, setKits] = useState([]);

  const [code, setCode] = useState("");
  const [validCode, setValidCode] = useState(false);
  const [loading, setLoading] = useState(false);

  const [columns] = useState([
    { label: "Kit", field: "id" },
    { label: "Tipo", field: "type_id" },
    { label: "Data", field: "created_at" },
    { label: "Status", field: "status" }
  ]);

  const [openDialog, setOpenDialog] = useState(false);

  const history = useHistory();

  async function getKits() {
    // TODO: PEGAR KITS MAIS RECENTES!!
    // IDEIA: 3 ultimos kits
    api.get("/user/last-kits").then((res) => {
      setLoading(false);
      setKits(res.data);
    });
  }

  useEffect(() => {
    setLoading(true);
    getKits();
  }, []);

  const handleDisagree = () => {
    setOpenDialog(false);
  };

  async function handleCreateKit() {
    setLoading(true);
    api
      .post(`/user/kits/${code}`, { user_id: getUserId() })
      .then((res) => {
        if (res.status === 200) {
          toast.success("Kit cadastrado!");
          triggerNewKitFunction(); // para dar trigger no menu e aparecer nova classe de kit

          if (res.data.type_id === 1) {
            api
              .post("questionario/intestinal", { kit_id: code, answered: false })
              .then((formResponse) => {
                setLoading(false);

                if (formResponse.status === 204) {
                  history.push("/home");
                } else {
                  history.push(`/form/human/${formResponse.data.form_id}`);
                }
              })
              .catch((err) => {
                // console.log(err);
              });
          } else if (res.data.type_id === 2) {
            api
              .post("questionario/solo", { kit_id: code, answered: false })
              .then((formResponse) => {
                setLoading(false);
                if (formResponse.status === 204) {
                  history.push("/home");
                } else {
                  history.push(`/form/soil/${formResponse.data.form_id}`);
                }
              })
              .catch((err) => {
                // console.log(err);
              });
          }
        } else {
          toast.error("Erro ao cadastrar kit...!");
        }
        setCode("");
        getKits();
      })
      .catch((err) => {
        // console.log(err);
      });
  }

  const goToForm = (id: string, kit_id: string) => {
    const base_url_fetch = Number(kit_id) === 1 ? "/kit/questionario/intestinal" : "/kit/questionario/solo";
    const base_url_redirect = Number(kit_id) === 1 ? "/form/human" : "/form/soil";

    api.get(`${base_url_fetch}/${id}`).then((res) => {
      if (res.data && res.data.id) {
        history.push(`${base_url_redirect}/${res.data.id}`);
      } else {
        toast.error("Erro ao encontrar questionário.");
      }
    });
    // .catch((err) => console.log(err));
  };

  async function accessKit(hasResult: boolean, kit: string, extension: string) {
    if (!hasResult) {
      toast.error("Não há resultado disponível para esse kit ainda!");
      return;
    }

    setLoading(true);

    api
      .post(
        "access/kit",
        { kit_id: kit, extension },
        {
          responseType: "blob"
        }
      )
      .then((r) => {
        const x = new Blob([r.data], { type: "text/html" });
        const url = window.URL.createObjectURL(x);
        window.open(url, "_blank");
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  }

  function handleCheckCode() {
    if (!code) {
      toast.error("Informe um código!");
      return;
    }

    api
      .get(`kits/check/${code}`)
      .then((res) => {
        if (res.status === 200) {
          handleCreateKit();
          setValidCode(true);
        } else {
          toast.error("Código inválido");
          setValidCode(false);
        }
      })
      .catch((err) => {
        toast.error("Erro. Contate o suporte!");
      });
  }

  const handleAgree = () => {
    setOpenDialog(false);
    handleCheckCode();
  };

  // eslint-disable-next-line
  async function downloadKit(kit: string, extension: string) {
    setLoading(true);

    api
      .post(
        "download/kit",
        { kit_id: kit },
        {
          responseType: "blob"
        }
      )
      .then((r) => {
        const url = window.URL.createObjectURL(r.data);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${kit}.pdf`;
        a.click();
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  }

  const handleSetCode = (code) => {
    setCode(code);
  };

  return (
    <Page>
      <Loading active={loading} />

      <NewKitDialog
        openDialog={openDialog}
        handleCancel={handleDisagree}
        handleValidar={handleAgree}
        handleSetCode={handleSetCode}
        validCode={validCode}
        code={code}
      />

      <Container>
        <KitsTable
          title="Kits recentes"
          kit_type={0}
          kits={kits}
          columns={columns}
          onClickRegister={() => setOpenDialog(true)}
          maxHeight="400px"
        />

        <Ilustracoes>
          <img src={ilu_7} alt="ilustracao" />
          <img src={ilu_8} alt="ilustracao" />
          <img src={ilu_9} alt="ilustracao" />
          <img src={ilu_10} alt="ilustracao" />
        </Ilustracoes>
      </Container>

      <Mobile>
        <Title>Kits recentes</Title>
        <KitList>
          {kits.map((kit: IKit) => {
            return <KitMobile key={kit.id} kit={kit} onClickForm={goToForm} onClickAccess={accessKit} />;
          })}
        </KitList>
      </Mobile>
    </Page>
  );
}

export default Home;
