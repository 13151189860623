import Input from "components/Input";
import styled from "styled-components";

export const Page = styled.div`
  flex: 1 1 auto;

  background-color: var(--color-roxo);

  display: flex;
  justify-content: center;
  // align-items: center;

  overflow: hidden;

  @media (min-width: 1200px) {
    padding: 30px;
    height: 100vh;
  }
`;

export const Container = styled.div`
  display: none;

  @media (min-width: 1200px) {
    flex: 1 1 auto;
    width: 100%;

    min-height: 500px;
    max-width: 1000px;

    background-color: #fff;

    box-shadow: 0px 3px 6px #2c28281c;
    border-radius: 10px;

    display: flex;
  }
`;

export const Mobile = styled.div`
  flex: 1 1 auto;

  background-color: #fff;
  border-radius: 10px 10px 0 0;

  display: flex;
  flex-direction: column;
  align-items: center;

  overflow: hidden;

  @media (min-width: 1200px) {
    display: none;
  }
`;

export const Table = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const Header = styled.div`
  width: 100%;

  button {
    margin-top: 0 !important;
  }
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;

  font-size: 24px;
  color: #172b4d;

  border-bottom: 1px solid #1717172e;

  .input-block {
    margin-top: 0 !important;
  }
`;
export const Title = styled.div``;

export const MyInput = styled(Input)`
  & > label {
    display: flex !important;
  }

  border-radius: 50px !important;
  height: 35px !important;
`;
export const Main = styled.div`
  flex: 1 1 auto;
  overflow: auto;
`;
export const Footer = styled.div`
  height:
  width: 100%;
  padding: 20px 30px;
  height: 80px;
`;

export const ListHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 3fr 6fr 2fr;
  text-align: center;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  background-color: #f1f3f9;
  font-size: 11px;
  color: #8898aa;
  height: 44px;
`;

export const ListRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 3fr 6fr 2fr;
  text-align: center;
  padding: 5px !important;

  &:nth-child(odd) {
    background-color: #f7fafc;
  }

  font-size: 14px;
  color: #172b4d;
  height: 82px;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    font-size: 24px;
    color: var(--color-roxo);
  }

  button {
    margin-top: 0 !important;
    margin: auto !important;
  }

  .email {
    font-weight: bold;
    text-align: left;
    width: 100%;
  }

  .buttons {
    svg:hover {
      cursor: pointer;
    }

    svg + svg {
      margin-left: 10px;
    }
  }

  .role {
    .select-block {
      margin-top: 0 !important;

      select {
        margin-top: 0 !important;
      }
    }
  }

  .status {
    span {
      width: 10px;
      height: 10px;
      background-color: var(--color-azul);
      border-radius: 50%;
      margin-right: 5px;
    }
  }
`;
