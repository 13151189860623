import Select from "components/Select";
import React, { useEffect, useState } from "react";
import api from "services/api";
import { IKitType } from "types";
import { MyInput, Spacing, TDialog, TDialogActions, TDialogButton, TDialogContent, TDialogContentText } from "./styles";

interface DialogProps {
  openDialog: boolean;
  handleChangePack(pack: { name: string; quantity: number; kitType: number }): void;
  handleCancel(): void;
  handleSave(): void;
  pack: { name: string; quantity: number; kitType: number };
}

export const NewPackDialog: React.FC<DialogProps> = ({
  openDialog,
  handleCancel,
  handleChangePack,
  handleSave,
  pack
}) => {
  const [kitTypes, setKitTypes] = useState<IKitType[]>([]);

  async function getKitTypes() {
    const res = await api.get("kit-types");
    // console.log(res)
    setKitTypes(res.data);
  }

  useEffect(() => {
    getKitTypes();
  }, []);

  return (
    <TDialog
      open={openDialog}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <TDialogContent>
        <TDialogContentText id="alert-dialog-description">
          <Select
            name="kit-type"
            label="Tipo dos Kits:"
            value={pack.kitType}
            options={kitTypes.map((type: IKitType) => {
              return { value: type.id, label: type.type };
            })}
            onChange={(e) => handleChangePack({ ...pack, kitType: Number(e.target.value) })}
          />
          <MyInput
            name="name"
            label="Nome do pack:"
            value={pack.name}
            onChange={(e) => {
              handleChangePack({ ...pack, name: e.target.value });
            }}
          />
          <MyInput
            name="quantity"
            type="number"
            label="Quantidade"
            value={pack.quantity}
            onChange={(e) => {
              handleChangePack({ ...pack, quantity: Number(e.target.value) });
            }}
          />
          <Spacing />
        </TDialogContentText>
      </TDialogContent>
      <TDialogActions>
        <TDialogButton primary="false" onClick={handleCancel}>
          Cancelar
        </TDialogButton>
        <TDialogButton primary="true" onClick={handleSave} color="primary" autoFocus>
          Salvar
        </TDialogButton>
      </TDialogActions>
    </TDialog>
  );
  /* eslint-disable */
};

export default NewPackDialog;
