import styled from "styled-components";

export const Container = styled.div`
  margin: 2rem 0;

  .options {
    .option {
      display: flex;
      flex-direction: row;

      padding: 8px 0;
      display: flex;

      .option-label {
        display: flex;
        align-items: center;
      }
    }
  }
`;

export const Label = styled.label`
  text-align: left;
  font: normal normal bold 15px/23px Poppins;
  letter-spacing: 0px;
  color: #210b3a;
  opacity: 1;
`;

export const Option = styled.div`
  display: flex;
  flex-direction: column;

  color: #210b3a;
  font: normal normal bold 15px/23px Poppins;

  & + & {
    margin-top: 10px;
  }

  div {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    word-break: break-all;

    span {
      height: 20px;
      width: 20px;
      padding: 5px;
      margin: 0 5px;
      color: red;
    }
  }
`;

interface RadioProps {
  readonly selected: boolean;
}

export const Radio = styled.button<RadioProps>`
  border-radius: 50%;
  height: 18px;
  min-height: 18px;
  width: 18px;
  min-width: 18px;

  background: ${(props) => (props.selected ? "var(--color-roxo)" : "white")};
  color: ${(props) => (props.selected ? "white" : "var(--color-text-base)")};

  margin: 0 6px;
  outline: none;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 12px;
  font-weight: bold;
  border: 2px solid var(--color-roxo);
`;
