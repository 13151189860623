import React, { ButtonHTMLAttributes } from "react";

import { MyButton } from "./styles";

export const Button1: React.FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({ children, ...rest }) => {
  return (
    <MyButton onClick={rest.onClick} type={rest.type}>
      {children}
    </MyButton>
  );
};

export default Button1;
