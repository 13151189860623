import React, { useState, FormEvent } from "react";

import { useParams } from "react-router";

import { useAuth } from "contexts/auth";
import Page from "components/Page";
import Button1 from "components/Button1";
import Loading from "components/Loading";
import LandingLayout from "components/LandingLayout";
import { Container, Form, Header, MyInput, Spacing, Subtitle, Title } from "./styles";

function ResetPassword() {
  const params: { token: string } = useParams();

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { resetPassword } = useAuth();

  async function handleLogin(e: FormEvent) {
    e.preventDefault();
    setLoading(true);
    try {
      setLoading(true);
      await resetPassword({ email, password, name: "" }, params.token);
      setLoading(false);
    } catch (e) {
      setLoading(true);
    }
  }

  return (
    <Page>
      <Loading active={loading} />
      <LandingLayout>
        <Container>
          <Header>
            <Title>Recupere</Title>
            <Title>sua conta!</Title>
            <Subtitle>Utilize os campos abaixo para recuperar a senha da tua conta</Subtitle>
          </Header>
          <Spacing />

          <Form onSubmit={handleLogin}>
            <MyInput
              name="email"
              type="email"
              label="E-mail"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <MyInput
              name="password"
              type="password"
              label="Informe a nova senha"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <Spacing height={20} />
            <Button1 type="submit">Redefinir</Button1>
          </Form>
        </Container>
      </LandingLayout>
    </Page>
  );
}

export default ResetPassword;
