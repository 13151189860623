import React from "react";

import "./styles.css";

import LoadingOverlay from "react-loading-overlay";
import { LinearProgress } from "@material-ui/core";

interface LoadingProps {
  active: boolean;
  progress: number;
}

const Loading: React.FC<LoadingProps> = ({ active, progress, children }) => {
  return (
    <LoadingOverlay
      active={active}
      // spinner={<RingLoader size={150} color="#2ef5ff" />}
      className={active ? "overlay" : "overlay-off"}
    >
      <div className="overlay-content">
        Carregando...
        <LinearProgress className="linear-progress" variant="determinate" value={progress} />
        {progress}%
      </div>

      {children}
    </LoadingOverlay>
  );
};

export default Loading;
