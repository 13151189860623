import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Container, Message } from "./styles";

export default function NotFound() {
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      history.push("/");
    }, 1000);
  });

  return (
    <Container>
      <Message>Página não encontrada!</Message>
    </Container>
  );
}
