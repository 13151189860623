import React from "react";

// import "./styles.css";
import { Radio, Row } from "./styles";

interface TableRowProps {
  row: any;
  setRow(row): void;
}

const TableRow: React.FC<TableRowProps> = ({ setRow, row }) => {
  const timesOptions = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
  const timeUnitOptions = ["D", "S", "M", "A"];
  const portionOptions = ["P", "M", "G", "E"];

  return (
    <Row>
      <div className="meal">
        <p className="meal-title">{row.meal}</p>
        <p className="meal-portion-title">Porção média:</p>
        <p className="meal-portion">{row.average_portion}</p>
      </div>
      <div className="times">
        <div className="label">Quantas vezes você come?</div>
        <div className="options">
          {timesOptions.map((opt) => (
            <Radio key={opt} onClick={() => setRow({ ...row, time: opt })} selected={row.time === opt}>
              {opt}
            </Radio>
          ))}
        </div>
      </div>
      <div className="time-unit">
        <div className="label">De quanto em quanto tempo?</div>
        <div className="options">
          {timeUnitOptions.map((opt) => (
            <Radio key={opt} onClick={() => setRow({ ...row, timeUnit: opt })} selected={row.timeUnit === opt}>
              {opt}
            </Radio>
          ))}
        </div>
      </div>
      <div className="portion-size">
        <div className="label">Sua porção:</div>
        <div className="options">
          {portionOptions.map((opt) => (
            <Radio key={opt} onClick={() => setRow({ ...row, portion: opt })} selected={row.portion === opt}>
              {opt}
            </Radio>
          ))}
        </div>
      </div>
    </Row>
  );
};

export default TableRow;
