import React, { useState, useEffect } from "react";

import { useHistory, useParams } from "react-router-dom";

import { IKit, IUser } from "types";
import Loading from "components/LoadingProgress";
import Dropzone from "components/Dropzone";
import { ArrowBack, EditOutlined, SaveOutlined } from "@material-ui/icons";
import { IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import Input from "components/Input";
import { toast } from "react-toastify";
import Button1 from "components/Button1";
import Button2 from "components/Button2";
import api from "../../services/api";

import { Button } from "../../assets/styles/global";
import { Container, Content, Extension, Footer, Header, Page, RowHeader, RowValues } from "./styles";

function KitDetails() {
  const [kit, setKit] = useState<IKit>();
  const params: { id: string } = useParams();

  // eslint-disable-next-line
  const [user, setUser] = useState<IUser>();

  const [resultHtml, setResultHtml] = useState<File>();
  const [resultPdf, setResultPdf] = useState<File>();
  const [resultZip, setResultZip] = useState<File>();

  const [triggerDialog, setTriggerDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [editingStatus, setEditingStatus] = useState(false);

  const [progress, setProgress] = useState<number>(0);

  const history = useHistory();

  useEffect(() => {
    api.get(`kits/${params.id}`).then((res) => {
      if (res.status === 404) {
        toast.error("Kit não encontrado!");
        history.push("/home");
      } else {
        const { kit, user } = res.data;
        setKit(kit);
        setUser(user);
        setLoading(false);
      }
    });
    // eslint-disable-next-line
  }, [params.id]);

  const handleResultHtmlFileSelection = (file: File) => {
    setResultHtml(file);
  };

  const handleResultPdfFileSelection = (file: File) => {
    setResultPdf(file);
  };

  const handleResultZipFileSelection = (file: File) => {
    setResultZip(file);
  };

  const handleClickDownloadFile = (extension: string) => {
    if (!kit) return;
    setLoading(true);

    if (!kit.hasHtml && extension === "html") {
      toast.error("Arquivos indisponíveis");
      setLoading(false);
    } else if (!kit.hasPdf && extension === "pdf") {
      toast.error("Arquivos indisponíveis");
      setLoading(false);
    } else if (!kit.hasZip && extension === "zip") {
      toast.error("Arquivos indisponíveis");
      setLoading(false);
    } else {
      setProgress(0);

      if (extension === "zip") {
        api
          .post("downloadURL/kit", { kit_id: kit.id, extension })
          .then((r) => {
            if (r.status === 200) {
              const { url } = r.data;

              const a = document.createElement("a");
              a.href = url;
              a.download = `${kit.id}.${extension}`;
              a.click();
            } else {
              toast.error("Erro ao tentar baixar arquivo");
            }
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
          });
      } else {
        api
          .post(
            "download/kit",
            { kit_id: kit.id, extension },
            {
              responseType: "blob",
              onUploadProgress: (data) => {
                // Set the progress value to show the progress bar
                setProgress(Math.round((100 * data.loaded) / data.total));
              }
            }
          )
          .then((r) => {
            const url = window.URL.createObjectURL(r.data);
            const a = document.createElement("a");
            a.href = url;
            a.download = `${kit.id}.${extension}`;
            a.click();
            setLoading(false);
          })
          .catch((err) => setLoading(false));
      }
    }
  };

  const handleClickDelete = () => {
    if (!kit) return;

    api
      .delete(`kits/${kit.id}`)
      .then((res) => {
        toast.success("Kit excluído com sucesso");
        setLoading(false);
        history.push("/kits");
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  };

  const handleClickSave = () => {
    if (!kit) return;
    const data = new FormData();

    const parsedKit = { ...kit };

    if (resultHtml) {
      data.append("kitResultHtml", resultHtml);
      parsedKit.hasHtml = true;
    }

    if (resultPdf) {
      data.append("kitResultPdf", resultPdf);
      parsedKit.hasPdf = true;
    }

    if (resultZip) {
      data.append("kitResultZip", resultZip);
      parsedKit.hasZip = true;
    }

    if (!!resultHtml && !!resultPdf) parsedKit.status = "Completo";

    data.append("kit", JSON.stringify(parsedKit));

    setLoading(true);
    setProgress(0);

    api
      .post(`upload/kit/${kit.id}`, data, {
        onUploadProgress: (data) => {
          // Set the progress value to show the progress bar
          setProgress(Math.round((100 * data.loaded) / data.total));
        }
      })
      .then((res) => {
        toast.success("Kit atualizado com sucesso");
        setLoading(false);
        history.goBack();
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  };

  const getFormatedData = (data) => {
    return new Intl.DateTimeFormat("pt-BR", {
      year: "numeric",
      month: "numeric",
      day: "numeric"
    }).format(new Date(data));
  };

  return (
    <Page>
      <Loading active={loading} progress={progress} />
      <Dialog
        open={triggerDialog}
        onClose={() => setTriggerDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">ATENÇÃO</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Você tem certeza que quer deletar esse kit?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setTriggerDialog(false)} primary={false}>
            VOLTAR
          </Button>
          <Button onClick={handleClickDelete} primary={true}>
            Excluir
          </Button>
        </DialogActions>
      </Dialog>

      <Container>
        <Header>
          <div className="title">
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => history.push("/kits")}>
              <ArrowBack />
            </IconButton>
            Detalhes do Kit
          </div>
          <div className="buttons">
            <Button2 onClick={() => setTriggerDialog(true)}>Excluir</Button2>
            <Button1 onClick={handleClickSave}>
              <span>Salvar</span>
            </Button1>
          </div>
        </Header>

        <Content>
          {kit && (
            <>
              <RowHeader>
                <div>ID</div>
                <div>Data de criação</div>
                <div>Pack</div>
                <div>Status</div>
                <div>Usuário</div>
              </RowHeader>

              <RowValues>
                <div>{kit.id}</div>
                <div>{getFormatedData(kit.created_at)}</div>
                <div>{kit?.pack_id ? kit.pack_name : "--"}</div>
                <div>
                  {editingStatus ? (
                    <div className="value">
                      <Input
                        name="kit-status"
                        value={kit?.status}
                        label=""
                        onChange={(e) => setKit({ ...kit, status: e.target.value })}
                      />{" "}
                      <SaveOutlined onClick={() => setEditingStatus(false)} />
                    </div>
                  ) : (
                    <div className="value">
                      {kit?.status} <EditOutlined onClick={() => setEditingStatus(true)} />
                    </div>
                  )}
                </div>
                <div>{user?.name || "--"}</div>
              </RowValues>

              <RowHeader>
                <div>Vendido</div>
                <div>Tipo</div>
                <div>Tem questionário</div>
                <div>Tem resultado</div>
              </RowHeader>

              <RowValues>
                <div>{kit.sold ? "Sim" : "Não"}</div>
                <div>{kit.type_id ? kit.type : "--"}</div>
                <div>{kit.hasForm ? "Sim" : "Não"}</div>
                <div>{kit.hasPdf || kit.hasHtml || kit.hasZip ? "Sim" : "Não"}</div>
              </RowValues>
            </>
          )}
        </Content>

        {kit && (
          <Footer>
            {[1, 2, 3].includes(kit.type_id) && (
              <>
                <Extension>
                  <div className="title">HTML</div>
                  {kit.hasHtml ? (
                    <Button1 onClick={() => handleClickDownloadFile("html")}>
                      <span>Download</span>
                    </Button1>
                  ) : (
                    <Button2 onClick={() => handleClickDownloadFile("html")}>Download</Button2>
                  )}
                  <Dropzone
                    onFileUploaded={handleResultHtmlFileSelection}
                    label={resultHtml ? resultHtml.name : "Upload"}
                    extensions=".html"
                  />
                </Extension>

                <Extension>
                  <div className="title">PDF</div>
                  {kit.hasPdf ? (
                    <Button1 onClick={() => handleClickDownloadFile("pdf")}>
                      <span>Download</span>
                    </Button1>
                  ) : (
                    <Button2 onClick={() => handleClickDownloadFile("pdf")}>Download</Button2>
                  )}
                  <Dropzone
                    onFileUploaded={handleResultPdfFileSelection}
                    label={resultPdf ? resultPdf.name : "Upload"}
                    extensions=".pdf"
                  />
                </Extension>
              </>
            )}
            {[3, 4].includes(kit.type_id) && (
              <>
                <Extension>
                  <div className="title">Zip</div>
                  {kit.hasZip ? (
                    <Button1 onClick={() => handleClickDownloadFile("zip")}>
                      <span>Download</span>
                    </Button1>
                  ) : (
                    <Button2 onClick={() => handleClickDownloadFile("zip")}>Download</Button2>
                  )}
                  <Dropzone
                    onFileUploaded={handleResultZipFileSelection}
                    label={resultZip ? resultZip.name : "Upload"}
                    extensions=".zip"
                  />
                </Extension>
              </>
            )}
          </Footer>
        )}
      </Container>
    </Page>
  );
}

export default KitDetails;
