import React, { SelectHTMLAttributes } from "react";

import "./styles.css";

export interface SelectNormal {
  name: string;
  value: string;
  label: string;
  options: SelectNormalOption[];
}

export interface SelectNormalOption {
  value: string;
  label: string;
}

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  question: SelectNormal;
  setFunction(question): void;
  placeholder?: string;
}

const Select: React.FC<SelectProps> = ({ question, setFunction, placeholder, ...rest }) => {
  const { value, name, label, options } = question;

  return (
    <div className="select-block">
      {label && <label htmlFor={name}>{label}</label>}
      <select value={value} id={name} onChange={(e) => setFunction({ ...question, value: e.target.value })} {...rest}>
        <option value="" disabled hidden>
          {placeholder || "Selecione uma opção"}
        </option>
        {options.map((option) => {
          return (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default Select;
