/* eslint-disable no-underscore-dangle */
import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_NODE_ENV === "DEV" ? process.env.REACT_APP_API_DEV_URL : process.env.REACT_APP_API_URL
  // baseURL: "http://ec2-18-231-176-22.sa-east-1.compute.amazonaws.com:3333/api"
});

api.interceptors.response.use(
  async (response) => {
    return response;
  },

  async (error) => {
    const originalRequest = error.config;
    const token = await localStorage.getItem("@RAuth:AGREGA:token");
    const storagedUser = await localStorage.getItem("@RAuth:AGREGA:user");

    if (error.response.status === 401 && !originalRequest._retry && token && storagedUser) {
      originalRequest._retry = true;

      // const user = JSON.parse(storagedUser);

      // api.post("refresh", { token, id: user.id, role_id: user.role_id }).then(async (res) => {
      //   if (res.status === 201) {
      //     await localStorage.setItem("@RAuth:AGREGA:token", res.data.token);
      //     api.defaults.headers.Authorization = `Bearer ${res.data.token}`;
      //     originalRequest.headers.Authorization = `Bearer ${res.data.token}`;
      //     api(originalRequest);
      //   }
      // });

      localStorage.clear();
      window.location.href = "/";
    }
    return error.response;
  }
);

// export const getForms = () => {
//   api.get("/questionarios/intestinal").then((res) => {
//     console.log(res);
//   });
// };

export default api;
