/* eslint-disable @typescript-eslint/ban-types */
import React from "react";

import "./styles.css";
import Input from "../Input";
import Select from "../Select";

// interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
//   name: string;
//   label?: string;
//   placeholder?: string;
//   options: Array<{ value: string; label: string; hasInput?: boolean }>;
// }

interface SelectWithInputProps {
  setFunction: Function;
  question: Question;
}

interface Question {
  name: string;
  value: string;
  label: string;
  options: SelectWithInputOption[];
  inputValue: string;
  inputName: string;
  inputLabel: string;
  inputTriggered: boolean;
}

interface SelectWithInputOption {
  value: string;
  label: string;
  triggersInput: boolean;
}

/* eslint-disable */
const SelectWithInput: React.FC<SelectWithInputProps> = ({ question, setFunction }) => {
  const { name, value, label, options, inputValue, inputName, inputLabel, inputTriggered } = question;
  return (
    <div className="select-with-input">
      <Select
        name={name}
        label={label}
        value={value}
        options={options}
        onChange={(e) => {
          options.map((opt) =>
            opt.value === e.target.value
              ? setFunction({
                ...question,
                inputTriggered: opt.triggersInput,
                value: e.target.value
              })
              : null
          );
        }}
      />
      {inputTriggered && (
        <Input
          name={inputName}
          label={inputLabel}
          value={inputValue}
          onChange={(e) => {
            setFunction({ ...question, inputValue: e.target.value });
          }}
        />
      )}
    </div>
  );
};
/* eslint-disable */


export default SelectWithInput;
