import styled from "styled-components";

import { Button as ButtonMD } from "@material-ui/core";

interface TableContainerProps {
  maxHeight?: string;
}

export const TableContainer = styled.div<TableContainerProps>`
  flex: 1 1 auto;
  width: 100%;

  max-width: 1000px;

  margin-top: 80px;
  max-height: ${(props) => (props.maxHeight ? `${props.maxHeight}` : `none`)};

  background-color: #fff;

  box-shadow: 0px 3px 6px #2c28281c;
  border-radius: 10px;

  display: flex;
  flex-direction: column;

  overflow: hidden;
`;

export const TableHeader = styled.div`
  width: 100%;
  height: 100px;

  padding: 40px;

  display: flex;

  justify-content: space-between;
  align-items: center;

  .title {
    font: 24px Poppins;
  }

  div {
    font-family: Gotham;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const TableContent = styled.div`
  flex: 1 1 auto;
  width: 100%;

  height: 300px;

  display: flex;
  flex-direction: column;

  overflow: auto;

  font-family: Gotham;

  .status {
    font-weight: 400;
    font-size: 14px;
  }

  .empty-table {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    // margin-top: 0.8rem;
    margin: auto;
    height: 60px;
    font-size: 1.6rem;
  }

  .extra-small-list {
    display: grid;
    grid-template-columns: 1fr 3fr 2fr 2fr 2fr;
    text-align: center;
    height: 50px;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .small-list {
    display: grid;
    grid-template-columns: 1fr 5fr 2fr 2fr 4fr;
    text-align: center;
    height: 50px;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .list {
    display: grid;
    grid-template-columns: 1fr 4fr 2fr 2fr 2fr 2fr;
    text-align: center;
    background: #fff;
    height: 50px;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .list-header {
    background-color: #f1f3f9;
    font-size: 11px;
    color: #8898aa;
    height: 44px;
  }

  .list-row {
    font-size: 14px;
    color: #172b4d;
    height: 82px;
  }

  .list-row:nth-child(odd) {
    background-color: #f7fafc;
  }

  .button-1 {
    background: var(--color-roxo) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #2c28281c;
    border-radius: 5px;
    color: #fff;
    font-size: 11px;
    width: 101px;
    cursor: pointer;
    height: 36px;

    outline: none;
    border: none;
  }

  .button-2 {
    background: inherit;
    border: 1px solid var(--color-roxo);
    box-shadow: 0px 3px 6px #2c28281c;
    border-radius: 5px;
    color: var(--color-roxo);
    font-size: 11px;
    width: 101px;
    height: 36px;
    cursor: pointer;

    outline: none;
  }

  .list-row svg {
    font-size: 24px;
    color: var(--color-roxo);
  }

  .result-buttons {
    display: flex;
    flex-direction: column;

    button {
      height: 25px;
    }

    button + button {
      margin-top: 8px;
    }
  }
`;

interface KitStatusColorProps {
  readonly color: string;
}

export const KitStatusColor = styled.div<KitStatusColorProps>`
  width: 10px;
  height: 10px;
  background-color: ${(props) => `${props.color}`};
  border-radius: 50%;
  margin-right: 5px;
`;

export const Button = styled(ButtonMD)`
  background: #5f33ff 0% 0% no-repeat padding-box !important;

  border-radius: 5px !important;
  opacity: 1 !important;
  text-transform: none !important;

  width: 180px !important;
  height: 52px !important;

  span {
    text-transform: none !important;
    color: #fff !important;
    font: 17px Poppins;
  }
`;
