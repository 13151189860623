import Button1 from "components/Button1";
import React, { useState } from "react";

import { CSVLink } from "react-csv";
import ImportCsvDialog from "components/Dialogs/ImportCsvDialog";
import ImportBatchDialog from "components/Dialogs/ImportBatchDialog";
import { Item, MyInput, TableContainer, TableContent, TableFooter, TableHeader } from "./styles";

interface Trable1Props {
  handlePreviousPage(): void;
  handleNextPage(): void;
  handleChangeSearch(value: string): void;
  handleImportCsv(file: File): void;
  handleImportBatch(file: File): void;
  total: number;
  offset: number;
  limit: number;
  csvData: any[];
}

export const Table1: React.FC<Trable1Props> = ({
  children,
  handleImportCsv,
  handleImportBatch,
  handleChangeSearch,
  handleNextPage,
  handlePreviousPage,
  total,
  offset,
  limit,
  csvData
}) => {
  const [value, setValue] = useState("");

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openBatchDialog, setOpenBatchDialog] = useState<boolean>(false);

  const handleChangeValue = (value: string) => {
    setValue(value);
    handleChangeSearch(value);
  };

  return (
    <TableContainer>
      <ImportCsvDialog
        openDialog={openDialog}
        handleImportCsv={(f) => {
          setOpenDialog(false);
          handleImportCsv(f);
        }}
        handleCancel={() => setOpenDialog(false)}
      />

      <ImportBatchDialog
        openDialog={openBatchDialog}
        handleImportBatch={(f) => {
          setOpenBatchDialog(false);
          handleImportBatch(f);
        }}
        handleCancel={() => setOpenBatchDialog(false)}
      />

      <TableHeader>
        <div>
          <div className="title">Kits</div>
          <MyInput
            name="search"
            onChange={(e) => handleChangeValue(e.target.value)}
            style={{ marginTop: 0 }}
            label=""
          />
        </div>
        <div>
          <Button1>
            <CSVLink data={csvData} filename="kits.csv" className="csvLink">
              Exportar
            </CSVLink>
          </Button1>

          <Button1 onClick={() => setOpenDialog(true)}>Importar</Button1>
          <Button1 onClick={() => setOpenBatchDialog(true)}>Upload de Lote</Button1>
        </div>
      </TableHeader>
      <TableContent>{children}</TableContent>

      {!value && (
        <TableFooter>
          <Item onClick={handlePreviousPage}>{"<"}</Item>
          <Item selected={false}>{offset >= total ? 0 : offset / limit + 1}</Item>
          <Item onClick={handleNextPage}>{">"}</Item>
        </TableFooter>
      )}
    </TableContainer>
  );
};

export default Table1;
