import styled from "styled-components";

import { Button } from "@material-ui/core";

export const MyButton = styled(Button)`
  width: 100%;
  // max-width: 450px;
  height: 60px !important;

  // margin-top: 20px !important;

  background: inherit !important;
  border: 2px solid #5f33ff !important;
  border-radius: 5px !important;
  opacity: 1 !important;

  text-align: left !important;
  font: 1rem Poppins !important;
  letter-spacing: 0px !important;
  opacity: 1 !important;

  color: #5f33ff !important;

  text-transform: none !important;

  span {
    span {
      color: #2ef5ff !important;
      margin-left: 5px;
    }
  }
`;
