import axios from "axios";

export interface CEPResponse {
  bairro: string;
  cep: string;
  complemento: string;
  ddd: string;
  localidade: string;
  logradouro: string;
  uf: string;
  erro?: boolean;
}

const service = axios.create({
  baseURL: "https://viacep.com.br/"
});

export function getCEP(cep: string) {
  return service.get<CEPResponse>(`/ws/${cep}/json/`);
}

export async function validarCEP(cep: string | undefined) {
  try {
    const response = await service.get<CEPResponse>(`/ws/${cep}/json/`);

    if (response.data.erro) {
      return false;
    }
  } catch (e) {
    return false;
  }

  return true;
}

export default service;
