import styled from "styled-components";

export const Page = styled.div`
  flex: 1 1 auto;

  background-color: var(--color-roxo);

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  overflow: hidden;

  @media (min-width: 1200px) {
    padding: 15px;
    height: 100vh;
  }
`;

export const PackRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 5fr 4fr 2fr 1fr 1fr;
  text-align: center;

  flex-grow: 1;
  max-height: 80px;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .kit {
    text-align: start;
    font-size: 14px;
    justify-content: flex-start;
  }

  svg {
    font-size: 24px;
    color: var(--color-roxo);
    cursor: pointer;
  }

  &:nth-child(odd) {
    background-color: #f7fafc;
  }

  .input-block {
    margin-top: 0;
  }
  input {
    margin-top: 0;
    width: 100%;
    // max-width: 450px;

    height: 40px !important;

    border: 2px solid #5f33ff !important;
    border-radius: 5px !important;
    opacity: 1 !important;

    color: #5f33ff !important;
    font-weight: bold;

    div {
      margin-top: 0 !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
