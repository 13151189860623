import styled from "styled-components";

import Input from "components/Input";
import MyMaskedInput from "components/MaskedInput";

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  // justify-content: center;
  align-items: center;

  width: 100%;

  text-align: left;
  color: #210b3a;
  font: 1rem Poppins

  font-weight: bold;
  font-size: 80px;

  span {
    color: #5f33ff;
  }

  font-size: 30px;


`;

export const Form = styled.form`
  flex: 1 1 auto;

  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const FormContent = styled.div`
  overflow-y: auto;
  padding: 30px;
`;

export const Main = styled.div`
  flex: 1 1 auto;
  width: 100%;
  min-height: 500px;
  max-width: 1000px;

  background-color: #fff;

  box-shadow: 0px 3px 6px #2c28281c;
  border-radius: 10px 10px 0 0;

  display: flex;
`;

export const Container = styled.div`
  flex: 1 1 auto;

  background-color: var(--color-roxo);

  display: flex;
  justify-content: center;
  // align-items: center;

  overflow: hidden;

  @media (min-width: 1200px) {
    padding: 15px 15px 0 15px;
    height: 100vh;
  }
`;

export const Header = styled.header`
  button {
    margin-top: 0 !important;
  }
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 30px;

  font-size: 24px;
  color: #172b4d;

  border-bottom: 1px solid #1717172e;
`;

interface InputGroupProps {
  readonly inputs: number;
}

export const InputGroup = styled.div<InputGroupProps>`
  display: grid;
  width: 100%;

  grid-template-columns: 1fr;

  button {
    margin-top: 20px;
  }

  @media (min-width: 1200px) {
    grid-template-columns: ${(props) => `repeat(${props.inputs}, 1fr)`};

    div + div {
      margin-left: 32px;
    }
  }

  div {
    display: flex;
    flex-direction: column;

    label {
      width: 100%;
      display: flex;

      text-align: left;
      font: normal normal normal 20px/33px Poppins;
      letter-spacing: 0px;
      color: #172b4d;
      opacity: 1;
    }
  }
`;

export const MyInput = styled(Input)`
  & > label {
    display: flex !important;
  }

  box-shadow: 0px 2px 4px #1717172e !important;
  border-radius: 5px !important;
  opacity: 0.8 !important;

  margin-top: 0 !important;
`;

export const MaskedInput = styled(MyMaskedInput)`
  & > label {
    display: flex !important;
  }

  box-shadow: 0px 2px 4px #1717172e !important;
  border-radius: 5px !important;
  opacity: 0.8 !important;

  margin-top: 0 !important;
`;

export const InputContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputError = styled.div`
  color: red;
  font-size: 0.8rem;
  margin: 0.8rem 0.4rem;
  font-weight: 700;
  text-align: initial;
`;
