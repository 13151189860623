import styled from "styled-components";

import { Button } from "@material-ui/core";

export const MyButton = styled(Button)`
  width: 100%;
  // max-width: 450px;
  height: 60px !important;

  // margin-top: 20px !important;

  background: #5f33ff 0% 0% no-repeat padding-box !important;
  border-radius: 5px !important;
  opacity: 1 !important;

  text-align: left !important;
  font: 1rem Poppins !important;
  letter-spacing: 0px !important;
  opacity: 1 !important;

  color: #fff !important;

  text-transform: none !important;

  span {
    span {
      color: #2ef5ff !important;
      margin-left: 5px;
    }
  }

  .csvLink {
    text-decoration: none !important;
    color: #fff !important;

    a {
      text-decoration: none !important;
      color: #fff !important;
      color: var(--color-button-text);
    }
  }
`;
