import React, { InputHTMLAttributes } from "react";

import "./styles.css";

export interface InputNormal {
  value: string;
  label: string;
  name: string;
}

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  question: InputNormal;
  setFunction(question): void;
}

const Input: React.FC<InputProps> = ({ question, setFunction, ...rest }) => {
  const { value, label, name } = question;

  return (
    <div className="input-block">
      <label htmlFor={name}>{label}</label>
      <input id={name} value={value} onChange={(e) => setFunction({ ...question, value: e.target.value })} {...rest} />
    </div>
  );
};

export default Input;
