import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";

import "./styles.css";

interface MyDropZoneProps {
  onFileUploaded: any;
  label?: string;
  extensions?: string;
}

const MyDropzone: React.FC<MyDropZoneProps> = ({ onFileUploaded, label, extensions }) => {
  const [, setSelectedFileURL] = useState("");

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      const fileUrl = URL.createObjectURL(file);

      setSelectedFileURL(fileUrl);
      onFileUploaded(file);
    },
    [onFileUploaded]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop
  });

  return (
    <div className="dropzone" {...getRootProps()}>
      <input {...getInputProps()} accept={extensions} />
      <p>{label || "Fazer Upload"} </p>
    </div>
  );
};

export default MyDropzone;
