export interface CheckBoxListWithSelect {
  label: string;
  name: string;
  options: CheckBoxWithSelectt[];
  selectOptions: SelectNormalOption[];
}

export interface CheckBoxWithSelectt {
  label: string;
  checked: boolean;
  selectValue: string;
  selectName: string;
}

export interface CheckboxListWithInput {
  name: string;
  label: string;
  options: CheckBoxWithInputOption[];
}

export interface CheckBoxWithInputOption {
  value: string;
  label: string;
  checked: boolean;
  triggersInput: boolean;
  inputValue?: string;
  inputName?: string;
  inputLabel?: string;
}

export interface CheckboxList {
  name: string;
  label: string;
  options: CheckBoxOption[];
}

export interface CheckBoxOption {
  value: string;
  label: string;
  checked: boolean;
}

export interface Table {
  name: string;
  rows: TableRow[];
}

export interface TableRow {
  meal: string;
  times: string;
  time_unit: string;
  portion: string;
  average_portion: string;
}

export interface SelectWithInput {
  name: string;
  value: string;
  label: string;
  options: SelectWithInputOption[];
  inputValue: string;
  inputName: string;
  inputLabel: string;
  inputTriggered: boolean;
}

export interface SelectWithInputOption {
  value: string;
  label: string;
  triggersInput: boolean;
}

export interface SelectWithSelect {
  name: string;
  value: string;
  label: string;
  options: SelectWithSelectOption[];
  selectOptions: SelectNormalOption[];
  selectValue: string;
  selectName: string;
  selectLabel: string;
  selectTriggered: boolean;
}

export interface SelectWithSelectOption {
  value: string;
  label: string;
  triggersSelect: boolean;
}

export interface SelectNormal {
  name: string;
  value: string;
  label: string;
  options: SelectNormalOption[];
}

export interface SelectNormalOption {
  value: string;
  label: string;
}

export interface InputNormal {
  value: string;
  label: string;
  name: string;
}

export interface TextAreaNormal {
  value: string;
  label: string;
  name: string;
}

export interface AutoComplete {
  value: string;
  inputValue: string;
  label: string;
  id: string;
  name: string;
}

export const S01Q01: AutoComplete = {
  label: "Estado",
  value: "",
  inputValue: "",
  id: "uf",
  name: "S01Q01"
};

export const S01Q02: AutoComplete = {
  label: "Cidade",
  value: "",
  inputValue: "",
  id: "cidade",
  name: "S01Q02"
};

export const S01Q03: CheckboxList = {
  label: "Como você definiria a sua ancestralidade/origem? (Você pode selecionar mais de uma alternativa! 🤓)",
  name: "S01Q03",
  options: [
    {
      value: "Países Ibéricos (Portugal e Espanha)",
      label: "Países Ibéricos (Portugal e Espanha)",
      checked: false
    },
    { value: "Afrodescendente", label: "Afrodescendente", checked: false },
    { value: "Itália", label: "Itália", checked: false },
    {
      value: "Ilhas Britânicas (Reino Unido e Irlanda)",
      label: "Ilhas Britânicas (Reino Unido e Irlanda)",
      checked: false
    },
    {
      value: "Europa Ocidental (França, Holanda e Alemanha)",
      label: "Europa Ocidental (França, Holanda e Alemanha)",
      checked: false
    },
    { value: "Indígena", label: "Indígena", checked: false },
    { value: "Asiática", label: "Asiática", checked: false },
    { value: "Judeu Ashkenazi", label: "Judeu Ashkenazi", checked: false },
    {
      value: "Península Balcânica (Croácia, Grécia e Bulgária)",
      label: "Península Balcânica (Croácia, Grécia e Bulgária)",
      checked: false
    },
    { value: "Outros", label: "Outros", checked: false },
    { value: "Não sei", label: "Não sei", checked: false }
  ]
};

// PARTE 2

export const S02Q01: InputNormal = {
  label: "Qual a sua idade? (Anos)",
  value: "",
  name: "S02Q01"
};

export const S02Q02: SelectNormal = {
  label: "Qual o status de gênero atribuído no seu nascimento?",
  value: "",
  name: "S02Q02",
  options: [
    { value: "Masculino", label: "Masculino" },
    { value: "Feminino", label: "Feminino" },
    { value: "Outro", label: "Outro" },
    { value: "Prefiro não dizer", label: "Prefiro não dizer" }
  ]
};

export const S02Q03: InputNormal = {
  label: "Qual o seu peso? (Kg)",
  value: "",
  name: "S02Q03"
};

export const S02Q04: SelectNormal = {
  label: "O seu peso mudou mais de 4,5 kg no ano passado?",
  value: "",
  name: "S02Q04",
  options: [
    { value: "Diminuiu mais de 4,5 kg", label: "Diminuiu mais de 4,5 kg" },
    { value: "Permaneceu estável", label: "Permaneceu estável" },
    { value: "Aumentou mais de 4,5 kg", label: "Aumentou mais de 4,5 kg" },
    { value: "Não sei", label: "Não sei" }
  ]
};

export const S02Q05: InputNormal = {
  label: "Qual a sua altura? (cm)",
  value: "",
  name: "S02Q05"
};

export const S02Q06: SelectNormal = {
  label: "Qual o seu tipo sanguíneo? 🩸",
  value: "",
  name: "S02Q06",
  options: [
    { value: "A+", label: "A+" },
    { value: "A-", label: "A-" },
    { value: "B+", label: "B+" },
    { value: "B-", label: "B-" },
    { value: "O+", label: "O+" },
    { value: "O-", label: "O-" },
    { value: "AB+", label: "AB+" },
    { value: "AB-", label: "AB-" },
    { value: "Não sei", label: "Não sei" }
  ]
};

export const S02Q07: CheckBoxListWithSelect = {
  label: "Você possui algumas das seguintes condições? Se sim, informe quando a condição foi diagnosticada?",
  options: [
    { checked: false, selectName: "", selectValue: "", label: "Alzheimer" },
    {
      checked: false,
      selectName: "",
      selectValue: "",
      label: "Cálculos biliares"
    },
    {
      checked: false,
      selectName: "",
      selectValue: "",
      label: "Colesterol Alto"
    },
    {
      checked: false,
      selectName: "",
      selectValue: "",
      label: "Colite microscópica (microcolite)"
    },
    {
      checked: false,
      selectName: "",
      selectValue: "",
      label: "Colite ulcerativa"
    },
    {
      checked: false,
      selectName: "",
      selectValue: "",
      label: "Diabetes tipo 1"
    },
    {
      checked: false,
      selectName: "",
      selectValue: "",
      label: "Diabetes tipo 2"
    },
    {
      checked: false,
      selectName: "",
      selectValue: "",
      label: "Diabetes gestacional"
    },
    { checked: false, selectName: "", selectValue: "", label: "Dispepsia" },
    {
      checked: false,
      selectName: "",
      selectValue: "",
      label: "Distúrbios neurocomportamentais (Autismo e TDAH )"
    },
    {
      checked: false,
      selectName: "",
      selectValue: "",
      label:
        "Doenças cardíacas (arritmia cardíaca, doença cardíaca congênita, doença cardíaca isquêmica, aterosclerose coronariana, hipercolesterolemia familiar, fibrilação atrial, infarto e insuficiência cardíaca) "
    },
    {
      checked: false,
      selectName: "",
      selectValue: "",
      label: "Doença celíaca"
    },
    {
      checked: false,
      selectName: "",
      selectValue: "",
      label: "Doença de Crohn ileal"
    },
    {
      checked: false,
      selectName: "",
      selectValue: "",
      label: "Doença de Crohn colônica"
    },
    {
      checked: false,
      selectName: "",
      selectValue: "",
      label: "Doença de Crohn ileal e colônica"
    },
    {
      checked: false,
      selectName: "",
      selectValue: "",
      label: "Doença do refluxo gastroesofágico (DRGE)"
    },
    {
      checked: false,
      selectName: "",
      selectValue: "",
      label:
        'Doenças hepáticas (atresia biliar, hepatites crônicas, cirrose, esteatose hepática/"gordura no fígado" etc.)'
    },
    {
      checked: false,
      selectName: "",
      selectValue: "",
      label: "Doença inflamatória intestinal (DII)"
    },
    {
      checked: false,
      selectName: "",
      selectValue: "",
      label:
        "Doenças psicológicas (transtorno de ansiedade, depressão, anorexia nervosa, transtorno obsessivo compulsivo, transtorno bipolar)"
    },
    {
      checked: false,
      selectName: "",
      selectValue: "",
      label: "Doenças renais crônicas (DRC)"
    },
    {
      checked: false,
      selectName: "",
      selectValue: "",
      label: "Doenças respiratórias crônicas (bronquite, asma, DPO, rinite)"
    },
    {
      checked: false,
      selectName: "",
      selectValue: "",
      label: "Doenças virais (AIDS, hepatite B e C e tuberculose)"
    },
    {
      checked: false,
      selectName: "",
      selectValue: "",
      label: "Hérnia abdominal"
    },
    { checked: false, selectName: "", selectValue: "", label: "Hipertensão" },
    {
      checked: false,
      selectName: "",
      selectValue: "",
      label: "Intolerância à lactose"
    },
    {
      checked: false,
      selectName: "",
      selectValue: "",
      label: "Intolerância ao glúten"
    },
    { checked: false, selectName: "", selectValue: "", label: "Gastrite" },
    {
      checked: false,
      selectName: "",
      selectValue: "",
      label: "Mal de Parkinson"
    },
    { checked: false, selectName: "", selectValue: "", label: "Osteoporose" },
    { checked: false, selectName: "", selectValue: "", label: "Pancreatite" },
    {
      checked: false,
      selectName: "",
      selectValue: "",
      label: "Síndrome do intestino irritável (SII)"
    },
    {
      checked: false,
      selectName: "",
      selectValue: "",
      label: "Úlcera Gástrica (“Úlcera de Estômago)"
    }
  ],
  selectOptions: [
    { value: "Menos de 1 ano", label: "Menos de 1 ano" },
    { value: "Entre 1 e 2 anos", label: "Entre 1 e 2 anos" },
    { value: "Entre 2 e 3 anos", label: "Entre 2 e 3 anos" },
    { value: "Entre 3 e 4 anos", label: "Entre 3 e 4 anos" },
    { value: "5 anos ou mais", label: "5 anos ou mais" }
  ],
  name: "S02Q07"
};

export const S02Q08: SelectNormal = {
  label: "Você já teve um quadro de infecção por Clostridium difficile?",
  value: "",
  name: "S02Q08",
  options: [
    { value: "Sim", label: "Sim" },
    { value: "Não", label: "Não" }
  ]
};

export const S02Q09: SelectNormal = {
  label: "Você suspeita estar apresentando agora um quadro de infecção por Clostridium difficile?",
  value: "",
  name: "S02Q09",
  options: [
    { value: "Sim", label: "Sim" },
    { value: "Não", label: "Não" }
  ]
};

export const S02Q10: CheckBoxListWithSelect = {
  name: "S02Q10",
  label: "Você possui algum câncer? Se sim, informe quando a condição foi diagnosticada?",
  options: [
    { checked: false, selectName: "", selectValue: "", label: "Câncer anal" },
    {
      checked: false,
      selectName: "",
      selectValue: "",
      label: "Câncer de bexiga"
    },
    {
      checked: false,
      selectName: "",
      selectValue: "",
      label: "Câncer de boca"
    },
    {
      checked: false,
      selectName: "",
      selectValue: "",
      label: "Câncer colorretal"
    },
    {
      checked: false,
      selectName: "",
      selectValue: "",
      label: "Câncer do colo do útero"
    },
    {
      checked: false,
      selectName: "",
      selectValue: "",
      label: "Câncer do esôfago"
    },
    {
      checked: false,
      selectName: "",
      selectValue: "",
      label: "Câncer do estômago"
    },
    {
      checked: false,
      selectName: "",
      selectValue: "",
      label: "Câncer do fígado"
    },
    {
      checked: false,
      selectName: "",
      selectValue: "",
      label: "Câncer de laringe"
    },
    { checked: false, selectName: "", selectValue: "", label: "Leucemia" },
    {
      checked: false,
      selectName: "",
      selectValue: "",
      label: "Linfoma de Hodgkin"
    },
    {
      checked: false,
      selectName: "",
      selectValue: "",
      label: "Linfoma não-Hodgkin"
    },
    {
      checked: false,
      selectName: "",
      selectValue: "",
      label: "Câncer de mama"
    },
    {
      checked: false,
      selectName: "",
      selectValue: "",
      label: "Câncer do ovário"
    },
    {
      checked: false,
      selectName: "",
      selectValue: "",
      label: "Câncer de pâncreas"
    },
    {
      checked: false,
      selectName: "",
      selectValue: "",
      label: "Câncer de pele melanoma"
    },
    {
      checked: false,
      selectName: "",
      selectValue: "",
      label: "Câncer de pele não melanoma"
    },
    {
      checked: false,
      selectName: "",
      selectValue: "",
      label: "Câncer do pênis"
    },
    {
      checked: false,
      selectName: "",
      selectValue: "",
      label: "Câncer de próstata"
    },
    {
      checked: false,
      selectName: "",
      selectValue: "",
      label: "Câncer do pulmão"
    },
    {
      checked: false,
      selectName: "",
      selectValue: "",
      label: "Câncer do testículo"
    },
    {
      checked: false,
      selectName: "",
      selectValue: "",
      label: "Tumores de Ewing"
    },
    { checked: false, selectName: "", selectValue: "", label: "Outro" }
  ],
  selectOptions: [
    { value: "Menos de 1 ano", label: "Menos de 1 ano" },
    { value: "Entre 1 e 2 anos", label: "Entre 1 e 2 anos" },
    { value: "Entre 2 e 3 anos", label: "Entre 2 e 3 anos" },
    { value: "Entre 3 e 4 anos", label: "Entre 3 e 4 anos" },
    { value: "5 anos ou mais", label: "5 anos ou mais" }
  ]
};

export const S02Q11: SelectNormal = {
  label: "Você está fazendo algum tratamento?",
  value: "",
  name: "S02Q11",
  options: [
    { value: "Quimioterapia ", label: "Quimioterapia " },
    { value: "Radioterapia", label: "Radioterapia" },
    { value: "Imunoterapia", label: "Imunoterapia" },
    {
      value: "Não estou fazendo tratamento",
      label: "Não estou fazendo tratamento"
    }
  ]
};

export const S02Q12: SelectWithInput = {
  label: "Você tem alguma outra doença diagnosticada que não foi listada acima?",
  value: "",
  name: "S02Q12",
  inputValue: "",
  inputName: "",
  inputTriggered: false,
  inputLabel: "Qual?",
  options: [
    { value: "Sim", label: "Sim", triggersInput: true },
    { value: "Não", label: "Não", triggersInput: false }
  ]
};

export const S02Q13: SelectNormal = {
  label: "Qual das frases abaixo melhor descreve o seu histórico de tabagismo?",
  value: "",
  name: "S02Q13",
  options: [
    {
      value: "Nunca fumei (não fumo ou fumei menos de 100 cigarros durante toda a vida)",
      label: "Nunca fumei (não fumo ou fumei menos de 100 cigarros durante toda a vida)"
    },
    {
      value: "Sou ex-fumante (fumei pelo menos 100 cigarros durante a vida, mas parei de fumar)",
      label: "Sou ex-fumante (fumei pelo menos 100 cigarros durante a vida, mas parei de fumar)"
    },
    {
      value: "Sou fumante (já fumei 100 ou mais cigarros durante a vida e continuo fumando)",
      label: "Sou fumante (já fumei 100 ou mais cigarros durante a vida e continuo fumando)"
    }
  ]
};

// PARTE 3

export const S03Q01: SelectNormal = {
  label: "Com que frequência você passa por situações de inchaço abdominal? 😣",
  value: "",
  name: "S03Q01",
  options: [
    {
      value: "Nunca ou menos que 1 vez por mês",
      label: "Nunca ou menos que 1 vez por mês"
    },
    { value: "1 - 3 por mês", label: "1 - 3 por mês" },
    { value: "1 vez por semana", label: "1 vez por semana" },
    { value: "2 - 4 por semana", label: "2 - 4 por semana" },
    { value: "5 - 6 por semana", label: "5 - 6 por semana" },
    { value: "1 por dia", label: "1 por dia" },
    { value: "2 - 3 por dia", label: "2 - 3 por dia" },
    { value: "Mais de 3 vezes por dia", label: "Mais de 3 vezes por dia" }
  ]
};
export const S03Q02: SelectNormal = {
  label: "Com que frequência você passa por situações de diarreia?",
  value: "",
  name: "S03Q02",
  options: [
    {
      value: "Nunca ou menos que 1 vez por mês",
      label: "Nunca ou menos que 1 vez por mês"
    },
    { value: "1 - 3 por mês", label: "1 - 3 por mês" },
    { value: "1 vez por semana", label: "1 vez por semana" },
    { value: "2 - 4 por semana", label: "2 - 4 por semana" },
    { value: "5 - 6 por semana", label: "5 - 6 por semana" },
    { value: "1 por dia", label: "1 por dia" },
    { value: "2 - 3 por dia", label: "2 - 3 por dia" },
    { value: "Mais de 3 vezes por dia", label: "Mais de 3 vezes por dia" }
  ]
};
export const S03Q03: SelectNormal = {
  label: "Com que frequência você passa por situações de aumento de gás (flatulência)? ",
  value: "",
  name: "S03Q03",
  options: [
    {
      value: "Nunca ou menos que 1 vez por mês",
      label: "Nunca ou menos que 1 vez por mês"
    },
    { value: "1 - 3 por mês", label: "1 - 3 por mês" },
    { value: "1 vez por semana", label: "1 vez por semana" },
    { value: "2 - 4 por semana", label: "2 - 4 por semana" },
    { value: "5 - 6 por semana", label: "5 - 6 por semana" },
    { value: "1 por dia", label: "1 por dia" },
    { value: "2 - 3 por dia", label: "2 - 3 por dia" },
    { value: "Mais de 3 vezes por dia", label: "Mais de 3 vezes por dia" }
  ]
};
export const S03Q04: SelectNormal = {
  label: "Com que frequência você passa por situações de dor abdominal?",
  value: "",
  name: "S03Q04",
  options: [
    {
      value: "Nunca ou menos que 1 vez por mês",
      label: "Nunca ou menos que 1 vez por mês"
    },
    { value: "1 - 3 por mês", label: "1 - 3 por mês" },
    { value: "1 vez por semana", label: "1 vez por semana" },
    { value: "2 - 4 por semana", label: "2 - 4 por semana" },
    { value: "5 - 6 por semana", label: "5 - 6 por semana" },
    { value: "1 por dia", label: "1 por dia" },
    { value: "2 - 3 por dia", label: "2 - 3 por dia" },
    { value: "Mais de 3 vezes por dia", label: "Mais de 3 vezes por dia" }
  ]
};
export const S03Q05: SelectNormal = {
  label: "Com que frequência você passa por situações de náusea/vômito? 🤢",
  value: "",
  name: "S03Q05",
  options: [
    {
      value: "Nunca ou menos que 1 vez por mês",
      label: "Nunca ou menos que 1 vez por mês"
    },
    { value: "1 - 3 por mês", label: "1 - 3 por mês" },
    { value: "1 vez por semana", label: "1 vez por semana" },
    { value: "2 - 4 por semana", label: "2 - 4 por semana" },
    { value: "5 - 6 por semana", label: "5 - 6 por semana" },
    { value: "1 por dia", label: "1 por dia" },
    { value: "2 - 3 por dia", label: "2 - 3 por dia" },
    { value: "Mais de 3 vezes por dia", label: "Mais de 3 vezes por dia" }
  ]
};
export const S03Q06: SelectNormal = {
  label: "Qual a sua frequência de evacuação? 💩",
  value: "",
  name: "S03Q06",
  options: [
    { value: "Mais de duas vezes ao dia", label: "Mais de duas vezes ao dia" },
    { value: "Uma vez ao dia", label: "Uma vez ao dia" },
    { value: "Uma vez a cada 2 dias", label: "Uma vez a cada 2 dias" },
    {
      value: "Menos de uma vez a cada 2 dias",
      label: "Menos de uma vez a cada 2 dias"
    },
    {
      value: "Menos de uma vez a cada 5 dias",
      label: "Menos de uma vez a cada 5 dias"
    }
  ]
};
export const S03Q07: SelectNormal = {
  label: "Você notou sangue nas fezes nos últimos 3 meses?",
  value: "",
  name: "S03Q07",
  options: [
    { value: "Não", label: "Não" },
    { value: "Sim", label: "Sim" }
  ]
};

// PARTE 4

export const S04Q01: SelectNormal = {
  label: "Você usou algum medicamento no último mês?",
  value: "",
  name: "S04Q01",
  options: [
    { value: "Não", label: "Não" },
    { value: "Sim", label: "Sim" }
  ]
};

export const S04Q02: InputNormal = {
  label: "Nome do medicamento e dosagem",
  value: "",
  name: "S04Q02"
};

export const S04Q03: SelectNormal = {
  label: "Com que frequência você usa este medicamento?",
  value: "",
  name: "S04Q03",
  options: [
    { value: "Todo dia", label: "Todo dia" },
    { value: "1 vez por semana", label: "1 vez por semana" },
    { value: "2 vez por semana", label: "2 vez por semana" },
    { value: "3 vez por semana", label: "3 vez por semana" },
    { value: "4 vez por semana", label: "4 vez por semana" },
    { value: "5 vez por semana", label: "5 vez por semana" },
    { value: "6 vez por semana", label: "6 vez por semana" }
  ]
};

export const S04Q04: SelectNormal = {
  label: "Você usou mais algum medicamento no último mês?",
  value: "",
  name: "S04Q04",
  options: [
    { value: "Não", label: "Não" },
    { value: "Sim", label: "Sim" }
  ]
};

export const S04Q05: InputNormal = {
  label: "Nome do medicamento e dosagem",
  value: "",
  name: "S04Q05"
};

export const S04Q06: SelectNormal = {
  label: "Com que frequência você usa este medicamento?",
  value: "",
  name: "S04Q06",
  options: [
    { value: "Todo dia", label: "Todo dia" },
    { value: "1 vez por semana", label: "1 vez por semana" },
    { value: "2 vez por semana", label: "2 vez por semana" },
    { value: "3 vez por semana", label: "3 vez por semana" },
    { value: "4 vez por semana", label: "4 vez por semana" },
    { value: "5 vez por semana", label: "5 vez por semana" },
    { value: "6 vez por semana", label: "6 vez por semana" }
  ]
};

export const S04Q07a: SelectNormal = {
  label: "Você menstrua?",
  value: "",
  name: "S04Q07a",
  options: [
    { value: "Não", label: "Não" },
    { value: "Sim", label: "Sim" }
  ]
};

export const S04Q07: SelectWithInput = {
  label: "Você está esperando um bebê?",
  value: "",
  name: "S04Q07",
  inputValue: "",
  inputName: "",
  inputTriggered: false,
  inputLabel: "Quantas semanas?",
  options: [
    { value: "Sim", label: "Sim", triggersInput: true },
    { value: "Não", label: "Não", triggersInput: false }
  ]
};

export const S04Q08: SelectNormal = {
  label: "O seu período/ciclo menstrual é regular?",
  value: "",
  name: "S04Q08",
  options: [
    { value: "Sim", label: "Sim" },
    { value: "Não", label: "Não" }
  ]
};

export const S04Q09: SelectNormal = {
  label: "Eu nasci de",
  value: "",
  name: "S04Q09",
  options: [
    { value: "Cesariana", label: "Cesariana" },
    { value: "Parto Normal", label: "Parto Normal" },
    { value: "Não sei", label: "Não sei" }
  ]
};

export const S04Q10: SelectNormal = {
  label: "Eu nasci em",
  value: "",
  name: "S04Q10",
  options: [
    { value: "Hospital/Clínica", label: "Hospital/Clínica" },
    { value: "Casa", label: "Casa" },
    { value: "Não sei", label: "Não sei" }
  ]
};

export const S04Q11: SelectNormal = {
  label: "Você foi amamentado por quanto tempo durante a infância?  👶🏽",
  value: "",
  name: "S04Q11",
  options: [
    { value: "Até 6 meses", label: "Até 6 meses" },
    { value: "Até 1 ano", label: "Até 1 ano" },
    { value: "Até 2 anos", label: "Até 2 anos" },
    { value: "Mais de 2 anos", label: "Mais de 2 anos" },
    { value: "Não fui amamentado", label: "Não fui amamentado" },
    { value: "Não sei", label: "Não sei" }
  ]
};

// PARTE 5

export const S05Q01: SelectNormal = {
  label:
    "Em quantos dias da última semana você caminhou por pelo menos 10 minutos contínuos em casa ou no trabalho, como forma de transporte para ir de um lugar para outro, por lazer, por prazer ou como forma de exercício? 🚶‍♂️",
  value: "",
  name: "S05Q01",
  options: [
    { value: "1 dia", label: "1 dia" },
    { value: "2 dias", label: "2 dias" },
    { value: "3 dias", label: "3 dias" },
    { value: "4 dias", label: "4 dias" },
    { value: "5 dias", label: "5 dias" },
    { value: "6 dias", label: "6 dias" },
    { value: "7 dias", label: "7 dias" },
    { value: "Nenhum dia", label: "Nenhum dia" }
  ]
};

// S05Q02
export const S05Q02a: InputNormal = {
  label: "Horas",
  value: "",
  name: "S05Q02a"
};

export const S05Q02b: InputNormal = {
  label: "Minutos",
  value: "",
  name: "S05Q02b"
};

export const S05Q03: SelectNormal = {
  label:
    "Em quantos dias da última semana você realizou atividades moderadas por pelo menos 10 minutos contínuos? 😮‍💨",
  value: "",
  name: "S05Q03",
  options: [
    { value: "1 dia", label: "1 dia" },
    { value: "2 dias", label: "2 dias" },
    { value: "3 dias", label: "3 dias" },
    { value: "4 dias", label: "4 dias" },
    { value: "5 dias", label: "5 dias" },
    { value: "6 dias", label: "6 dias" },
    { value: "7 dias", label: "7 dias" },
    { value: "Nenhum dia", label: "Nenhum dia" }
  ]
};

export const S05Q04a: InputNormal = {
  label: "Horas",
  value: "",
  name: "S05Q04a"
};

export const S05Q04b: InputNormal = {
  label: "Minutos",
  value: "",
  name: "S05Q04b"
};

export const S05Q05: SelectNormal = {
  label: "Em quantos dias da última semana você realizou atividades vigorosas por pelo menos 10 minutos contínuos? 🥵",
  value: "",
  name: "S05Q05",
  options: [
    { value: "1 dia", label: "1 dia" },
    { value: "2 dias", label: "2 dias" },
    { value: "3 dias", label: "3 dias" },
    { value: "4 dias", label: "4 dias" },
    { value: "5 dias", label: "5 dias" },
    { value: "6 dias", label: "6 dias" },
    { value: "7 dias", label: "7 dias" },
    { value: "Nenhum dia", label: "Nenhum dia" }
  ]
};

export const S05Q06a: InputNormal = {
  label: "Horas",
  value: "",
  name: "S05Q06a"
};

export const S05Q06b: InputNormal = {
  label: "Minutos",
  value: "",
  name: "S05Q06b"
};

export const S05Q07a: InputNormal = {
  label: "Horas",
  value: "",
  name: "S05Q07a"
};

export const S05Q07b: InputNormal = {
  label: "Minutos",
  value: "",
  name: "S05Q07b"
};

export const S05Q08a: InputNormal = {
  label: "Horas",
  value: "",
  name: "S05Q08a"
};

export const S05Q08b: InputNormal = {
  label: "Minutos",
  value: "",
  name: "S05Q08b"
};

// PARTE 6

export const S06Q01: Table = {
  name: "S06Q01",
  rows: [
    {
      meal: "Abacate",
      average_portion: "2 colheres de sopa cheias (90g)",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Achocolatado em pó (adicionado ao leite)",
      average_portion: "2 colheres de sopa (25g)",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Açúcar/mel/geléia",
      average_portion: "1/2 colher de sopa (6g)",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Alface",
      average_portion: "3 folhas médias (30g)",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Arroz polido/integral",
      average_portion: "2 escumadeiras médias (120g)",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Banana",
      average_portion: "1 unidade média (86g)",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Batata cozida/purê/mandioca cozida/jicama (batata mexicana)/inhame",
      average_portion: "1 escumadeira cheia (90g)",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Batata frita/chips",
      average_portion: "2 colheres de servir cheias (100g)",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Bauru/enroladinho de queijo e presunto/esfiha/torta salgada",
      average_portion: "2 unidades ou 2 pedaços médios (140g)",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Biscoito recheado/waffer",
      average_portion: "3 unidades (42g)",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Biscoito sem recheio (doce/salgado)",
      average_portion: "4 unidades (24g)",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Bolo (simples/recheado)",
      average_portion: "1 fatia média (60g)",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Café ou chá (com açúcar)",
      average_portion: "2 xícaras de café (90ml)",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Café ou chá (sem açúcar)",
      average_portion: "2 xícaras de café (90ml)",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Caqui",
      average_portion: "1 unidade (113g)",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Carne bovina (bife/cubos/moída/miúdos/vísceras)",
      average_portion: "1 bife médio ou 2 pedaços (100g)",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Carne suína (lombo/bisteca)",
      average_portion: "1 fatia média (100g)",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Carnes salgadas e curadas",
      average_portion: "2 pedaços pequenos (40g)",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Cenoura",
      average_portion: "1 colher de sopa (25g)",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Cerveja",
      average_portion: "2 latas (700ml)",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Chocolate/brigadeiro/bombom",
      average_portion: "1 barra pequena ou 2 colheres de sopa (25g)",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Embutidos (presunto/mortadela/salame/salsicha)",
      average_portion: "2 fatias médias (30g) ou 1 unidade (40g)",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Farinha de mandioca ou milho/farofa/cuscuz/aveia/tapioca",
      average_portion: "3 colheres de sopa (40g)",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Feijão (carioca/roxo/preto/verde)",
      average_portion: "1 concha média (86g)",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Feijoada/feijão tropeiro",
      average_portion: "1 concha média (210g)",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Frango/outras aves",
      average_portion: "1 pedaço ou 1 filé pequeno (60g)",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Goiaba",
      average_portion: "1 unidade grande (225g)",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Hambúrguer/nuggets/almôndegas",
      average_portion: "1 unidade média (60g)",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Hortaliças crucíferas (brócolis/couve-flor/repolho)",
      average_portion: "1 ramo ou 2 colheres de sopa (30g)",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Iogurtes",
      average_portion: "1 unidade pequena (140g",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Laranja/acerola/mexerica/limão",
      average_portion: "1 unidade média",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Leite (todos os tipos)",
      average_portion: "1/2 copo de requeijão (125ml)",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Lentilha, ervilha seca, grão de bico, soja",
      average_portion: "1 colher de servir (45g)",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Linguiça",
      average_portion: "1 gomo médio (60g)",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Maçã/pêra/abacaxi/outras fruta",
      average_portion: "1 unidade média (110g)",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Macarrão (com molho com carne/lasanha/nhoque)",
      average_portion: "1 escumadeira cheia ou 1 pedaço peq. (110g)",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Macarrão (com molho sem carne)",
      average_portion: "1 prato raso (200g)",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Maionese/molho para salada/patê",
      average_portion: "1 colher de sopa rasa (17g)",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Mamão",
      average_portion: "1 fatia ou 1/2 unidade média (160g)",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Manteiga/margarina",
      average_portion: "3 pontas de faca (15g)",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Melancia/melão",
      average_portion: "1/2 fatia média (100g)",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Molho de soja (shoyu)",
      average_portion: "2 colheres de chá (4g)",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Óleo para tempero de salada",
      average_portion: "1 fio (5ml)",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Outras verduras (espinafre/acelga/rúcula/agrião/escarola)",
      average_portion: "1 prato de sobremesa (38g)",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Outros legumes (berinjela/abobrinha/chuchu/beterraba/pepino)",
      average_portion: "1 colher de sopa cheia (30g)",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Ovo (frito/cozido)",
      average_portion: "1 unidade (50g)",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Pão (francês/outros tipos)",
      average_portion: "1 unidade ou 2 fatias (50g)",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Peixe/alichela/anchova/frutos do mar",
      average_portion: "1 filé pequeno ou 1 posta pequena (100g",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Pizza/panqueca",
      average_portion: "2 fatias pequenas ou 2 unidades (180g)",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Polenta",
      average_portion: "1 pedaço pequeno (100g)",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Queijo branco (minas/ricota/coalho)",
      average_portion: "1 fatia média (30g)",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Queijos amarelos (mussarela/prato/parmesão/provolone/cheddar)",
      average_portion: "1 1/2 fatias grossas (30g)",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Refrigerante/garapa",
      average_portion: "1 copo de requeijão ou 3/4 da lata (250ml",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Sal para tempero de salada",
      average_portion: "1 pitada (0,35g)",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Salada de maionese com legumes",
      average_portion: "3 colheres de sopa (90g)",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Salgados (massa para pastel/pastel/coxinha/rissólis/tempura/bolinho",
      average_portion: "1 unidade grande (80g)",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Sanduíche (cachorro-quente/hambúrguer)",
      average_portion: "2 unidades simples (220g)",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Sobremesas/doces/tortas doces/pudins",
      average_portion: "1 pedaço ou 1 fatia média (60g)",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Sopas (legumes/canja/creme/etc.)",
      average_portion: "1 concha média (150g)",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Suco industrializado",
      average_portion: "1 copo de requeijão (240ml com 10.8g de pó)",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Suco natural",
      average_portion: "1/2 copo americano (80ml)",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Tomate/molho de tomate",
      average_portion: "3 fatias médias (40g)",
      times: "",
      time_unit: "",
      portion: ""
    },
    {
      meal: "Uva",
      average_portion: "1 cacho médio (100 g)",
      times: "",
      time_unit: "",
      portion: ""
    }
  ]
};

export const S06Q02: SelectNormal = {
  label:
    "Você mudou seus hábitos alimentares recentemente ou está fazendo dieta para emagrecer ou por qualquer outro motivo?",
  value: "",
  name: "S06Q02",
  options: [
    { value: "Não ", label: "Não " },
    { value: "Sim, para redução de sal", label: "Sim, para redução de sal" },
    { value: "Sim, para perda de peso", label: "Sim, para perda de peso" },
    {
      value: "Sim, para redução de colesterol ",
      label: "Sim, para redução de colesterol "
    },
    {
      value: "Sim, por orientação médica ",
      label: "Sim, por orientação médica "
    },
    { value: "Sim, para ganho de peso ", label: "Sim, para ganho de peso " },
    {
      value: "Sim, para dieta vegetariana ",
      label: "Sim, para dieta vegetariana "
    },
    { value: "Sim, por outro motivo", label: "Sim, por outro motivo" }
  ]
};

export const S06Q03: SelectNormal = {
  label: "Qual alternativa melhor descreve sua dieta?",
  value: "",
  name: "S06Q03",
  options: [
    {
      value: "Como alimentos de origem vegetal e animal",
      label: "Como alimentos de origem vegetal e animal"
    },
    {
      value: "Como alimentos de origem vegetal e animal, mas não como carne vermelha",
      label: "Como alimentos de origem vegetal e animal, mas não como carne vermelha"
    },
    {
      value: "Sou “vegetariano”, mas como frutos do mar",
      label: "Sou “vegetariano”, mas como frutos do mar"
    },
    { value: "Vegetariano", label: "Vegetariano" },
    { value: "Vegano", label: "Vegano" },
    { value: "Não fornecido", label: "Não fornecido" }
  ]
};

export const S06Q04: SelectNormal = {
  label: "Quantas vezes você bebe pelo menos um litro de água (5 copos) 💧",
  value: "",
  name: "S06Q04",
  options: [
    { value: "Nunca", label: "Nunca" },
    {
      value: "Raramente (menos de uma vez por semana)",
      label: "Raramente (menos de uma vez por semana)"
    },
    {
      value: "Ocasionalmente (1-2 vezes por semana)",
      label: "Ocasionalmente (1-2 vezes por semana)"
    },
    {
      value: "Regularmente (3-5 vezes por semana)",
      label: "Regularmente (3-5 vezes por semana)"
    },
    { value: "Diariamente", label: "Diariamente" }
  ]
};

export const S06Q05: SelectNormal = {
  label: "Com que frequência você consome bebidas que contêm álcool? 🍸",
  value: "",
  name: "S06Q05",
  options: [
    { value: "Nunca", label: "Nunca" },
    { value: "Uma vez por mês ou menos", label: "Uma vez por mês ou menos" },
    {
      value: "Duas a quatro vezes ao mês",
      label: "Duas a quatro vezes ao mês"
    },
    {
      value: "Duas a três vezes por semana",
      label: "Duas a três vezes por semana"
    },
    {
      value: "Quatro ou mais vezes por semana",
      label: "Quatro ou mais vezes por semana"
    }
  ]
};

export const S06Q06: SelectNormal = {
  label: "Quantas bebidas com álcool você consome em um dia normal?",
  value: "",
  name: "S06Q06",
  options: [
    { value: "1 ou 2", label: "1 ou 2" },
    { value: "3 ou 4", label: "3 ou 4" },
    { value: "5 ou 6", label: "5 ou 6" },
    { value: "7 a 9", label: "7 a 9" },
    { value: "10 ou mais", label: "10 ou mais" }
  ]
};

export const S06Q07: SelectNormal = {
  label: "Com que frequência você consome cinco ou mais bebidas com álcool em uma única ocasião?",
  value: "",
  name: "S06Q07",
  options: [
    { value: "Nunca", label: "Nunca" },
    {
      value: "Menos do que uma vez por mês",
      label: "Menos do que uma vez por mês"
    },
    { value: "Uma vez por mês", label: "Uma vez por mês" },
    { value: "Uma vez por semana", label: "Uma vez por semana" },
    {
      value: "Diariamente ou quase diariamente",
      label: "Diariamente ou quase diariamente"
    }
  ]
};

export const S06Q08: SelectWithInput = {
  label: "Você tomou probióticos na semana anterior ao teste?",
  value: "",
  name: "S06Q08",
  inputValue: "",
  inputName: "",
  inputTriggered: false,
  inputLabel: "Especifique:",
  options: [
    { value: "Não", label: "Não", triggersInput: false },
    { value: "Sim, iogurte", label: "Sim, iogurte", triggersInput: false },
    { value: "Sim, kombucha", label: "Sim, kombucha", triggersInput: false },
    { value: "Sim, kefir", label: "Sim, kefir", triggersInput: false },
    {
      value: "Sim, probióticos vendidos na farmácia",
      label: "Sim, probióticos vendidos na farmácia",
      triggersInput: false
    },
    { value: "Sim, outros", label: "Sim, outros", triggersInput: true }
  ]
};

export const S06Q09: SelectNormal = {
  label: "Você ingeriu bebidas alcoólicas nas últimas 24 horas antes do teste?",
  value: "",
  name: "S06Q09",
  options: [
    { value: "Não", label: "Não" },
    { value: "Sim, vinho", label: "Sim, vinho" },
    { value: "Sim, cerveja", label: "Sim, cerveja" },
    {
      value: "Sim, bebidas destiladas (whisky, vodka, aguardente, etc)",
      label: "Sim, bebidas destiladas (whisky, vodka, aguardente, etc)"
    },
    { value: "Sim, outros", label: "Sim, outros" }
  ]
};

export const S06Q10: TextAreaNormal = {
  label: "Você pode fornecer informações sobre o que você comeu nas últimas 24 horas antes do teste?",
  value: "",
  name: "S06Q10"
};

// PARTE 7

export const S07Q01: SelectNormal = {
  label: "Como o seu 💩 se parece geralmente?",
  value: "",
  name: "S07Q01",
  options: [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" }
  ]
};

export const S07Q02: SelectNormal = {
  label: "Como o seu 💩 se parecia no dia da coleta do seu microbioma?",
  value: "",
  name: "S07Q02",
  options: [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" }
  ]
};
