/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FormEvent, useState } from "react";

import { useHistory } from "react-router-dom";
import { useParams } from "react-router";

import Page from "components/Page";
import LandingLayout from "components/LandingLayout";
import Button1 from "components/Button1";
import { useAuth } from "contexts/auth";
import { toast } from "react-toastify";
import Loading from "components/Loading";
import { Container, Form, Header, MyInput, Register, Spacing, Subtitle, Title } from "./styles";
import api from "../../services/api";

export const Login = () => {
  const params: { type: string; kit: string } = useParams();

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { login } = useAuth();
  const history = useHistory();

  async function handleLogin(e: FormEvent) {
    e.preventDefault();

    try {
      setLoading(true);
      await login({ email: email.toLowerCase(), password, name: "" });
      setLoading(false);
    } catch (e) {
      toast.error("Erro ao tentar fazer login!");
    }
  }

  async function handleGoToRegister() {
    history.push(params.kit && params.type ? `/register/${params.type}/${params.kit}` : `/register/`);
  }

  async function handleForgotPassword() {
    if (email === "") {
      toast.error("Por favor, informe seu e-mail!");
    } else {
      setLoading(true);
      api
        .post("/forgot_password", { email })
        .then((res) => {
          if (res.status === 200) {
            setLoading(false);
            toast.success(`E-mail enviado para ${email}`);
          }

          if (res.status === 404) {
            setLoading(false);
            toast.error(`O e-mail (${email}) não está cadastrado!`);
          }
        })
        .catch((err) => {
          toast.error("Erro! Contate o suporte!");
          setLoading(false);
        });
    }
  }

  return (
    <Page>
      <Loading active={loading} />
      <LandingLayout>
        <Container>
          <Header>
            <Title>
              <span>Olá,</span>
            </Title>
            <Title>bem vindo!</Title>
            <Subtitle>
              Registre aqui seu kit agrega microbioma ou acesse seus kits já registrados e acompanhe seus resultados.
            </Subtitle>
          </Header>
          <Spacing />

          <Form onSubmit={handleLogin}>
            <MyInput
              name="email"
              type="email"
              label="E-mail"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <MyInput
              name="password"
              type="password"
              label="Senha"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <Register>
              <a onClick={handleForgotPassword}>Esqueceu a senha?</a>
            </Register>

            <Button1 type="submit">
              <span>login</span>
            </Button1>
          </Form>

          <Register>
            Não tem um kit registrado? <a onClick={handleGoToRegister}>Registre agora</a>
          </Register>
        </Container>
      </LandingLayout>
    </Page>
  );
};

export default Login;
