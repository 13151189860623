import React, { useState, useEffect, useCallback } from "react";

import { FiUser } from "react-icons/fi";
import api from "services/api";
import Loading from "components/Loading";
import Select from "components/Select";
import { useAuth } from "contexts/auth";
import { IUser } from "types";
import loadash from "lodash";
import Table2 from "components/Table2";
import { DeleteOutlined, EditOutlined, SaveOutlined } from "@material-ui/icons";
import { toast } from "react-toastify";
import { ListRow, Page } from "./styles";

export default function Users() {
  // eslint-disable-next-line
  const { register } = useAuth();

  const [users, setUsers] = useState<IUser[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<IUser[]>([]);

  const [loading, setLoading] = useState(true);
  const [selectedUser, selectUser] = useState<IUser>();

  const roles = [
    { value: "1", label: "Admin" },
    { value: "2", label: "User" },
    { value: "3", label: "Disabled" }
  ];

  const getUsers = () => {
    api.get(`users`).then((res) => {
      const users = res.data;
      setUsers(users);
      setFilteredUsers(users);
      setLoading(false);
    });
  };

  useEffect(() => {
    getUsers();
  }, []);

  const debouncedSearch = useCallback(
    loadash.debounce((nextValue, users) => {
      if (nextValue) {
        const newUsers = users.filter((user) => user.email.includes(nextValue) || user.name.includes(nextValue));
        setFilteredUsers(newUsers);
      } else {
        setFilteredUsers(users);
      }
    }, 1000),
    []
  );

  const handleChangeSearch = (v) => {
    debouncedSearch(v, users);
  };

  function getRoleName(id: number) {
    switch (id) {
      case 1:
        return "Admin";
      case 2:
        return "User";
      case 3:
        return "Disabled";
      default:
        return "";
    }
  }

  const saveUser = () => {
    if (!selectedUser) return;

    setLoading(true);
    api
      .put(`users/${selectedUser.id}`, {
        role_id: selectedUser.role_id
      })
      .then((res) => {
        selectUser(undefined);
        getUsers();
        setLoading(false);
      });
    // .catch((err) => console.log(err));
  };

  const deleteUser = (user: IUser) => {
    setLoading(true);
    api.delete(`users/${user.id}`).then((res) => {
      if (res.status === 400) {
        if (res.data.error) {
          toast.error(res.data.error);
        }
      }
      getUsers();
      setLoading(false);
    });
  };

  return (
    <Page>
      <Loading active={loading} />
      <Table2 handleChangeSearch={handleChangeSearch}>
        {filteredUsers.map((user: IUser) => {
          return (
            <ListRow key={user.id}>
              <div>
                <FiUser />
              </div>

              {selectedUser && user.id === selectedUser.id ? (
                <div className="role">
                  <Select
                    name="role-id"
                    options={roles}
                    value={selectedUser.role_id}
                    onChange={(e) => selectUser({ ...selectedUser, role_id: Number(e.target.value) })}
                  />
                </div>
              ) : (
                <div className="role">{getRoleName(user.role_id || 0)}</div>
              )}

              <div className="name">{user.name}</div>
              <div className="email">{user.email}</div>
              <div className="buttons">
                {selectedUser && user.id === selectedUser.id ? (
                  <SaveOutlined onClick={saveUser} />
                ) : (
                  <EditOutlined onClick={() => selectUser(user)} />
                )}
                <DeleteOutlined onClick={() => deleteUser(user)} />
              </div>
            </ListRow>
          );
        })}
      </Table2>

      {/* <Mobile>
        sdsd
      </Mobile> */}
    </Page>
  );
}
