import { AxiosResponse } from "axios";
import React, { createContext, useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { IUser } from "types";

import api from "../services/api";

interface AuthContextData {
  signed: boolean;
  triggerNewKit: boolean;
  user: User | undefined;
  changeFormId(formId: string | null): void;
  getFormId(): string | null;
  login(user: User): Promise<boolean>;
  resetPassword(user: User, token: string): Promise<boolean>;
  update(user: User): Promise<boolean>;
  createUser(user: User): Promise<boolean>;
  register(user: User, code: string): Promise<AxiosResponse | void>;
  logout(): void;
  isAdmin(): boolean;
  triggerNewKitFunction(): void;
  getUserId(): string | undefined;
}

interface User extends IUser {
  actual_password?: string;
  new_password?: string;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<User>();
  const [formId, setFormId] = useState<string | null>(null);
  const [, setLoading] = useState(true);
  const [triggerNewKit, setTriggerNewKit] = useState(true);

  useEffect(() => {
    async function loadStoragedData() {
      const storagedUser = await localStorage.getItem("@RAuth:AGREGA:user");
      const storagedToken = await localStorage.getItem("@RAuth:AGREGA:token");

      if (storagedUser && storagedToken) {
        api.defaults.headers.Authorization = `Bearer ${storagedToken}`;
        setUser(JSON.parse(storagedUser));
        setLoading(false);
      }
    }

    loadStoragedData();
  }, []);

  function getUserId() {
    return user?.id;
  }

  async function login(user: User) {
    const res = await api.post("/login", {
      email: user.email,
      password: user.password
    });

    if (res.status === 200) {
      api.defaults.headers.Authorization = `Bearer ${res.data.token}`;

      localStorage.setItem("@RAuth:AGREGA:user", JSON.stringify(res.data.user));
      localStorage.setItem("@RAuth:AGREGA:token", res.data.token);

      setUser(res.data.user);
      toast.success("Login efetuado com sucesso!");
      return true;
    }
    toast.error("Falha no Login!");
    return false;
  }

  async function createUser(user: User) {
    const res = await api.post("/register", {
      ...user
    });

    if (res.status === 201) {
      toast.success("Usuário criado com sucesso!");
      return true;
    }

    toast.error("Erro ao criar usuário...");
    return false;
  }

  async function resetPassword(user: User, token: string) {
    const res = await api.post("/change_password", {
      email: user.email,
      password: user.password,
      token
    });

    if (res.status === 200) {
      api.defaults.headers.Authorization = `Bearer ${res.data.token}`;

      localStorage.setItem("@RAuth:AGREGA:user", JSON.stringify(res.data.user));
      localStorage.setItem("@RAuth:AGREGA:token", res.data.token);

      setUser(res.data.user);
      toast.success("Senha modificada com sucesso!");
      return true;
    }
    toast.error("Falha no Login!");
    return false;
  }

  // eslint-disable-next-line consistent-return
  async function register(user: User, code: string) {
    const registerResponse = await api.post("/register", {
      ...user,
      email: user.email,
      password: user.password,
      name: user.name,
      phone: user.phone
    });

    if (registerResponse.status === 201) {
      toast.success("Registro efetuado com sucesso!");
      const kitRegisterResponse = await api.post(`user/kits/${code}`, { user_id: registerResponse.data.user.id });

      if (kitRegisterResponse) {
        api.defaults.headers.Authorization = `Bearer ${registerResponse.data.token}`;

        localStorage.setItem("@RAuth:AGREGA:user", JSON.stringify(registerResponse.data.user));
        localStorage.setItem("@RAuth:AGREGA:token", registerResponse.data.token);

        setUser(registerResponse.data.user);

        return kitRegisterResponse;
      }
      toast.error("Erro ao registrar Kit!");
    } else {
      toast.error("Erro ao registrar usuário!");
    }
  }

  const triggerNewKitFunction = () => {
    setTriggerNewKit(!triggerNewKit);
  };

  function isAdmin() {
    return user?.role_id === 1;
  }

  async function update(newUser: User) {
    if (newUser.actual_password) {
      const check = await api.post("/profile/check-password", {
        password: newUser.actual_password
      });

      if (check.status !== 200) {
        toast.error("Senha atual incorreta");
        return false;
      }
    }

    if (newUser.actual_password && !newUser.new_password) {
      toast.error("Você precisa informar a nova senha...");
      return false;
    }

    const res = await api.put("/profile", {
      ...newUser,
      password: newUser.actual_password ? newUser.new_password : null,
      name: newUser.name,
      phone: newUser.phone
    });

    if (res.status === 200) {
      localStorage.setItem(
        "@RAuth:AGREGA:user",
        JSON.stringify({
          ...newUser,
          email: user?.email,
          password: user?.password
        })
      );
      setUser({ ...newUser, email: user?.email || "", password: user?.password || "" });
      toast.success("Perfil atualizado com sucesso!");
      return true;
    }
    toast.error("Falha ao atualizar perfil!");
    return false;
  }

  async function logout() {
    localStorage.clear();
    api.defaults.headers.Authorization = "";
    setUser(undefined);
  }

  function getFormId() {
    return formId;
  }

  function changeFormId(formId: string | null) {
    if (formId) localStorage.setItem("@RAuth:AGREGA:form", formId);
    setFormId(formId);
  }

  return (
    <AuthContext.Provider
      value={{
        signed: !!user,
        triggerNewKit,
        user,
        getFormId,
        changeFormId,
        login,
        logout,
        register,
        update,
        createUser,
        isAdmin,
        getUserId,
        resetPassword,
        triggerNewKitFunction
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider.");
  }

  return context;
}

export { AuthProvider, useAuth };

