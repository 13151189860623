import React from "react";

// import "./styles.css";

import { Container, Label, Options, Option, MyCheckbox } from "./styles";

interface CheckboxListProps {
  question: Question;
  setFunction(question): void;
}

interface Question {
  label: string;
  options: CheckBoxOption[];
}

interface CheckBoxOption {
  value: string;
  label: string;
  checked: boolean;
}

const CheckboxList: React.FC<CheckboxListProps> = ({ question, setFunction }) => {
  const { label, options } = question;

  return (
    <Container>
      <Label>{label}</Label>
      <Options>
        {options.map((opt) => (
          <Option key={opt.value}>
            <div>
              <MyCheckbox
                checked={opt.checked}
                onChange={(e, checked) =>
                  setFunction({
                    ...question,
                    options: options.map((option) => (option.value === opt.value ? { ...option, checked } : option))
                  })
                }
                color="default"
                name={opt.value}
              />
              <div>{opt.label}</div>
            </div>
          </Option>
        ))}
      </Options>
    </Container>
  );
};

export default CheckboxList;
