export interface RadioButtonList {
  name: string;
  label: string;
  options: RadioButton[];
}

export interface RadioButton {
  value: string;
  label: string;
  checked: boolean;
}

export interface CheckboxList {
  name: string;
  label: string;
  options: CheckBoxOption[];
}

export interface CheckBoxOption {
  value: string;
  label: string;
  checked: boolean;
}

export interface SelectWithInput {
  name: string;
  value: string;
  label: string;
  options: SelectWithInputOption[];
  inputValue: string;
  inputName: string;
  inputLabel: string;
  inputTriggered: boolean;
}

export interface SelectWithInputOption {
  value: string;
  label: string;
  triggersInput: boolean;
}

export interface SelectWithSelectOption {
  value: string;
  label: string;
  triggersSelect: boolean;
}

export interface SelectNormal {
  name: string;
  value: string;
  label: string;
  options: SelectNormalOption[];
}

export interface SelectNormalOption {
  value: string;
  label: string;
}

export interface InputNormal {
  value: string;
  label: string;
  name: string;
}

export interface AutoComplete {
  value: string;
  inputValue: string;
  label: string;
  id: string;
  name: string;
}

export const S01Q01: AutoComplete = {
  label: "Estado",
  value: "",
  inputValue: "",
  id: "uf",
  name: "S01Q01"
};

export const S01Q02: AutoComplete = {
  label: "Cidade",
  inputValue: "",
  value: "",
  id: "cidade",
  name: "S01Q02"
};

export const S01Q03: InputNormal = {
  label: "Data da coleta:",
  value: "",
  name: "S01Q03"
};

export const S01Q04: InputNormal = {
  label: "Data estimada para o plantio de soja:",
  value: "",
  name: "S01Q04"
};

export const S01Q05: RadioButtonList = {
  label: "Como a sua área é conduzida?",
  name: "S01Q05",
  options: [
    {
      checked: false,
      value:
        "Rotação ou alternância de espécies vegetais na mesma área e na mesma estação. Por exemplo, aveia/soja e depois canola/milho",
      label:
        "Rotação ou alternância de espécies vegetais na mesma área e na mesma estação. Por exemplo, aveia/soja e depois canola/milho"
    },
    {
      checked: false,
      value:
        "Sucessão ou sequência repetitiva de culturas cultivadas na mesma área e em estação estival diferente. Por exemplo, aveia no inverno e soja no verão.",
      label:
        "Sucessão ou sequência repetitiva de culturas cultivadas na mesma área e em estação estival diferente. Por exemplo, aveia no inverno e soja no verão."
    },
    {
      checked: false,
      value:
        "Pousio. Por exemplo, apenas cultivo de soja na safra de verão, sem a realização de plantio de soja ou outras culturas no inverno",
      label:
        "Pousio. Por exemplo, apenas cultivo de soja na safra de verão, sem a realização de plantio de soja ou outras culturas no inverno"
    },
    {
      checked: false,
      value: "Não sei",
      label: "Não sei"
    }
  ]
};

export const S01Q06: SelectNormal = {
  label: "Qual o seu sistema de manejo?",
  value: "",
  name: "S01Q06",
  options: [
    { value: "Plantio direto", label: "Plantio direto" },
    { value: "Preparo convencional", label: "Preparo convencional" },
    { value: "Outro", label: "Outro" },
    { value: "Não sei", label: "Não sei" }
  ]
};
// PARTE 2

export const S02Q01: SelectNormal = {
  label: "Como foi o crescimento das plantas na sua última lavoura de soja?",
  value: "",
  name: "S02Q01",
  options: [
    { value: "Uniforme ", label: "Uniforme " },
    { value: "Desigual", label: "Desigual" },
    { value: "Não sei", label: "Não sei" }
  ]
};

export const S02Q02: SelectWithInput = {
  label: "Você poderia fornecer o número exato da densidade de plantio na sua última lavoura de soja?",
  value: "",
  name: "S02Q02",
  inputValue: "",
  inputName: "",
  inputTriggered: false,
  inputLabel: "Plantas por hectare?",
  options: [
    { value: "Sim", label: "Sim", triggersInput: true },
    { value: "Não, mas a densidade era alta", label: "Não, mas a densidade era alta", triggersInput: false },
    { value: "Não, mas a densidade era média", label: "Não, mas a densidade era média", triggersInput: false },
    { value: "Não, mas a densidade era baixa", label: "Não, mas a densidade era baixa", triggersInput: false },
    { value: "Não sei", label: "Não sei", triggersInput: false }
  ]
};

export const S02Q03: CheckboxList = {
  label:
    "Houve algum fator não relacionado à fertilidade ou saúde do solo que possa ter comprometido a produtividade na sua última lavoura de soja? ",
  name: "S02Q03",
  options: [
    { value: "Plantas invasoras", label: "Plantas invasoras", checked: false },
    { value: "Estiagem", label: "Estiagem", checked: false },
    { value: "Doenças", label: "Doenças", checked: false },
    { value: "Nenhum fator", label: "Nenhum fator", checked: false },
    { value: "Não sei", label: "Não sei", checked: false }
  ]
};

export const S02Q04: SelectWithInput = {
  label: "Você poderia fornecer o rendimento aproximado da sua última lavoura de soja?",
  value: "",
  name: "S02Q04",
  inputValue: "",
  inputName: "",
  inputTriggered: false,
  inputLabel: "Sacas por hectare?",
  options: [
    { value: "Sim", label: "Sim", triggersInput: true },
    { value: "Não", label: "Não", triggersInput: false }
  ]
};

export const S02Q05: CheckboxList = {
  label: "Você fez a utilização de agroquímicos na sua última lavoura de soja?",
  name: "S02Q05",
  options: [
    { value: "Fungicidas", label: "Fungicidas", checked: false },
    { value: "Inseticidas", label: "Inseticidas", checked: false },
    { value: "Herbicidas", label: "Herbicidas", checked: false },
    { value: "Outros", label: "Outros", checked: false },
    { value: "Nenhum", label: "Nenhum", checked: false }
  ]
};

// PARTE 3

export const S03Q01: SelectNormal = {
  label: "Com qual frequência você utiliza inoculantes biológicos para a soja?",
  value: "",
  name: "S03Q01",
  options: [
    { value: "Faço o uso de inoculantes em todo plantio", label: "Faço o uso de inoculantes em todo plantio" },
    {
      value: "Faço o uso de inoculantes a cada dois plantios",
      label: "Faço o uso de inoculantes a cada dois plantios"
    },
    { value: "Raramente uso", label: "Raramente uso" },
    { value: "Nunca utilizei", label: "Nunca utilizei" }
  ]
};

export const S03Q02: SelectWithInput = {
  label: "Você utilizou inoculantes biológicos na sua última lavoura de soja?",
  value: "",
  name: "S03Q02",
  inputValue: "",
  inputName: "",
  inputTriggered: false,
  inputLabel: "Quais?",
  options: [
    { value: "Sim", label: "Sim", triggersInput: true },
    { value: "Não", label: "Não", triggersInput: false }
  ]
};
