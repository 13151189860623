import React from "react";
import { Spacing, TDialog, TDialogActions, TDialogButton, TDialogContent, TDialogContentText, Title } from "./styles";

interface DialogProps {
  openDialog: boolean;
  handleDisagree(): void;
  handleAgree(): void;
}

export const TermsDialog: React.FC<DialogProps> = ({ openDialog, handleAgree, handleDisagree }) => {
  /* eslint-disable */
  return (
    <TDialog
      open={openDialog}
      onClose={handleDisagree}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Spacing height={20} />

      <Title >Atenção!</Title>
      <TDialogContent>
        <TDialogContentText id="alert-dialog-description">
          <Spacing height={12} />
          Ao ativar um Kit você está aceitando os{" "}
          <a href="https://www.agregabiotec.com/termos-de-uso" target="_blank">
            Termos de Uso
          </a>{" "}
          e a{" "}
          <a href="https://www.agregabiotec.com/politica-de-privacidade" target="_blank">
            Política de Privacidade.
          </a>
          <Spacing height={12} />
        </TDialogContentText>
      </TDialogContent>
      <TDialogActions>
        <TDialogButton primary={"false"} onClick={handleDisagree}>
          Cancelar
        </TDialogButton>
        <TDialogButton primary={"true"} onClick={handleAgree} color="primary" autoFocus>
          Concordo
        </TDialogButton>
      </TDialogActions>
    </TDialog>
  );
  /* eslint-disable */
};

export default TermsDialog;
