import React, { InputHTMLAttributes } from "react";

import "./styles.css";
import MaskedInput from "react-text-mask";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label: string;
  mask: any[];
}

const MyMaskedInput: React.FC<InputProps> = ({ label, name, mask, value, id, ...rest }) => {
  return (
    <div className="input-block">
      <label htmlFor={name}>{label}</label>
      <MaskedInput mask={mask} value={value} className="form-control" guide={false} id={id} type="text" {...rest} />
    </div>
  );
};

export default MyMaskedInput;
