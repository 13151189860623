import React from "react";
import { ImageContainer, Left, LogoContainer, Main, Right } from "./styles";

import logo from "../../assets/images/agrega_logo_branco.png";
import ilustracao from "../../assets/images/ilustração_4.png";

export const Layout: React.FC = ({ children }) => {
  return (
    <Main>
      <Left>
        <LogoContainer>
          <img src={logo} alt="Logo" />
        </LogoContainer>
        <ImageContainer>
          <img src={ilustracao} alt="Ilustracao" />
        </ImageContainer>
      </Left>
      <Right>{children}</Right>
    </Main>
  );
};

export default Layout;
