import Button1 from "components/Button1";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { MyInput, TableContainer, TableContent, TableHeader } from "./styles";

interface Trable2Props {
  handleChangeSearch(value: string): void;
}

export const Table1: React.FC<Trable2Props> = ({ children, handleChangeSearch }) => {
  const [value, setValue] = useState("");

  const handleChangeValue = (value: string) => {
    setValue(value);
    handleChangeSearch(value);
  };

  const history = useHistory();

  return (
    <TableContainer>
      <TableHeader>
        <div>
          <div className="title">Usuários</div>
          <div style={{ marginLeft: 16 }}>
            <Button1 onClick={() => history.push("/new-user")}>Criar usuário</Button1>
          </div>
        </div>
        <div>
          <MyInput
            name="search"
            onChange={(e) => handleChangeValue(e.target.value)}
            style={{ marginTop: 0 }}
            label=""
            value={value}
          />
        </div>
      </TableHeader>
      <TableContent>{children}</TableContent>
    </TableContainer>
  );
};

export default Table1;

