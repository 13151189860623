import Button1 from "components/Button1";
import React, { useState } from "react";

import { Item, MyInput, TableContainer, TableContent, TableFooter, TableHeader } from "./styles";

interface Trable3Props {
  handlePreviousPage(): void;
  handleNextPage(): void;
  handleClickNew(): void;
  handleDownloadSoil(): void;
  handleDownloadHuman(): void;
  handleChangeSearch(value: string): void;
  total: number;
  offset: number;
  limit: number;
}

export const Table3: React.FC<Trable3Props> = ({
  children,
  handleChangeSearch,
  handleNextPage,
  handlePreviousPage,
  handleClickNew,
  handleDownloadSoil,
  handleDownloadHuman,
  total,
  offset,
  limit
}) => {
  const [value, setValue] = useState("");

  const handleChangeValue = (value: string) => {
    setValue(value);
    handleChangeSearch(value);
  };

  return (
    <TableContainer>
      <TableHeader>
        <div>
          <div className="title">Packs</div>
          <MyInput
            name="search"
            onChange={(e) => handleChangeValue(e.target.value)}
            style={{ marginTop: 0 }}
            label=""
          />
        </div>
        <div>
          <Button1 onClick={() => handleDownloadSoil()}>Baixar Solo</Button1>
          <Button1 onClick={() => handleDownloadHuman()}>Baixar Intestinal</Button1>
          <Button1 onClick={() => handleClickNew()}>Cadatrar novo</Button1>
        </div>
      </TableHeader>
      <TableContent>{children}</TableContent>
      {!value && (
        <TableFooter>
          <Item onClick={handlePreviousPage}>{"<"}</Item>
          <Item selected={false}>{offset >= total ? -1 : offset / limit + 1}</Item>
          <Item onClick={handleNextPage}>{">"}</Item>
        </TableFooter>
      )}
    </TableContainer>
  );
};

export default Table3;
