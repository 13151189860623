import styled from "styled-components";

export const Page = styled.div`
  flex: 1 1 auto;

  background-color: var(--color-roxo);

  display: flex;
  justify-content: center;

  overflow: hidden;

  @media (min-width: 1200px) {
    padding: 15px 15px 0 15px;
    height: 100vh;
  }
`;

export const Container = styled.div`
  display: none;

  @media (min-width: 1200px) {
    flex: 1 1 auto;
    height: 100vh;

    background-image: url("/src/assets/images/Comilão.png");
    background-color: var(--color-roxo);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    overflow: hidden;

    padding: 0 15px;
  }
`;

export const Mobile = styled.div`
  flex: 1 1 auto;

  background-color: #fff;
  border-radius: 10px 10px 0 0;

  display: flex;
  flex-direction: column;
  align-items: center;

  overflow: hidden;

  @media (min-width: 1200px) {
    display: none;
  }
`;

export const Title = styled.div`
  font-size: 23px;
  font-weight: bold;
  width: 90%;

  padding-top: 20px;
`;
export const KitList = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px 0;

  overflow: auto;
`;

export const TableFooter = styled.div`
  display: none;

  @media (min-width: 1200px) {
    margin-top: auto;
    width: 100%;
    height: 100px;

    padding: 40px;

    display: flex;

    justify-content: center;
    align-items: center;
  }
`;

interface ItemProps {
  readonly selected?: boolean;
}

export const Item = styled.div<ItemProps>`
  background: ${(props) =>
    props.selected ? "#5F33FF 0% 0% no-repeat padding-box" : "#FFFFFF 0% 0% no-repeat padding-box"};
  color: ${(props) => (props.selected ? "#FFFFFF" : "#5F33FF")};

  border: 1px solid #8898aa80;
  opacity: 1;
  width: 35px;
  height: 35px;
  border-radius: 100%;

  font-weight: 14px;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  & + & {
    margin-left: 15px;
  }
`;
