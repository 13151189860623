/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useState } from "react";
import { Delete } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Emoji from "components/Emoji";
import Loading from "components/Loading";
import { FiX } from "react-icons/fi";
import CheckboxList from "components/CheckboxList";
import RadioButtonList from "components/RadioButton";
import NotAnswering from "components/Dialogs/NotAnswerDialog";
import OldFormsDialog from "components/Dialogs/OldFormsDialog";
import api from "../../services/api";
import Input from "../../components/FormInput";
import Select from "../../components/FormSelect";
import SelectWithInput from "../../components/SelectWithInput";
import Dropzone from "../../components/Dropzone";
import {
  Button,
  Container,
  LandingCard,
  Card,
  FileCard,
  InputGroupChild,
  InputGroup,
  ButtonSave,
  Spacing
} from "./styles";
import { getUF, getUFCities, IBGEUFResponse, IBGECityResponse } from "../../services/ibge";
import Autocomplete from "../../components/Autocomplete";

import * as Questionario from "./questions";

interface ITemplate {
  S01Q01: Questionario.AutoComplete;
  S01Q02: Questionario.AutoComplete;
  S01Q03: Questionario.InputNormal;
  S01Q04: Questionario.InputNormal;
  S01Q05: Questionario.RadioButtonList;
  S01Q06: Questionario.SelectNormal;
  S02Q01: Questionario.SelectNormal;
  S02Q02: Questionario.SelectWithInput;
  S02Q03: Questionario.CheckboxList;
  S02Q04: Questionario.SelectWithInput;
  S02Q05: Questionario.CheckboxList;
  S03Q01: Questionario.SelectNormal;
  S03Q02: Questionario.SelectWithInput;
}

export default function FormSoil() {
  const [show, setShow] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openOldFormsDialog, setOpenOldFormsDialog] = React.useState(false);
  const [answered, setAnswered] = React.useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [section, setSection] = useState<number>(1);

  const [ufs, setUfs] = useState<IBGEUFResponse[]>([]);
  const [cities, setCities] = useState<IBGECityResponse[]>([]);

  const [files, setFiles] = useState<File[]>([]);
  const params: { id: string } = useParams();

  const [oldForms, setOldForms] = useState<{ kit: string; form: string }[]>([]);

  const history = useHistory();

  const [questionario, setQuestionario] = useState<ITemplate>({
    S01Q01: Questionario.S01Q01,
    S01Q02: Questionario.S01Q02,
    S01Q03: Questionario.S01Q03,
    S01Q04: Questionario.S01Q04,
    S01Q05: Questionario.S01Q05,
    S01Q06: Questionario.S01Q06,
    S02Q01: Questionario.S02Q01,
    S02Q02: Questionario.S02Q02,
    S02Q03: Questionario.S02Q03,
    S02Q04: Questionario.S02Q04,
    S02Q05: Questionario.S02Q05,
    S03Q01: Questionario.S03Q01,
    S03Q02: Questionario.S03Q02
  });

  useEffect(() => {
    localStorage.removeItem("@RAuth:AGREGA:formCode");
  }, []);

  useEffect(() => {
    if (section > 0) document.querySelector("#page-form > .card-content")?.scrollTo(0, 0);
  }, [section]);

  const getOldForm = (formId) => {
    api.get(`questionario/solo/${formId}`).then((res) => {
      const { data } = res;

      if (!data || !data.responses) return;

      setAnswered(data.answered);

      const oldForm = { ...questionario };

      Object.keys(data.responses).forEach((key) => {
        const savedResponse = data.responses[key];
        oldForm[key] = { ...oldForm[key], ...savedResponse };
      });

      setFiles(data.files);

      setQuestionario(oldForm);
    });
  };

  useEffect(() => {
    getOldForm(params.id);
    // eslint-disable-next-line
  }, [params]);

  useEffect(() => {
    getUF().then((response) => setUfs(response.data));
  }, []);

  useEffect(() => {
    getUFCities(questionario.S01Q01.value).then((response) => setCities(response.data));
  }, [questionario.S01Q01.value]);

  const setQuestion = (question) => {
    setQuestionario({ ...questionario, [question.name]: { ...question } });
  };

  const handleSetResult = (file: File) => {
    const f = files.find((f) => f.name === file.name);

    if (!f) {
      setFiles([...files, file]);
    }
  };

  const handleDeleteResult = (file: File) => {
    const i = files.findIndex((f) => f.name === file.name);

    if (i >= 0) setFiles([...files.slice(0, i), ...files.slice(i + 1)]);
  };

  const handleSaveForm = () => {
    setLoading(true);
    const formId = params.id;

    const data = new FormData();

    data.append("form", JSON.stringify(questionario));

    files.forEach((file) => {
      data.append("laudos", file);
    });

    if (formId) {
      api
        .post(`questionario/solo/${formId}`, data, { headers: { "Content-Type": "multipart/form-data" } })
        .then((res) => {
          if (res.status === 200) {
            setLoading(false);

            toast.success("Formulário respondido com sucesso!");
            history.push("/home");
          }
        });
    }
  };

  const handleAgree = () => {
    setOpenDialog(false);
    history.push("/home");
  };

  const handleDisagree = () => {
    setOpenDialog(false);
  };

  const handleAnswer = () => {
    if (answered) {
      setShow(true);
    } else {
      api.get(`kit/questionario/respondidos/solo/${params.id}`).then((res) => {
        if (!res.data || res.data.length === 0) {
          setShow(true);
        } else {
          const forms = res.data;
          setOldForms(forms);
          setOpenOldFormsDialog(true);
        }
      });
    }
  };

  const handleNotSelect = () => {
    setOldForms([]);
    setOpenOldFormsDialog(false);
    setShow(true);
  };

  const handleSelect = (formId: string) => {
    getOldForm(formId);
    setOpenOldFormsDialog(false);
    setShow(true);
    setOldForms([]);
  };

  return (
    <Container>
      <Loading active={loading} />
      {show ? (
        <Card id="page-form">
          <div className="closeIcon">
            <IconButton onClick={handleSaveForm}>
              <FiX />
            </IconButton>
          </div>
          <div className="card-content">
            {section === 1 && (
              <fieldset>
                <header>
                  <legend>
                    <div>
                      Inicialmente, alguns <span className="roxo">dados gerais</span>
                    </div>
                  </legend>
                </header>
                <section>
                  <Spacing height={66} />
                  <p>Qual localidade de origem de sua amostra de solo?</p>

                  <InputGroup>
                    <InputGroupChild grow={5} maxWidth={90}>
                      <Autocomplete
                        options={ufs.map((uf) => uf.sigla)}
                        setQuestion={setQuestion}
                        question={questionario.S01Q01 as Questionario.AutoComplete}
                      />
                    </InputGroupChild>

                    <InputGroupChild grow={2}>
                      <Autocomplete
                        options={cities.map((city) => city.nome)}
                        setQuestion={setQuestion}
                        question={questionario.S01Q02 as Questionario.AutoComplete}
                      />
                    </InputGroupChild>
                  </InputGroup>
                  <InputGroup>
                    <InputGroupChild grow={1}>
                      <Input type="date" question={questionario.S01Q03} setFunction={setQuestion} />
                    </InputGroupChild>

                    <InputGroupChild grow={1}>
                      <Input type="date" question={questionario.S01Q04} setFunction={setQuestion} />
                    </InputGroupChild>
                  </InputGroup>

                  <RadioButtonList question={questionario.S01Q05} setFunction={setQuestion} />

                  <Select question={questionario.S01Q06} setFunction={setQuestion} />
                </section>
              </fieldset>
            )}
            {section === 2 && (
              <fieldset>
                <header>
                  <legend>
                    <div>
                      A respeito das características da <br />
                      sua <span className="roxo">última safra de soja</span> <Emoji symbol="🌱" label="planta" />
                    </div>
                  </legend>
                </header>
                <div>
                  <section>
                    <Spacing height={66} />
                    <p>Assinale abaixo as alternativas que melhor descrevem a lavoura da área de amostragem do solo:</p>

                    <Select question={questionario.S02Q01} setFunction={setQuestion} />
                    <SelectWithInput question={questionario.S02Q02} setFunction={setQuestion} />
                    <CheckboxList question={questionario.S02Q03} setFunction={setQuestion} />
                    <SelectWithInput question={questionario.S02Q04} setFunction={setQuestion} />
                    <CheckboxList question={questionario.S02Q05} setFunction={setQuestion} />
                  </section>
                </div>
              </fieldset>
            )}
            {section === 3 && (
              <fieldset>
                <div>
                  <header>
                    <legend>
                      <div>
                        A respeito do uso <br /> de <span className="roxo">inoculantes biológicos</span>{" "}
                        <Emoji symbol="🦠" label="microorganismo" />
                      </div>
                    </legend>
                  </header>
                  <section>
                    <Spacing height={66} />
                    <Select question={questionario.S03Q01} setFunction={setQuestion} />
                    <SelectWithInput question={questionario.S03Q02} setFunction={setQuestion} />
                  </section>
                </div>
              </fieldset>
            )}
            {section === 4 && (
              <fieldset>
                <div>
                  <header>
                    <legend>
                      <div>
                        Resultados de análise química <Emoji symbol="🧪" label="quimica" />
                      </div>
                    </legend>
                  </header>
                  <section>
                    <Spacing height={66} />
                    <p style={{ marginBottom: 16 }}>
                      Se você possuir laudos de análises químicas do solo da área de amostragem, por favor, envie os
                      resultados pelo link abaixo:
                    </p>

                    {files.map((file) => (
                      <FileCard key={file.name}>
                        <p>{file.name}</p>
                        <IconButton onClick={() => handleDeleteResult(file)}>
                          <Delete />
                        </IconButton>
                      </FileCard>
                    ))}

                    <Dropzone onFileUploaded={handleSetResult} label="Upload" />
                  </section>
                </div>
              </fieldset>
            )}
          </div>
          {section === 4 ? (
            <footer>
              <Button onClick={() => (section > 1 ? setSection(section - 1) : null)}>Voltar</Button>
              <Button primary onClick={handleSaveForm}>
                Salvar
              </Button>
            </footer>
          ) : (
            <footer>
              <ButtonSave width="160px" onClick={handleSaveForm}>
                Salvar e sair
              </ButtonSave>

              <div>
                <Button onClick={() => (section > 1 ? setSection(section - 1) : null)}>Voltar</Button>
                <Button primary onClick={() => setSection(section + 1)}>
                  Próximo
                </Button>
              </div>
            </footer>
          )}
        </Card>
      ) : (
        <LandingCard>
          <div className="card-content">
            <header>
              <div>
                Temos algumas perguntas para te fornecer um <span className="roxo">resultado ainda melhor</span>!
                <Emoji symbol="🙂" label="sorrindo" />{" "}
              </div>
            </header>
            <section>
              <Spacing height={66} />
              <div>
                Tais informações são utilizadas para treinar o nosso algoritmo e criar perfis de saúde e produtividade
                do solo. Quando você responde o questionário, usamos suas respostas para calibrar a análise.
              </div>
            </section>
          </div>
          <footer>
            <Button onClick={() => setOpenDialog(true)}>Não quero responder o questionário</Button>
            <Button primary onClick={() => handleAnswer()}>
              Quero responder o questionário.
            </Button>
          </footer>
          <NotAnswering openDialog={openDialog} handleAgree={handleAgree} handleDisagree={handleDisagree} />
          <OldFormsDialog
            openDialog={openOldFormsDialog}
            handleNotSelect={handleNotSelect}
            handleSelect={handleSelect}
            options={oldForms}
          />
        </LandingCard>
      )}
    </Container>
  );
}
