/* eslint-disable @typescript-eslint/ban-types */
import React, { useEffect, useState } from "react";

import { FiPackage } from "react-icons/fi";

import { IKit, IKitType } from "types";
import api from "../../services/api";
import { Button, KitStatusColor, TableContainer, TableContent, TableHeader } from "./styles";

// eslint-disable-next-line @typescript-eslint/naming-convention
interface column {
  label: string;
  field: string;
}

interface KitTableProps {
  kit_type: number;
  kits: IKit[];
  columns: column[];
  onClickDownload?: Function;
  onClickAccess?: Function;
  onClickForm?: Function;
  onClickRegister?: Function;
  maxHeight?: string;
  title?: string;
}

const KitsTable: React.FC<KitTableProps> = ({
  kit_type,
  kits,
  columns,
  onClickDownload,
  onClickAccess,
  onClickForm,
  onClickRegister,
  maxHeight,
  title,
  children
}) => {
  const [kitType, setKitType] = useState<IKitType>();
  const [loading, setLoading] = useState(true);

  const [listHeaderClass, setListHeaderClass] = useState("");
  const [listRowClass, setListRowClass] = useState("");

  const [kitTypeMap, setKitTypeMap] = useState<{ [Key: string]: string }>({});

  useEffect(() => {
    if (Number(kit_type) === 0) return setListHeaderClass("extra-small-list list-header");
    if (Number(kit_type) === 3) return setListHeaderClass("list list-header");
    if (Number(kitType?.id) === 4) return setListHeaderClass("small-list list-header");
    return setListHeaderClass("list list-header");
  }, [kit_type, kitType]);

  useEffect(() => {
    if (kit_type === 0) return setListRowClass("extra-small-list list-row");
    if (kit_type === 3) return setListRowClass("list list-row");
    if (kitType?.id === 4) return setListRowClass("small-list list-row");
    return setListRowClass("list list-row");
  }, [kit_type, kitType]);

  useEffect(() => {
    setLoading(true);

    if (kit_type !== 0) {
      api.get("kit-types").then((res) => {
        setKitType(res.data.find((kitType: IKitType) => kitType.id === Number(kit_type)));
        setLoading(false);
      });
    } else {
      api.get("kit-types").then((res) => {
        setKitTypeMap(
          res.data.reduce((r: { [Key: string]: string }, c: IKitType) => {
            const old = r;
            old[c.id] = c.type;
            return old;
          }, {})
        );
        setLoading(false);
      });
      // .catch((err) => console.log(err));
    }
  }, [kit_type]);

  return (
    <>
      {!loading && (
        <TableContainer maxHeight={maxHeight}>
          <TableHeader>
            <div>
              <div className="title">
                {title || "Kits"} {kit_type === 0 ? null : kitType?.type}
              </div>
            </div>
            <div>
              <Button
                onClick={() => {
                  if (onClickRegister) onClickRegister();
                }}
              >
                Cadastrar novo
              </Button>
            </div>
          </TableHeader>
          <TableContent>
            <div className={listHeaderClass}>
              <div />
              {columns.map((column) => (
                <div key={column.label}>{column.label}</div>
              ))}
            </div>
            {kits.length ? (
              <>
                {kits.map((kit: IKit) => {
                  const hasResult = kit.hasHtml || kit.hasPdf || kit.hasZip;

                  return (
                    <div key={kit.id} className={listRowClass}>
                      <div>
                        <FiPackage />
                      </div>
                      <div className="kit">{kit.id}</div>
                      {kit_type === 0 && kitTypeMap && <div>{kitTypeMap[kit.type_id]}</div>}
                      <div>
                        {new Intl.DateTimeFormat("pt-BR", {
                          year: "numeric",
                          month: "numeric",
                          day: "numeric"
                        }).format(new Date(kit.created_at))}
                      </div>
                      <div className="status">
                        <KitStatusColor color={hasResult ? "#2EF5FF" : "#5F33FF"} />
                        {kit.status}
                      </div>
                      {columns.map((col) => col.field).includes("hasForm") && onClickForm && (
                        <div>
                          <button
                            type="button"
                            disabled={!kit.hasForm}
                            onClick={() => {
                              onClickForm(kit.id, kit_type);
                            }}
                            className={kit.hasForm ? "button-1" : "button-2"}
                          >
                            {kit.hasForm ? "Responder" : "Indisponível"}
                          </button>
                        </div>
                      )}
                      {columns.map((col) => col.field).includes("files") && onClickDownload && (
                        <div className="result-buttons">
                          <button
                            type="button"
                            className={hasResult ? "button-1" : "button-2"}
                            onClick={() => onClickDownload(hasResult, kit.id, "zip")}
                          >
                            Download
                          </button>
                        </div>
                      )}
                      {columns.map((col) => col.field).includes("hasResult") && onClickDownload && onClickAccess && (
                        <>
                          {kit_type === 3 ? (
                            <div className="result-buttons">
                              <button
                                type="button"
                                className={hasResult ? "button-1" : "button-2"}
                                onClick={() => onClickDownload(kit.hasPdf, kit.id, "pdf")}
                              >
                                Laudo PDF
                              </button>
                              <button
                                type="button"
                                className={hasResult ? "button-1" : "button-2"}
                                onClick={() => onClickAccess(kit.hasHtml, kit.id, "html")}
                              >
                                Laudo Online
                              </button>
                            </div>
                          ) : (
                            <div className="result-buttons">
                              <button
                                type="button"
                                className={kit.hasPdf ? "button-1" : "button-2"}
                                onClick={() => onClickDownload(kit.hasPdf, kit.id, "pdf")}
                              >
                                Download
                              </button>
                              <button
                                type="button"
                                className={kit.hasHtml ? "button-1" : "button-2"}
                                onClick={() => onClickAccess(kit.hasHtml, kit.id, "html")}
                              >
                                Acessar
                              </button>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  );
                })}
                {children}
              </>
            ) : (
              <div className="empty-table">Sem kits para visualizar...</div>
            )}
          </TableContent>
        </TableContainer>
      )}
    </>
  );
};

export default KitsTable;
