import styled from "styled-components";

export const Header = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.div`
  width: 100%;

  text-align: left;
  color: #210b3a;

  font-family: Coolvetica;
  font-size: 95px;

  span {
    color: #5f33ff;
  }

  & + & {
    position: relative;
    top: -16px;
  }

  font-size: 42px;

  @media (min-width: 1200px) {
    // letter-spacing: -8px;
    font-size: 80px;

    & + & {
      position: relative;
      top: -32px;
    }
  }
`;

export const Subtitle = styled.div`
  width: 100%;

  text-align: left;
  font: normal 12px Poppins;
  color: #210b3a;
  opacity: 1;

  @media (min-width: 1200px) {
    overflow: hidden;
    font: normal 16px Poppins;
    letter-spacing: 0px;
    position: relative;
    top: -32px;
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 450px;
`;

interface SpacingProps {
  readonly height?: string;
}

export const Spacing = styled.div<SpacingProps>`
  height: ${(props) => (props.height ? props.height : "40px")};
`;
