import Button1 from "components/Button1";
import Button2 from "components/Button2";
import React from "react";
import { FiPackage } from "react-icons/fi";
import {
  IconContainer,
  Kit,
  KitButtons,
  KitDate,
  KitDetails,
  KitFooter,
  KitHeader,
  KitId,
  KitInfo,
  KitStatus,
  KitStatusColor
} from "./styles";

interface KitMobileProps {
  kit: any;
  onClickDownload?(hasResult: boolean, kit: string, extension: string): void;
  onClickAccess?(hasResult: boolean, kit: string, extension: string): void;
  onClickForm?(id: string, kit_id: string): void;
}

const getDateFormated = (date) => {
  return new Intl.DateTimeFormat("pt-BR", {
    year: "numeric",
    month: "numeric",
    day: "numeric"
  }).format(new Date(date));
};

export const KitMobile: React.FC<KitMobileProps> = ({ children, kit, onClickAccess, onClickForm }) => {
  return (
    <Kit key={kit.id}>
      <KitHeader>
        <KitInfo>
          <IconContainer>
            <FiPackage />
          </IconContainer>
          <KitDetails>
            <KitId>Kit {kit.id.split("-")[0]}</KitId>
            <KitDate>{getDateFormated(kit.created_at)}</KitDate>
          </KitDetails>
        </KitInfo>
        <KitStatus>
          <KitStatusColor color={kit.hastPdf || kit.hasHtml ? "#2EF5FF" : "#5F33FF"} />
          {kit.status}
        </KitStatus>
      </KitHeader>
      <KitFooter>
        {true && (
          <KitButtons>
            {kit.hasHtml || kit.hasPdf || kit.hasZip ? (
              <Button1 onClick={() => onClickAccess && onClickAccess(true, kit.id, "html")}>Acessar Resultados</Button1>
            ) : (
              <Button2>Acessar Resultados</Button2>
            )}
            {kit.hasForm ? (
              <Button1 onClick={() => onClickForm && onClickForm(kit.id, kit.type_id)}>Acessar Questionário</Button1>
            ) : (
              <Button2>Acessar Questionário</Button2>
            )}
          </KitButtons>
        )}
      </KitFooter>
    </Kit>
  );
};

export default KitMobile;
