import React from "react";

import "./styles.css";

import LoadingOverlay from "react-loading-overlay";
import RingLoader from "react-spinners/RingLoader";

interface LoadingProps {
  active: boolean;
}

const Loading: React.FC<LoadingProps> = ({ active, children }) => {
  return (
    <LoadingOverlay
      active={active}
      spinner={<RingLoader size={150} color="#2ef5ff" />}
      className={active ? "overlay" : "overlay-off"}
    >
      {children}
    </LoadingOverlay>
  );
};

export default Loading;
