import React, { useState, useEffect, FormEvent, ChangeEvent } from "react";
import api from "services/api";

import { useAuth } from "contexts/auth";
import { useHistory } from "react-router-dom";

import Loading from "components/Loading";
import { IUser } from "types";
import { getCEP, validarCEP } from "services/cep";
import { toast } from "react-toastify";
import Button1 from "components/Button1";
import { validarCPF } from "services/utils";
import {
  Container,
  Form,
  FormContent,
  Header,
  InputContent,
  InputError,
  InputGroup,
  Main,
  MaskedInput,
  MyInput,
  Title
} from "./styles";

interface User extends IUser {
  actual_password?: string;
  new_password?: string;
}

interface Errors {
  [key: string]: string;
}

export default function Profile() {
  const [errors, setErros] = useState<Errors>({});
  const [user, setUser] = useState<User>({
    email: "",
    name: "",
    address: "",
    city: "",
    cep: "",
    complement: "",
    cpf: "",
    mobile_phone: "",
    neighborhood: "",
    phone: "",
    number: "",
    reference: "",
    uf: "",
    password: ""
  });
  const [loading] = useState(false);
  const [changing_password, setChangingPassword] = useState(false);
  const [actual_password, setActualPassword] = useState<string>("");
  const [new_password, setNewPassword] = useState<string>("");

  const { update } = useAuth();
  const history = useHistory();

  useEffect(() => {
    async function getUser() {
      await api.get("/profile").then((res) => {
        setUser(res.data);
      });
      // .catch((err) => console.log(err));
    }

    getUser();
  }, []);

  async function handleValidation() {
    let isValid = true;

    let newErrors = {};

    if (user.email === "" || !user.email) {
      newErrors = { ...newErrors, email: "E-mail é obrigatório." };
      isValid = false;
    }

    if (user.password === "") {
      newErrors = { ...newErrors, password: "Senha é obrigatória." };

      isValid = false;
    }

    if (user.name === "") {
      newErrors = { ...newErrors, name: "Nome é obrigatório." };
      isValid = false;
    }

    if (user.cpf === "" || !user.cpf) {
      newErrors = { ...newErrors, cpf: "CPF ou CNPJ é obrigatório." };
      isValid = false;
    }

    if (user.address === "" || !user.address) {
      newErrors = { ...newErrors, address: "Endereço é obrigatório." };
      isValid = false;
    }

    if (user.cep === "" || !user.cep) {
      newErrors = { ...newErrors, cep: "Endereço é obrigatório." };
      isValid = false;
    }

    if (user.number === "" || !user.number) {
      newErrors = { ...newErrors, number: "Número é obrigatório." };
      isValid = false;
    }

    if (user.city === "" || !user.city) {
      newErrors = { ...newErrors, city: "Cidade é obrigatório." };
      isValid = false;
    }

    if (user.uf === "" || !user.uf) {
      newErrors = { ...newErrors, uf: "UF é obrigatório." };
      isValid = false;
    }

    if (user.cpf !== "" && validarCPF(user.cpf) === false) {
      newErrors = { ...newErrors, cpf: "CPF ou CNPJ inválido." };
      isValid = false;
    }

    if (user.cep !== "" && (await validarCEP(user.cep)) === false) {
      newErrors = { ...newErrors, cep: "CEP inválido." };
      isValid = false;
    }

    setErros(newErrors);
    return isValid;
  }

  async function handleRegister(e: FormEvent) {
    e.preventDefault();

    const isValid = await handleValidation();

    if (isValid) {
      const res = await update({
        ...user,
        actual_password,
        new_password
      });

      if (res) history.push("/home");
    } else {
      toast.error("O formulário precisa ser preenchido corretamente.");
    }
  }

  function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
    const { id, value } = event.target;
    setUser({ ...user, [id]: value } as User);
  }

  function handleActualPasswordChange(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    setActualPassword(value);
  }

  function handleNewPasswordChange(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    setNewPassword(value);
  }

  function handleCepChange() {
    const { cep } = user;

    if (!cep) {
      return;
    }

    // setLoading(true);
    getCEP(cep).then((res) => {
      if (res && res.status === 200 && !(res.data.erro === true)) {
        const { complemento, localidade, logradouro, uf, bairro } = res.data;
        setUser({
          ...user,
          complement: complemento,
          uf,
          city: localidade,
          address: logradouro,
          neighborhood: bairro
        });
      }
      // setLoading(false);
    });
    // .catch((err) => console.log(err));
  }

  return (
    <Container>
      <Loading active={loading} />
      <Main>
        <Form onSubmit={handleRegister}>
          <Header>
            <Title>Seus Dados</Title>
            <Button1 type="submit">Salvar</Button1>
          </Header>

          <FormContent>
            <InputGroup inputs={2}>
              <InputContent>
                <MyInput
                  name="name"
                  label="Nome completo"
                  value={user && user.name ? user.name : ""}
                  onChange={handleInputChange}
                />
                {errors.name && <InputError>{errors.name}</InputError>}
              </InputContent>
              <InputContent>
                <MyInput
                  name="cpf"
                  id="cpf"
                  value={user && user.cpf ? user.cpf : ""}
                  label="CPF ou CNPJ"
                  onChange={handleInputChange}
                />
                {errors.cpf && <InputError>{errors.cpf}</InputError>}
              </InputContent>
            </InputGroup>

            <InputGroup inputs={2}>
              <InputContent>
                <MaskedInput
                  name="phone"
                  id="phone"
                  value={user && user.phone ? user.phone : ""}
                  label="Telefone"
                  mask={["(", /[1-9]/, /\d/, ")", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                  onChange={handleInputChange}
                />
              </InputContent>
              <InputContent>
                <MaskedInput
                  name="mobile_phone"
                  id="mobile_phone"
                  value={user && user.mobile_phone ? user.mobile_phone : ""}
                  label="Celular"
                  mask={["(", /[1-9]/, /\d/, ")", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                  onChange={handleInputChange}
                />
              </InputContent>
            </InputGroup>

            <InputGroup inputs={3}>
              <InputContent>
                <MaskedInput
                  name="cep"
                  label="CEP"
                  id="cep"
                  value={user && user.cep ? user.cep : ""}
                  mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                  onBlur={() => handleCepChange()}
                  onChange={handleInputChange}
                />
                {errors.cep && <InputError>{errors.cep}</InputError>}
              </InputContent>
              <InputContent>
                <MyInput
                  type="text"
                  name="uf"
                  label="Estado"
                  value={user && user.uf ? user.uf : ""}
                  onChange={handleInputChange}
                />
                {errors.uf && <InputError>{errors.uf}</InputError>}
              </InputContent>
              <InputContent>
                <MyInput
                  type="text"
                  name="city"
                  label="Cidade"
                  value={user && user.city ? user.city : ""}
                  onChange={handleInputChange}
                />
                {errors.city && <InputError>{errors.city}</InputError>}
              </InputContent>
            </InputGroup>

            <InputGroup inputs={2}>
              <InputContent>
                <MyInput
                  type="text"
                  name="address"
                  label="Endereço"
                  value={user && user.address ? user.address : ""}
                  onChange={handleInputChange}
                />
                {errors.address && <InputError>{errors.address}</InputError>}
              </InputContent>
              <InputContent>
                <MyInput
                  type="text"
                  name="number"
                  label="Número"
                  value={user && user.number ? user.number : ""}
                  onChange={handleInputChange}
                />
                {errors.number && <InputError>{errors.number}</InputError>}
              </InputContent>
            </InputGroup>

            <InputGroup inputs={3}>
              <InputContent>
                <MyInput
                  name="complement"
                  label="Complemento"
                  value={user && user.complement ? user.complement : ""}
                  onChange={handleInputChange}
                />
                {errors.complement && <InputError>{errors.complement}</InputError>}
              </InputContent>
              <InputContent>
                <MyInput
                  name="neighborhood"
                  label="Bairro"
                  value={user && user.neighborhood ? user.neighborhood : ""}
                  onChange={handleInputChange}
                />
                {errors.neighborhood && <InputError>{errors.neighborhood}</InputError>}
              </InputContent>
              <InputContent>
                <MyInput
                  name="reference"
                  label="Referência"
                  value={user && user.reference ? user.reference : ""}
                  onChange={handleInputChange}
                />
                {errors.reference && <InputError>{errors.reference}</InputError>}
              </InputContent>
            </InputGroup>

            {changing_password ? (
              <InputGroup inputs={2}>
                <InputContent>
                  <MyInput
                    type="password"
                    name="actual_password"
                    label="Senha atual"
                    value={actual_password}
                    onChange={handleActualPasswordChange}
                  />
                </InputContent>
                <InputContent>
                  <MyInput
                    type="password"
                    name="confirm_password"
                    label="Nova senha"
                    value={new_password}
                    onChange={handleNewPasswordChange}
                  />
                </InputContent>
              </InputGroup>
            ) : (
              <InputGroup inputs={2}>
                <InputContent />
                <InputContent>
                  <Button1 type="button" onClick={() => setChangingPassword(true)}>
                    Mudar senha
                  </Button1>
                </InputContent>
              </InputGroup>
            )}
          </FormContent>
        </Form>
      </Main>
    </Container>
  );
}
