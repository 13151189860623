import styled from "styled-components";

export const Container = styled.div`
  flex: 1 1 auto;

  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Message = styled.h1``;
