import styled from "styled-components";

import Input from "components/Input";

export const TableContainer = styled.div`
  flex: 1 1 auto;
  width: 100%;

  height: 100%;

  max-width: 1000px;

  background-color: #fff;

  box-shadow: 0px 3px 6px #2c28281c;
  border-radius: 10px;

  display: flex;
  flex-direction: column;

  max-height: 600px;

  overflow: hidden;
`;

export const TableHeader = styled.div`
  width: 100%;
  height: 100px;

  padding: 40px;

  display: flex;

  justify-content: space-between;
  align-items: center;

  .title {
    font: 24px Poppins;
  }
  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    button + button {
      margin-left: 15px;
    }

    div + div {
      margin-left: 15px;
    }
  }
  button {
    height: 50px !important;
    width: 150px !important;
    margin-top: 0 !important;
  }

  .input-block {
    margin-top: 0 !important;
  }
`;

export const TableContent = styled.div`
  flex: 1 1 auto;
  width: 100%;
  // max-height: 400px;

  display: flex;
  flex-direction: column;

  overflow: auto;
`;

export const TableFooter = styled.div`
  display: none;

  @media (min-width: 1200px) {
    width: 100%;
    height: 100px;

    padding: 40px;

    display: flex;

    justify-content: center;
    align-items: center;
  }
`;

export const MyInput = styled(Input)`
  & > label {
    display: flex !important;
  }

  border-radius: 50px !important;
  height: 35px !important;
`;

interface ItemProps {
  readonly selected?: boolean;
}

export const Item = styled.div<ItemProps>`
  background: ${(props) =>
    props.selected ? "#5F33FF 0% 0% no-repeat padding-box" : "#FFFFFF 0% 0% no-repeat padding-box"};
  color: ${(props) => (props.selected ? "#FFFFFF" : "#5F33FF")};

  border: 1px solid #8898aa80;
  opacity: 1;
  width: 35px;
  height: 35px;
  border-radius: 100%;

  font-weight: 14px;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  & + & {
    margin-left: 15px;
  }
`;
