import styled from "styled-components";

import Input from "components/Input";

export const Header = styled.div`
  display: none;

  @media (min-width: 1200px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
`;

export const Title = styled.div`
  width: 100%;

  text-align: left;
  color: #210b3a;

  font-family: Coolvetica;
  font-size: 95px;

  span {
    color: #5f33ff;
  }

  & + & {
    position: relative;
    top: -16px;
  }

  font-size: 42px;

  @media (min-width: 1200px) {
    // letter-spacing: -8px;
    font-size: 80px;

    & + & {
      position: relative;
      top: -32px;
    }
  }
`;

export const Subtitle = styled.div`
  display: none;
  width: 100%;

  text-align: left;
  font: normal 12px Poppins;
  color: #210b3a;
  opacity: 1;

  @media (min-width: 1200px) {
    display: block;
    overflow: hidden;
    font: normal 16px Poppins;
    letter-spacing: 0px;
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 450px;
`;

interface SpacingProps {
  readonly height?: string;
}

export const Spacing = styled.div<SpacingProps>`
  display: none;
  height: ${(props) => (props.height ? props.height : "40px")};
  @media (min-width: 1200px) {
    height: ${(props) => (props.height ? props.height : "40px")};
  }
`;

export const MyInput = styled(Input)`
  width: 100%;
  max-width: 450px;

  height: 70px !important;

  border: 2px solid #5f33ff !important;
  border-radius: 5px !important;
  opacity: 1 !important;

  color: #5f33ff !important;
  font-weight: bold;
`;

export const Form = styled.form``;

export const Register = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  color: #8c98a9;

  a {
    cursor: pointer;
    color: #5f33ff;
    font-weight: 700px;
    text-decoration: underline;
  }
`;
