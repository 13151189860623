import styled from "styled-components";

export const Page = styled.div`
  flex: 1 1 auto;

  background-color: var(--color-roxo);

  display: flex;
  justify-content: center;
  align-items: center;

  overflow: hidden;

  @media (min-width: 1200px) {
    padding: 15px;
    height: 100vh;
  }
`;
export const Container = styled.div`
  width: 100%;
  background-color: #fff;
  flex: 1 1 auto;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;

  @media (min-width: 1200px) {
    flex: 1 1 auto;
    width: 100%;

    min-height: 500px;
    max-width: 1000px;

    max-height: 520px;

    background-color: #fff;

    box-shadow: 0px 3px 6px #2c28281c;
    border-radius: 10px;

    display: flex;
    flex-direction: column;
  }
`;

export const Header = styled.div`
  height: 100px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 60px;

  .title {
    font-weight: bold;
    font-size: 24px;
  }

  .buttons {
    display: flex;

    button + button {
      width: 180px;
      margin-left: 10px;
    }
  }
`;
export const Content = styled.div`
  flex: 1 1 auto;
  width: 100%;
`;
export const Footer = styled.div`
  height: 200px;
  padding: 0 60px;

  display: grid;
  grid-template-columns: 1fr;
  text-align: center;

  @media (min-width: 1200px) {
    height: 150px;
    padding: 0 60px;

    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: center;
  }
`;

export const Extension = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 2fr;
  text-align: center;

  max-width: 320px;

  width: 100%;

  & + & {
    margin-top: 10px;
  }
  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .title {
    font: 24px Poppins;
    font-weight: bold;
    color: #5F33FF

    margin: auto;
  }

  button {
    height: 50px !important;
    margin: auto;
    max-width: 120px;
  }

  .dropzone {
    height: 50px !important;
    margin: auto;
    max-width: 120px;
  }
`;

export const RowHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  text-align: center;

  background: #f1f3f9;

  height: 30px;

  div {
    margin: auto;
  }
`;

export const RowValues = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  text-align: center;

  height: 90px;

  div {
    margin: auto;

    .value {
      display: flex;
      align-items: center;
      justify-content: center;

      .input-block {
        margin-top: 0 !important;

        input {
          margin-top: 0 !important;
          height: 35px;
        }
      }
    }
  }
`;
