import React from "react";

import { useHistory } from "react-router-dom";
import { useParams } from "react-router";

import Page from "components/Page";
import LandingLayout from "components/LandingLayout";
import Button1 from "components/Button1";
import Button2 from "components/Button2";

import { Container, Header, Spacing, Subtitle, Title } from "./styles";

export const Landing = () => {
  const params: { type: string; kit: string } = useParams();

  const history = useHistory();

  return (
    <Page>
      <LandingLayout>
        <Container>
          <Header>
            <Title>
              <span>Olá,</span>
            </Title>
            <Title>bem vindo!</Title>
            <Subtitle>
              Registre aqui seu kit agrega microbioma ou acesse seus kits já registrados e acompanhe seus resultados.
            </Subtitle>
          </Header>
          <Spacing />
          <Button1
            onClick={() =>
              history.push(params.kit && params.type ? `/register/${params.type}/${params.kit}` : `/register/`)
            }
          >
            Esse é meu primeiro <span>kit</span>
          </Button1>
          <Spacing height="10px" />
          <Button2
            onClick={() => history.push(params.kit && params.type ? `/login/${params.type}/${params.kit}` : `/login/`)}
          >
            Já registrei um kit
          </Button2>
        </Container>
      </LandingLayout>
    </Page>
  );
};

export default Landing;
