import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";

// import { InputProps } from "../Input";

import "./styles.css";

// interface InputAutocomplete extends InputProps {
//   id: string;
//   options: string[];
// }

interface InputAutocompleteProps {
  setQuestion(question): void;
  question: Question;
  options: string[];
}

interface Question {
  name: string;
  value: string;
  inputValue: string;
  label: string;
}

const MyInputAutocomplete: React.FC<InputAutocompleteProps> = ({ question, setQuestion, options }) => {
  const { name, label, value, inputValue } = question;

  return (
    <>
      <div className="autocomplete-label">{label}</div>

      <Autocomplete
        id={name}
        value={value}
        options={options}
        disableClearable
        freeSolo
        inputValue={inputValue}
        onInputChange={(_, newInputValue) => {
          setQuestion({ ...question, inputValue: newInputValue });
        }}
        getOptionLabel={(option) => option}
        className="autocomplete"
        onChange={(_, newValue) => {
          setQuestion({ ...question, value: newValue });
        }}
        renderInput={(params) => <TextField {...params} margin="none" className="textfield" variant="outlined" />}
      />
    </>
  );
};

export default MyInputAutocomplete;
