import styled from "styled-components";

interface ButtonProps {
  readonly primary?: boolean;
  readonly width?: string;
}

interface InputGroupChildProps {
  readonly grow: number;
  readonly maxWidth?: number;
}

export const Container = styled.div`
  flex: 1 1 auto;

  @media (min-width: 1200px) {
    padding: 15px;
    height: 100vh;
  }

  border-radius: 15px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LandingCard = styled.div`
  @media (max-width: 700px) {
    height: 100%;
  }

  @media (min-width: 1200px) {
    max-width: 1000px;
  }

  flex: 1 1 auto;

  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  text-align: justify;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #2c28281c;
  border-radius: 10px;

  @media (min-width: 1200px) {
    display: block;
    padding: 20px 40px;
  }

  .card-content {
    flex: 1 1 auto;

    display: flex;
    flex-direction: column;
    overflow: auto;

    width: 100%;

    padding: 20px 30px 0 30px;

    @media (min-width: 1200px) {
      padding: 20px 40px;
      width: 100%;
    }

    header {
      color: #210b3a;
      font: bold 24px Coolvetica;

      @media (min-width: 1200px) {
        font: bold 42px Coolvetica;
      }
    }
  }

  header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;

    @media (min-width: 1200px) {
      padding: 20px 40px;
    }
  }

  section {
    @media (min-width: 1200px) {
      margin-top: 20px;
      padding: 20px 40px;
    }
  }

  footer {
    @media (max-width: 1200px) {
      padding: 10px 30px;

      button {
        height: 45px;
        padding: 15px;

        width: 100%;
      }
      button + button {
        margin-top: 10px;
      }

      height: 120px;
    }

    button {
      width: 100%;
    }

    @media (min-width: 1200px) {
      padding: 20px 80px;
      flex-direction: row;
      height: 100px;

      button + button {
        margin-left: 10px;
      }
    }

    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .roxo {
    color: var(--color-roxo);
  }

  .bold {
    font-weight: bold;
  }

  p + p {
    margin-top: 1rem;

    @media (min-width: 1200px) {
      margin-top: 2rem;
    }
  }

  p {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: justify;

    @media (min-width: 1200px) {
      font-size: 16px;
    }
  }
`;

export const ButtonSave = styled.button<ButtonProps>`
  padding: 20px;
  height: 4rem;

  // width: ${(props) => (props.width ? props.width : "12rem")};
  max-width: 140px !important;

  border-radius: 5px;
  font: 700 0.8rem Poppins;

  display: flex;
  align-items: center;
  justify-content: center;

  text-decoration: none;

  border: ${(props) => (props.primary ? "0" : "1px solid var(--color-roxo);")};

  color: ${(props) => (props.primary ? "var(--color-button-text)" : "var(--color-roxo)")};
  background: ${(props) => (props.primary ? "var(--color-roxo)" : "#ffffff")};

  transition: background-color 0.2s;
  cursor: pointer;

  outline: 0;

  &:hover {
    opacity: 0.8;
  }
`;

export const Button = styled.button<ButtonProps>`
  padding: 20px;
  height: 4rem;

  width: ${(props) => (props.width ? props.width : "12rem")};
  // max-width: 140px !important;

  border-radius: 5px;
  font: 700 0.8rem Poppins;

  display: flex;
  align-items: center;
  justify-content: center;

  text-decoration: none;

  border: ${(props) => (props.primary ? "0" : "1px solid var(--color-roxo);")};

  color: ${(props) => (props.primary ? "var(--color-button-text)" : "var(--color-roxo)")};
  background: ${(props) => (props.primary ? "var(--color-roxo)" : "#ffffff")};

  transition: background-color 0.2s;
  cursor: pointer;

  outline: 0;

  &:hover {
    opacity: 0.8;
  }
`;

export const Card = styled.div`
  @media (min-width: 1200px) {
    max-width: 1000px;
    max-height: 90%;
    border-radius: 10px;
    padding: 15px 0;
  }

  @media (max-width: 700px) {
    img {
      width: 80vw;
    }
  }

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  text-align: justify;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #2c28281c;

  .card-content {
    flex: 1 1 auto;

    display: flex;
    flex-direction: column;

    overflow-y: auto;

    width: 100%;

    padding: 15px 30px 0 30px;

    @media (min-width: 1200px) {
      padding: 20px 40px;
    }
  }

  section {
    // min-height: 400px;
    .text {
      font: 16px Poppins;
      color: #210b3a;
    }

    .bold {
      font-weight: bold;
    }
  }

  fieldset {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 700px;
    border: 0;
  }

  legend {
    font: 700 1.2rem Montserrat;
    text-align: left;
    color: #210b3a;

    @media (min-width: 1200px) {
      font: 42px Coolvetica;
      text-align: left;
      letter-spacing: 0px;
      color: #210b3a;

      // max-width: 550px;
    }

    div > span {
      // font: 18px Coolvetica;
    }
  }

  footer {
    @media (min-width: 1200px) {
      padding: 20px 40px;

      button {
        max-width: 120px;
      }
    }

    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px;

    button {
      width: 100%;
    }

    div {
      display: flex;
      flex-direction: row;

      button + button {
        @media (min-width: 1200px) {
          margin-left: 5px;
        }
      }
    }
  }

  label,
  p,
  .autocomplete-label {
    font-weight: bold;
    color: #210b3a;
    display: flex;
    letter-spacing: 0px;
  }

  .autocomplete input {
    border: 2px solid var(--color-roxo);
    background: #ffffff 0% 0% no-repeat padding-box;
    opacity: 1;
    border-radius: 5px;
  }

  select,
  input,
  .textarea-block textarea {
    border: 2px solid var(--color-roxo);
    background: #ffffff 0% 0% no-repeat padding-box;
    opacity: 1;
    border-radius: 5px;
  }

  .autocomplete input {
    border-radius: 5px;
  }

  .roxo {
    color: var(--color-roxo);
    margin-left: 5px;
  }

  .bold {
    font-weight: bold;
  }

  .closeIcon {
    @media (min-width: 1200px) {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      svg {
        font-size: 36px;
      }
    }

    display: none;
  }

  header {
    width: 100%;
  }

  section p {
    display: block;
    margin: 10px 0;
  }
`;

export const InputGroup = styled.div`
  width: 100%;

  display: flex;

  flex-direction: column;
  align-items: center;

  @media (min-width: 1200px) {
    flex-direction: row;

    .input-block + .input-block {
      margin-left: 10px;
    }
  }
`;

export const InputGroupChild = styled.div<InputGroupChildProps>`
  flex-grow: ${(props) => props.grow};
  width: 100%;

  @media (min-width: 1200px) {
    max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : `100%`)};

    & + & {
      margin-left: 10px;
    }
  }

  max-width: 100%;
  flex-direction: row;
  align-items: center;
`;

export const TableIntro = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 100%;

  div {
    display: flex;
    width: 100%;

    align-items: center;
    flex-direction: row;
  }

  .title {
    color: #210b3a;
    font-size: 12px;
    line-height: 18px;
    font-weight: bold;
    flex-shrink: 3;
  }

  .values {
    display: grid;

    grid-template-columns: 1fr 1fr;
    text-align: center;

    text-align: left;
    font-weight: medium;
    font-size: 12px;
    letter-spacing: 0px;
    color: #210b3a;
    opacity: 1;

    width: 100%;

    span {
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 2px solid #5f33ff;
      opacity: 1;
      border-radius: 50px;

      margin-right: 5px;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      width: 25px;
      height: 25px;
    }
  }

  @media (max-width: 770px) {
    padding: 20px 0;
    flex-direction: column;

    div {
      align-items: flex-start;
      flex-direction: column;
    }

    .title {
      padding: 10px 0;
    }

    .left {
      .values {
        row-gap: 5px;
        div {
          align-items: center;
          flex-direction: row;
        }
      }
    }

    .right {
      .values {
        grid-template-columns: 1fr;
        row-gap: 5px;
        div {
          align-items: center;
          flex-direction: row;
        }
      }
    }
  }
`;

export const Table = styled.div`
  width: 100%;
`;

export const FileCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: var(--color-background);
  border-radius: 10px;
  height: 48px;
  padding: 0 20px;

  font-weight: bold;

  margin: 20px 0px;

  svg {
    color: red;
    cursor: pointer;
  }
`;

interface SpacingProps {
  readonly height?: number;
}

export const Spacing = styled.div`
  height: ${(props: SpacingProps) => (props.height ? `${props.height}px` : "40px")};
`;
