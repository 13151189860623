import React, { useState, useEffect } from "react";
import api from "services/api";

import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import NewKitDialog from "components/Dialogs/NewKitDialog";
import KitMobile from "components/KitMobile";
import { IKit, IKitType } from "../../types/index";
import KitsTable from "../../components/KitsTable";
import Loading from "../../components/LoadingProgress";
import { useAuth } from "../../contexts/auth";
import { Container, Item, KitList, Mobile, Page, TableFooter, Title } from "./styles";

interface column {
  label: string;
  field: string;
}

export default function MyKits() {
  const { getUserId, triggerNewKitFunction } = useAuth();
  const params: { type: string; kit: string } = useParams();
  const [progress, setProgress] = useState<number>(0);

  const [loading, setLoading] = useState(false);

  const [kits, setKits] = useState<IKit[]>([]);

  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);
  const [total, setTotal] = useState<number>(0);

  const [code, setCode] = useState("");
  const [validCode, setValidCode] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);

  const [columns, setColumns] = useState<column[]>([]);

  const [kitTypes, setKitTypes] = useState<IKitType[]>([]);

  async function getKits() {
    await api.get("kit-types").then((res) => setKitTypes(res.data));

    await api.get(`/user/kits?type=${params.type}&limit=${limit}&offset=${offset}`).then((res) => {
      const { total, kits } = res.data;

      setKits(kits);
      setTotal(total);
      setLoading(false);
    });
  }

  useEffect(() => {
    setLimit(5);
  }, []);

  useEffect(() => {
    setLoading(false);
    getKits();

    if (params.type === "1" || params.type === "2") {
      setColumns([
        { label: "Kit", field: "id" },
        { label: "Data", field: "created_at" },
        { label: "Status", field: "status" },
        { label: "Questionário", field: "hasForm" },
        { label: "Resultado", field: "hasResult" }
      ]);
    }

    if (params.type === "3") {
      setColumns([
        { label: "Kit", field: "id" },
        { label: "Data", field: "created_at" },
        { label: "Status", field: "status" },
        { label: "Arquivos", field: "files" },
        { label: "Resultado", field: "hasResult" }
      ]);
    }

    if (params.type === "4") {
      setColumns([
        { label: "Kit", field: "id" },
        { label: "Data", field: "created_at" },
        { label: "Status", field: "status" },
        { label: "Arquivos", field: "files" }
      ]);
    }

    if (params.kit) {
      setCode(params.kit);
      setOpenDialog(true);
    }
    // eslint-disable-next-line
  }, [params, offset, limit]);

  const history = useHistory();

  async function handleCreateKit() {
    setLoading(true);

    api
      .post(`/user/kits/${code}`, { user_id: getUserId() })
      .then((res) => {
        if (res.status === 200) {
          toast.success("Kit cadastrado!");
          triggerNewKitFunction(); // para dar trigger no menu e aparecer nova classe de kit

          if (res.data.type_id === 1) {
            api
              .post("questionario/intestinal", { kit_id: code, answered: false })
              .then((formResponse) => {
                setLoading(false);
                if (formResponse.status === 204) {
                  history.push("/home");
                } else {
                  history.push(`/form/human/${formResponse.data.form_id}`);
                }
              })
              .catch((err) => {
                // console.log(err);
              });
          } else if (res.data.type_id === 2) {
            api
              .post("questionario/solo", { kit_id: code, answered: false })
              .then((formResponse) => {
                setLoading(false);
                if (formResponse.status === 204) {
                  history.push("/home");
                } else {
                  history.push(`/form/soil/${formResponse.data.form_id}`);
                }
              })
              .catch((err) => {
                // console.log(err);
              });
          }
        } else {
          toast.error("Erro ao cadastrar kit...!");
        }
        setCode("");
        getKits();
      })
      .catch((err) => {
        // console.log(err);
      });
  }

  function handleCheckCode() {
    if (!code) {
      toast.error("Informe um código!");
      return;
    }

    api
      .get(`kits/check/${code}`)
      .then((res) => {
        if (res.status === 200) {
          handleCreateKit();
          setValidCode(true);
        } else {
          toast.error("Código inválido");
          setValidCode(false);
        }
      })
      .catch((err) => {
        toast.error("Erro. Contate o suporte!");
      });
  }

  const handleAgree = () => {
    setOpenDialog(false);
    handleCheckCode();
  };

  async function downloadKit(hasResult: boolean, kit: string, extension: string) {
    if (!hasResult) {
      toast.error("Não há resultado disponível para esse kit ainda!");
      return;
    }

    setLoading(true);
    setProgress(0);

    if (extension === "zip") {
      api
        .post("downloadURL/kit", { kit_id: kit, extension })
        .then((r) => {
          if (r.status === 200) {
            const { url } = r.data;

            const a = document.createElement("a");
            a.href = url;
            a.download = `${kit}.${extension}`;
            a.click();
          } else {
            toast.error("Erro ao tentar baixar arquivo");
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else if (extension === "pdf") {
      api
        .post(
          "download/kit",
          { kit_id: kit, extension },
          {
            responseType: "blob",
            onUploadProgress: (data) => {
              // Set the progress value to show the progress bar
              setProgress(Math.round((100 * data.loaded) / data.total));
            }
          }
        )
        .then((r) => {
          const url = window.URL.createObjectURL(r.data);
          const a = document.createElement("a");
          a.href = url;
          a.download = `${kit}.${extension}`;
          a.click();
          setLoading(false);
        })
        .catch((err) => setLoading(false));
    }
  }

  async function accessKit(hasResult: boolean, kit: string, extension: string) {
    if (!hasResult) {
      toast.error("Não há resultado disponível para esse kit ainda!");
      return;
    }

    setLoading(true);

    api
      .post(
        "access/kit",
        { kit_id: kit, extension },
        {
          responseType: "blob",
          onUploadProgress: (data) => {
            // Set the progress value to show the progress bar
            setProgress(Math.round((100 * data.loaded) / data.total));
          }
        }
      )
      .then((r) => {
        const x = new Blob([r.data], { type: "text/html" });
        const url = window.URL.createObjectURL(x);
        window.open(url, "_blank");
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  }

  const goToForm = (id: string, kit_id: string) => {
    const base_url_fetch = Number(kit_id) === 1 ? "/kit/questionario/intestinal" : "/kit/questionario/solo";
    const base_url_redirect = Number(kit_id) === 1 ? "/form/human" : "/form/soil";

    api.get(`${base_url_fetch}/${id}`).then((res) => {
      if (res.data && res.data.id) {
        history.push(`${base_url_redirect}/${res.data.id}`);
      } else {
        toast.error("Erro ao encontrar questionário.");
      }
    });
  };

  useEffect(() => {
    setOffset(0);
  }, [params]);

  const handleSetCode = (code) => {
    setCode(code);
  };

  const handlePreviousPage = () => {
    if (offset - limit < 0) return;
    setOffset(offset - limit);
  };

  const handleNextPage = () => {
    if (offset + limit >= total) return;

    // console.log(offset, limit, total, offset + limit >= total);
    setOffset(offset + limit);
  };

  return (
    <Page>
      <Loading active={loading} progress={progress} />
      <NewKitDialog
        openDialog={openDialog}
        handleCancel={() => setOpenDialog(false)}
        handleValidar={handleAgree}
        handleSetCode={handleSetCode}
        validCode={validCode}
        code={code}
      />
      <Container>
        {kitTypes && (
          <KitsTable
            maxHeight="80%"
            kit_type={Number(params.type)}
            kits={kits}
            onClickForm={goToForm}
            onClickDownload={downloadKit}
            onClickAccess={accessKit}
            onClickRegister={() => setOpenDialog(true)}
            columns={columns}
          >
            <TableFooter>
              <Item onClick={handlePreviousPage}>{"<"}</Item>
              <Item selected={false}>{offset >= total ? 0 : offset / limit + 1}</Item>
              <Item onClick={handleNextPage}>{">"}</Item>
            </TableFooter>
          </KitsTable>
        )}
      </Container>

      <Mobile>
        <Title>Kits</Title>
        <KitList>
          {kits.map((kit: IKit) => {
            return (
              <KitMobile
                key={kit.id}
                kit={kit}
                onClickForm={goToForm}
                onClickDownload={downloadKit}
                onClickAccess={accessKit}
              />
            );
          })}
        </KitList>
      </Mobile>
    </Page>
  );
}
