import styled from "styled-components";

import { Button as ButtonMD, Dialog, DialogActions, DialogContent } from "@material-ui/core";

export const TDialog = styled(Dialog)`
  border-radius: 10px !important;
`;

export const Title = styled.div`
  width: 100%;

  text-align: center;
  color: #210b3a;

  font-family: Coolvetica;

  span {
    color: #5f33ff;
  }

  font-size: 32px;
`;

interface SpacingProps {
  readonly height?: number;
}

export const Spacing = styled.div`
  height: ${(props: SpacingProps) => (props.height ? `${props.height}px` : "40px")};
`;

export const TDialogContent = styled(DialogContent)`
  width: 100% !important;

  @media (min-width: 1200px) {
    width: 537px !important;
  }
`;

export const TDialogContentText = styled.div`
  text-align: center;
  font-family: Poppins !important;
  font-size: 16px !important;
  font-weight: medium !important;
  letter-spacing: 0px !important;
  color: #210b3a !important;

  a {
    // font: normal normal bold 16px/25px Poppins;
    font-weight: bold !important;
  }
`;

export const TDialogActions = styled(DialogActions)`
  margin-bottom: 20px !important;
  justify-content: center !important;
  flex-direction: column !important;

  button {
    font: 14px Poppins !important;
  }

  button + button {
    margin-top: 10px !important;
  }

  @media (min-width: 700px) {
    flex-direction: row !important;

    button + button {
      margin-left: 20px !important;
      margin-top: 0px !important;
    }
  }
`;

interface ButtonProps {
  primary?: string;
}

export const TDialogButton = styled(ButtonMD)`
  background: ${(props: ButtonProps) =>
    props.primary === "true"
      ? "#5f33ff 0% 0% no-repeat padding-box !important"
      : "#ACACAC  0% 0% no-repeat padding-box !important"};

  border-radius: 5px !important;
  opacity: 1 !important;
  text-transform: none !important;

  width: 100% !important;
  height: 52px !important;
  margin: 0 !important;

  span {
    text-transform: none !important;
    color: #fff !important;
  }

  @media (min-width: 700px) {
    width: 144px !important;
  }
`;
