import React from "react";
import { Container, Label, Radio } from "./styles";

interface RaduiButtonListProps {
  question: Question;
  setFunction(question): void;
}

interface Question {
  label: string;
  options: RadioButtonOption[];
}

interface RadioButtonOption {
  value: string;
  label: string;
  checked: boolean;
}

const RadioButtonList: React.FC<RaduiButtonListProps> = ({ question, setFunction }) => {
  const { label, options } = question;

  return (
    <Container>
      <Label>{label}</Label>
      <div className="options">
        {options.map((opt) => (
          <div key={opt.value} className="option">
            <Radio
              onClick={() =>
                setFunction({
                  ...question,
                  options: options.map((option) =>
                    option.value === opt.value ? { ...option, checked: true } : { ...option, checked: false }
                  )
                })
              }
              selected={opt.checked}
            />
            <div className="option-label">{opt.label}</div>
          </div>
        ))}
      </div>
    </Container>
  );
};

export default RadioButtonList;
