import styled from "styled-components";

export const Container = styled.main`
  @media (max-width: 1200px) {
    display: none;
  }

  height: 100vh;
  width: 290px;

  background-color: white;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 15px 30px;

  transition: width 0.2s;

  header {
    width: 100%;
    font-size: 1.2rem;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    img {
      width: 120px;
      margin-left: 10px;
    }

    svg {
      margin: 1rem 0;
      font-size: 1.6rem;
      color: var(--color-azul);
      cursor: pointer;
    }
  }

  .sair {
    text-decoration: none;
    font-weight: bold;
    height: 30px;
    cursor: pointer;

    width: 100%;
  }
`;

export const ContainerCollapsed = styled.main`
  height: 100vh;
  width: 40px;

  background-color: white;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 15px 30px;

  justify-content: space-between;

  transition: width 0.4s;

  .sair {
    text-decoration: none;
    font-weight: bold;
    height: 30px;
    cursor: pointer;
  }

  header {
    svg {
      margin: 1rem 0;
      font-size: 1.6rem;
      color: var(--color-azul);
      cursor: pointer;
    }
  }
`;

interface LiProps {
  readonly selected?: boolean;
}

export const LI = styled.li`
  width: 100%;
  margin: 15px 0;

  a {
    &:hover {
      opacity: 0.6;
    }
    color: #172b4d;

    text-decoration: none;
    font-weight: 600;

    padding: 8px 4px;

    font-size: 0.8rem;
    transition: opacity 0.2s;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    svg {
      margin: 0 1rem 0 0;
      font-size: 25px;
      color: ${(props: LiProps) => (props.selected ? "var(--color-azul)" : "var(--color-roxo)")};
    }
  }
`;

export const UL = styled.ul`
  margin-top: 1.2rem;
  list-style-type: none;
  width: 100%;
  flex-grow: 2;
`;
